import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactComponent as ConnectGoogleGraphic } from 'assets/banners/connect-google.svg';
import IntegrateGmailButton from 'components/Dashboard/CompanyV2/CompanyConnections/IntegrateGmailButton';
import useFlags from 'hooks/useFlags';
import { SPLITS } from 'utils/constants';
const ConnectGoogleCard = ({ onSuccess }) => {
    const { enabled: blockEmailIntegration } = useFlags(SPLITS.blockEmailIntegration);
    if (blockEmailIntegration) {
        return null;
    }
    return (_jsx("div", { className: "border border-solid border-border rounded-lg space-y-2 w-[584px]", children: _jsxs("div", { className: "flex items-center gap-g60", children: [_jsx("div", { children: _jsx(ConnectGoogleGraphic, {}) }), _jsxs("div", { className: "flex flex-col space-y-8", children: [_jsxs("div", { className: "flex flex-col gap-g40", children: [_jsx("p", { className: "text-content-strong typography-label-default-strong text-left", children: "Sync your Google calendar and inbox" }), _jsx("p", { className: "text-content-default typography-paragraph-default-default", children: "Track communications and meetings across your team" })] }), _jsx("div", { className: "w-min", children: _jsx(IntegrateGmailButton, { type: "primary", labelOverride: "Connect", onSuccess: onSuccess }) })] })] }) }));
};
export default ConnectGoogleCard;
