import { gql } from '@apollo/client';
export const REMOVE_COMPANIES_FROM_WATCHLIST = gql `
  mutation RemoveCompaniesFromWatchlist($id: String!, $companies: [String]!) {
    removeCompaniesFromWatchlistWithIds(id: $id, companies: $companies) {
      id
      entityUrn
      companyIds
      name
      sharedWithTeam
      userWatchlistType
    }
  }
`;
export const REMOVE_COMPANIES_FROM_WATCHLIST_MOCKED_RESPONSE = {
    data: {
        removeCompaniesFromWatchlistWithIds: {
            id: 'ab1d04d3-2d3b-4d37-83ae-c0175e3420c9',
            entityUrn: 'urn:harmonic:company_watchlist:ab1d04d3-2d3b-4d37-83ae-c0175e3420c9',
            companyIds: ['1'],
            name: 'New list',
            sharedWithTeam: false,
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            __typename: 'CompanyWatchlist'
        }
    }
};
