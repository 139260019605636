import { gql } from '@apollo/client';
export const getWatchlistsFromCompanies = gql `
  query GetWatchlistsFromCompanies($ids: [Int!]!, $extended: Boolean!) {
    getCompaniesByIds(ids: $ids, extended: $extended) {
      id
      watchlists {
        id
      }
    }
  }
`;
