import { INITIAL_COMPANY_SORT, INITIAL_PEOPLE_SORT, INITIAL_PEOPLE_WATCHLIST_SORT, INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY, INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY } from 'interfaces/SearchModel/Search';
import { FILTER_SESSION_STORE_VERSION_PREFIX } from 'utils/constants';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallowStoreGeneric } from './util';
const useFilterStore = create(persist((set, get) => ({
    searchStates: {},
    companiesDashboardFilters: INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY,
    peopleDashboardFilters: INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY,
    savedSearchFiltersCollection: {},
    companyExplorePageSort: {
        sortField: INITIAL_COMPANY_SORT.sortField,
        descending: INITIAL_COMPANY_SORT.sortDescending
    },
    peopleExplorePageSort: {
        sortField: INITIAL_PEOPLE_SORT.sortField,
        descending: INITIAL_PEOPLE_SORT.sortDescending
    },
    companyWatchlistPageSort: {
        sortField: INITIAL_COMPANY_SORT.sortField,
        descending: INITIAL_COMPANY_SORT.sortDescending
    },
    peopleWatchlistPageSort: {
        sortField: INITIAL_PEOPLE_WATCHLIST_SORT.sortField,
        descending: INITIAL_PEOPLE_WATCHLIST_SORT.sortDescending
    },
    resetListFilter: false,
    editSearchState: (key, payload) => set((state) => ({
        ...state,
        searchStates: { ...state.searchStates, [key]: payload }
    })),
    editFilterStore: (key, payload) => set((state) => ({
        ...state,
        [key]: payload
    })),
    editSavedSearchFilterStore: (key, payload) => {
        const savedSearchFiltersCollection = get().savedSearchFiltersCollection;
        set((state) => ({
            ...state,
            savedSearchFiltersCollection: {
                ...savedSearchFiltersCollection,
                [key]: {
                    ...savedSearchFiltersCollection?.[key],
                    ...payload
                }
            }
        }));
    }
}), {
    // Filter out any keys you don't want to persist to local storage
    partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => !['savedSearchFiltersCollection'].includes(key))),
    // Update it anytime you make some changes to schema
    version: 21,
    name: `${FILTER_SESSION_STORE_VERSION_PREFIX}filter-storage` // unique name
}));
export const useShallowFilterStore = (keys) => useShallowStoreGeneric(useFilterStore, keys);
export default useFilterStore;
