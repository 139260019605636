import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ReactComponent as BoxBarChart } from 'assets/bxs-bar-chart-alt.svg';
import { ReactComponent as BoxHandHoldingUsd } from 'assets/bxs-hand-holding-usd.svg';
import InvestorCard from 'components/common/CompanyCard/InvestorCard';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import ReportDataIssue from 'components/common/ReportDataIssue';
import useFlags from 'hooks/useFlags';
import { ReportSourceSection, ReportSourceView, getCommonlyReportedIssuesBySection } from 'interfaces/UserReportedDataIssue';
import { isNil } from 'lodash';
import getCompanyCapData from 'mocks/mock-data/get_company_cap_table';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { useEffect, useMemo, useState } from 'react';
import { getCapTableForCompanyId } from 'utils/api';
import { SPLITS } from 'utils/constants';
import { getInvestorFundingRoundsMap } from 'utils/funding';
import FundingTotal from '../Cards/FundingTotal';
import CapTable from './CapTable';
import CapTableWaitlistForm from './CapTableWaitlistForm';
import CompanyFinancingContentLoader from './CompanyFinancingContentLoader';
import CompanySingleRbr from './CompanySingleRbr';
const CompanyFinancing = ({ companyId }) => {
    const { data } = useQuery(getCompanyFinancing, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const investorsMap = useMemo(() => {
        const investors = data?.getCompanyById?.funding?.investors ?? [];
        return investors.reduce((acc, investor) => {
            if (!investor?.name)
                return acc;
            acc[investor.name] = investor;
            return acc;
        }, {});
    }, [data?.getCompanyById?.funding?.investors]);
    const { enabled: showCapTable } = useFlags(SPLITS.showCapTable);
    const { enabled: showCapTableMock } = useFlags(SPLITS.showCapTableMock);
    const { enabled: showCapTableWaitlist } = useFlags(SPLITS.showCapTableWaitlist);
    const loading = isNil(data);
    const companyFunding = data?.getCompanyById?.funding;
    const companyName = data?.getCompanyById?.name;
    const investors = companyFunding?.investors || [];
    const filteredInvestors = investors?.filter((investor) => investor?.name !== '');
    const totalFundingRounds = companyFunding?.numFundingRounds || 'Unknown';
    const fundingRounds = companyFunding?.fundingRounds ?? [];
    const rbrExist = fundingRounds.length > 0;
    const noInvestorsExist = fundingRounds?.length === 0 && filteredInvestors?.length === 0;
    const [capTableData, setCapTableData] = useState();
    useEffect(() => {
        if (!showCapTable)
            return;
        getCapTableForCompanyId(companyId).then((resp) => {
            setCapTableData(resp);
        });
    }, [companyId, showCapTable]);
    const investorsFundingRoundMap = useMemo(() => getInvestorFundingRoundsMap(fundingRounds), [fundingRounds]);
    const renderNoInvestorsFound = () => {
        return (_jsxs("div", { className: "mt-6", children: [_jsx("p", { className: "text-content-title typography-title-medium", children: "Investors" }), _jsx("p", { className: "text-content-default typography-label", children: "No investors found" })] }));
    };
    const renderInvestorsWithoutRbr = () => {
        return (_jsxs("div", { "data-testid": "CompanyFinancing-Investors-Without-Rbr", children: [_jsx("div", { children: _jsx("p", { className: "text-clickables-buttonHover typography-title-small", children: "Investors" }) }), _jsx("div", { className: "mt-6 grid sm:grid-cols-2 gap-4", children: filteredInvestors.map((investor) => {
                        const investorName = investor?.name;
                        const logoUrl = investorName
                            ? investorsMap[investorName]?.logoUrl
                            : '';
                        const entityUrn = investor?.__typename === 'Company'
                            ? investor?.companyUrn
                            : investor?.personUrn;
                        return (_jsx(InvestorCard, { investorName: investorName, logoUrl: logoUrl, entityUrn: entityUrn, dataTestId: "CompanyFinancing-NoRbr-Investor" }, investorName));
                    }) })] }));
    };
    const renderInvestorsWithRbr = () => {
        if (!rbrExist)
            return null;
        return (_jsx("div", { "data-testid": "CompanyFinacing-Round-By-Round", className: "flex flex-col gap-g90", children: fundingRounds.map((fundingRound, index) => (_jsx(CompanySingleRbr, { fundingRound: fundingRound, investorsFundingRoundMap: investorsFundingRoundMap, investorsMap: investorsMap }, index))) }));
    };
    const renderFundingCards = () => {
        return (_jsxs("div", { className: "grid sm:grid-cols-3 gap-4 py-p80", children: [_jsx(FundingTotal, { companyId: companyId }), _jsx(MetricCard, { icon: BoxBarChart, value: totalFundingRounds, label: "Funding rounds" }), _jsx(MetricCard, { icon: BoxHandHoldingUsd, value: investors?.length, label: "Investors" })] }));
    };
    return (_jsxs("div", { className: "min-h-screen", children: [_jsx("div", { className: "flex justify-between py-p60", children: _jsx("p", { className: "text-content-title typography-title-medium", children: "Financing" }) }), loading ? (_jsx(CompanyFinancingContentLoader, {})) : (_jsxs("div", { className: "w-full", children: [renderFundingCards(), showCapTable && (_jsxs("div", { className: "py-p80", children: [_jsxs("div", { className: "flex justify-between py-p60 border-b mb-g80 border-solid border-border", children: [_jsx("div", { className: "text-content-title typography-title-small", children: "Cap table" }), _jsx(ReportDataIssue, { reportParams: {
                                            companyUrn: 'urn:company:harmonic:' + companyId,
                                            reportSourceView: ReportSourceView.COMPANY,
                                            reportSourceSection: ReportSourceSection.CAP_TABLE
                                        }, placeholderText: "This company's captable is missing an entry - Series A-1 ..." })] }), capTableData && (_jsx(CapTable, { companyId: companyId, capTableData: capTableData })), showCapTableWaitlist && !capTableData && (_jsx(CapTableWaitlistForm, { companyId: companyId, companyName: companyName })), showCapTableMock && !capTableData && (_jsx(CapTable, { companyId: companyId, capTableData: getCompanyCapData.capTable }))] })), _jsxs("div", { className: "flex justify-between py-p60 border-b mb-p80 border-solid border-border", children: [_jsx("p", { className: "text-content-title typography-title-small", children: "Funding rounds" }), _jsx(ReportDataIssue, { reportParams: {
                                    companyUrn: 'urn:company:harmonic:' + companyId,
                                    reportSourceView: ReportSourceView.COMPANY,
                                    reportSourceSection: ReportSourceSection.FINANCING
                                }, commonlyReportedIssues: getCommonlyReportedIssuesBySection(ReportSourceSection.FINANCING) })] }), noInvestorsExist && renderNoInvestorsFound(), !noInvestorsExist && (_jsx("div", { children: rbrExist
                            ? renderInvestorsWithRbr()
                            : renderInvestorsWithoutRbr() }))] }))] }));
};
export default CompanyFinancing;
