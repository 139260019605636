import { SearchType } from '__generated__/graphql';
import { getFirebaseToken } from 'actions/fetchActions';
import axios, { AxiosHeaders } from 'axios';
import { companySortableHeaderFields } from 'components/Dashboard/Grids/ColumnDefinitions/CompanyHeaders';
import { PeopleSortableHeaderFields } from 'components/Dashboard/Grids/ColumnDefinitions/PeopleHeaders';
import { config } from 'config/config';
import { EntityListType, INITIAL_COMPANY_SORT, INITIAL_PEOPLE_SEARCH_MODEL, INITIAL_PEOPLE_SORT_VALUE, INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { CREATE_SAVED_SEARCH } from 'queries/createSavedSearch';
import { CREATE_WATCHLIST } from 'queries/createWatchlist';
import { GET_SAVED_SEARCH_SIMPLE } from 'queries/getSavedSearch';
import { REMOVE_WATCHLIST } from 'queries/removeWatchlist';
import { UPDATE_SAVED_SEARCH } from 'queries/updateSavedSearch';
import { UPDATE_WATCHLIST } from 'queries/updateWatchlist';
import { useAppStore } from '../hooks/useAppStore';
import analytics, { CustomTrackEvent, SearchVersion } from './analytics';
import { makeGraphqlMutationWithApolloClient } from './api';
import './axiosInterceptor';
import { API_V2_BULK_UPLOAD, API_V2_DATASTORE_USER_ROUTE, COMPANY_GRID_EXPORT_MAX_COUNT, PEOPLE_GRID_EXPORT_MAX_COUNT, PERSONS_ROUTE } from './constants';
import { logger } from './logger';
import { removeArtifactsFromSearchQuery, transformSearchModelForApiv2 } from './search';
import { camelize, snakelize } from './utilities';
const midtierAxios = axios.create({
    baseURL: config.BASE_MIDTIER_API_URL
});
midtierAxios.interceptors.request.use(async (config) => {
    const isAuthenticated = useAppStore.getState().auth?.isAuthenticated;
    if (isAuthenticated) {
        const firebaseToken = await getFirebaseToken();
        const headers = new AxiosHeaders();
        headers.set('authorization', firebaseToken);
        config.headers = headers;
    }
    return config;
});
export const getUser = () => {
    return getFirebaseToken().then((token) => {
        return midtierAxios
            .get(`users/firebaseToken/${token}`)
            .then((response) => {
            return response?.data;
        });
    });
};
export const acceptPilotAgreement = () => {
    return midtierAxios.post('users/acceptPilotContract').then((res) => res.data);
};
export const processOnboarding = async (userOnboardingPayload) => {
    return midtierAxios
        .post('users/onboard', userOnboardingPayload)
        .then((res) => {
        return res.data;
    });
};
const _createCompaniesExportRequestBody = (searchModel, companyCollectionId, includeCount, includeResults, includeCompanyIdsOnly, includeCompanySnapshots, filterOutUnenriched) => {
    const requestBody = {};
    const transformedSearchModel = transformSearchModelForApiv2(searchModel);
    const snakeQuery = snakelize(transformedSearchModel);
    requestBody.query = {
        ...snakeQuery,
        pagination: {
            start: 0,
            page_size: COMPANY_GRID_EXPORT_MAX_COUNT
        }
    };
    if (companyCollectionId) {
        requestBody.company_collection_id = companyCollectionId;
    }
    if (includeCount) {
        requestBody.include_count = includeCount;
    }
    requestBody.include_results = includeResults;
    requestBody.include_all_company_ids_only = includeCompanyIdsOnly;
    requestBody.include_company_snapshots = includeCompanySnapshots;
    requestBody.filter_out_unenriched = filterOutUnenriched;
    return requestBody;
};
const _createPeopleExportRequestBody = (searchModel, peopleWatchlistId, includeCount, includeResults, includePersonIdsOnly, includePersonSnapshots, filterOutUnenriched) => {
    const requestBody = {};
    const transformedSearchModel = transformSearchModelForApiv2(searchModel);
    const snakeQuery = snakelize(transformedSearchModel);
    requestBody.query = {
        ...snakeQuery,
        pagination: {
            start: 0,
            page_size: PEOPLE_GRID_EXPORT_MAX_COUNT
        }
    };
    if (peopleWatchlistId) {
        requestBody.people_watchlist_id = peopleWatchlistId;
    }
    if (includeCount) {
        requestBody.include_count = includeCount;
    }
    requestBody.include_results = includeResults;
    requestBody.include_all_person_ids_only = includePersonIdsOnly;
    requestBody.include_person_snapshots = includePersonSnapshots;
    requestBody.filter_out_unenriched = filterOutUnenriched;
    return requestBody;
};
export class ExportCancelledError extends Error {
    constructor() {
        super('Export cancelled');
    }
}
export const streamPeopleAsCSV = async ({ searchModel, peopleWatchlistId, columns, onChunkProcessed, includeCount = true, includeResults = true, includePersonIdsOnly = false, includePersonSnapshots = false, filterOutUnenriched = false }) => {
    const requestBody = _createPeopleExportRequestBody(searchModel, peopleWatchlistId, includeCount, includeResults, includePersonIdsOnly, includePersonSnapshots, filterOutUnenriched);
    requestBody.specific_csv_columns = columns;
    const token = await getFirebaseToken();
    // axios does not support streaming responses in the browser
    // using fetch instead
    const response = await fetch(`${config.BASE_MIDTIER_API_URL}exports/people`, {
        headers: {
            Authorization: token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        method: 'POST'
    });
    if (!response.ok || !response.body) {
        throw new Error(`Failed to stream people as CSV: ${response.status} ${response.statusText}`);
    }
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    let cancelled = false;
    const cancel = () => {
        cancelled = true;
    };
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const { done, value } = await reader.read();
        if (cancelled) {
            reader.cancel();
            throw new ExportCancelledError();
        }
        if (done) {
            reader.cancel();
            return;
        }
        const newLineCount = (value.match(/(?<!\\)\n/g) || []).length;
        onChunkProcessed(value, newLineCount, cancel);
    }
};
export const streamCompaniesAsCSV = async ({ searchModel, companyCollectionId, columns, onChunkProcessed, includeCount = true, includeResults = true, includeCompanyIdsOnly = false, includeCompanySnapshots = false, filterOutUnenriched = false }) => {
    const requestBody = _createCompaniesExportRequestBody(searchModel, companyCollectionId, includeCount, includeResults, includeCompanyIdsOnly, includeCompanySnapshots, filterOutUnenriched);
    requestBody.specific_csv_columns = columns;
    const token = await getFirebaseToken();
    // axios does not support streaming responses in the browser
    // using fetch instead
    const response = await fetch(`${config.BASE_MIDTIER_API_URL}exports/companies`, {
        headers: {
            Authorization: token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        method: 'POST'
    });
    if (!response.ok || !response.body) {
        throw new Error(`Failed to stream companies as CSV: ${response.status} ${response.statusText}`);
    }
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    let cancelled = false;
    const cancel = () => {
        cancelled = true;
    };
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const { done, value } = await reader.read();
        if (cancelled) {
            reader.cancel();
            throw new ExportCancelledError();
        }
        if (done) {
            reader.cancel();
            return;
        }
        const newLineCount = (value.match(/(?<!\\)\n/g) || []).length;
        onChunkProcessed(value, newLineCount, cancel);
    }
};
// Sign In Token for Chrome Extension
export const getUserSignInToken = async () => {
    const token = await getFirebaseToken();
    const response = await midtierAxios.post('users/signInToken', {
        headers: {
            authorization: token
        }
    });
    return response?.data?.token;
};
export const getUserApiRequests = (apiKey) => {
    return midtierAxios
        .get(`metrics/api_requests`, {
        headers: {
            apikey: apiKey
        }
    })
        .then((response) => {
        return response?.data;
    });
};
export const getUserApiKeys = async () => {
    const user = await getUser();
    const customerUrn = user.customer;
    return midtierAxios
        .get(`customers/${customerUrn}/apiKeys`)
        .then((response) => {
        return response?.data;
    });
};
export const createNewApiKey = async (name, customerUrn) => {
    const bodyFormData = new FormData();
    bodyFormData.append('name', name);
    bodyFormData.append('key_request_source', 'CONSUMER_API');
    return midtierAxios
        .post(`customers/${customerUrn}/apiKeys`, bodyFormData)
        .then((response) => {
        return response?.data;
    });
};
export const deleteApiKey = async (keyId, customerUrn) => {
    const bodyFormData = new FormData();
    bodyFormData.append('api_key_id', keyId);
    return midtierAxios
        .delete(`customers/${customerUrn}/apiKeys`, { data: bodyFormData })
        .then((response) => {
        return response?.data;
    });
};
export const getUserApiUptime = (apiKey) => {
    return midtierAxios
        .get('metrics/uptime', {
        headers: {
            apikey: apiKey
        }
    })
        .then((res) => {
        return res.data;
    });
};
export const updateSavedSearchUserOptions = (savedSearchId, options) => {
    return midtierAxios
        .put('savedSearches:userOptions', {
        saved_search_id: savedSearchId,
        is_pinned: options.isPinned
    })
        .then((res) => {
        return res.data;
    });
};
export const updateUserSettings = ({ pinnedSavedSearchesOrder }, cancelToken) => {
    return midtierAxios
        .put('users/settings', {
        pinned_saved_searches_order: pinnedSavedSearchesOrder
    }, {
        cancelToken
    })
        .then((res) => res.data);
};
export const getCompaniesSearchResultCount = async (searchModel, cancelToken) => {
    const transformedSearchModel = transformSearchModelForApiv2(searchModel);
    return midtierAxios
        .post(`search/companies_count`, {
        query: transformedSearchModel,
        type: 'COMPANIES_LIST',
        only_surfaceable: true
    }, {
        cancelToken
    })
        .then((res) => {
        return res.data;
    });
};
export const getPeopleSearchResultCount = async (searchModel, cancelToken) => {
    const transformedSearchModel = transformSearchModelForApiv2(searchModel);
    return midtierAxios
        .post(`search/people_count`, {
        query: transformedSearchModel,
        type: 'PERSONS',
        only_surfaceable: true
    }, {
        cancelToken
    })
        .then((res) => {
        return res.data;
    });
};
export const getRecentApiActivity = (apiKey) => {
    return midtierAxios
        .get(`metrics/recent_activity`, {
        headers: {
            apikey: apiKey
        }
    })
        .then((response) => {
        return response?.data;
    });
};
export const addWatchlist = async (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
client, addWatchlistInput) => {
    const result = await makeGraphqlMutationWithApolloClient(client, CREATE_WATCHLIST, {
        watchlistInput: camelize(addWatchlistInput)
    });
    if (client) {
        // For reference: write query doesnt work with lists of reference objects.
        // client.cache.writeQuery({
        //   query: getCompanyWatchlists,
        //   data: result.data?.createCompanyWatchlist
        // });
        // so we need to use cache.modify directly
        // finding the root query in the apollo cache with the query key getCompanyWatchlistsForTeam
        // adding the new reference created by the mutation to the list of existing references (watchlist)
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Query',
                id: 'ROOT_QUERY'
            }),
            fields: {
                getCompanyWatchlistsForTeam(existingRefs, options) {
                    const newWatchlistRef = options.toReference({
                        __typename: 'CompanyWatchlist',
                        id: result.data?.createCompanyWatchlist.id
                    });
                    return [...existingRefs, newWatchlistRef];
                }
            }
        });
    }
    return { urn: result.data?.createCompanyWatchlist.entityUrn };
};
export const updateWatchlist = async (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
client, watchlistUrn, watchlistInput) => {
    const result = await makeGraphqlMutationWithApolloClient(client, UPDATE_WATCHLIST, {
        idOrUrn: watchlistUrn,
        watchlistInput: camelize(watchlistInput)
    });
    return result.data?.updateCompanyWatchlist;
};
export const deleteWatchlist = async (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
client, watchlistUUID) => {
    const result = await makeGraphqlMutationWithApolloClient(client, REMOVE_WATCHLIST, {
        idOrUrn: watchlistUUID
    });
    // finding the root query in the apollo cache with the query key getCompanyWatchlistsForTeam
    // adding the new reference created by the mutation to the list of existing references (watchlist)
    client.cache.modify({
        id: client.cache.identify({
            __typename: 'Query',
            id: 'ROOT_QUERY'
        }),
        fields: {
            getCompanyWatchlistsForTeam(existingRefs, actions) {
                return existingRefs.filter((watchlistRef) => actions.readField('id', watchlistRef) !== watchlistUUID);
            }
        }
    });
    return result.data?.deleteCompanyWatchlist;
};
export const transformSearchQueryWithValidSortField = (searchQuery, isPeopleSort = false) => {
    const sortableHeaderFields = isPeopleSort
        ? PeopleSortableHeaderFields
        : companySortableHeaderFields;
    const initialSortValue = isPeopleSort
        ? INITIAL_PEOPLE_SORT_VALUE
        : INITIAL_COMPANY_SORT.sortField;
    if (searchQuery.sort) {
        searchQuery.sort = searchQuery.sort.map((sort) => {
            const existingSortField = sort.sort_field || sort.sortField;
            return {
                ...sort,
                sortField: existingSortField && sortableHeaderFields.includes(existingSortField)
                    ? existingSortField
                    : initialSortValue
            };
        });
    }
    else {
        searchQuery = isPeopleSort
            ? INITIAL_PEOPLE_SEARCH_MODEL
            : INITIAL_SEARCH_MODEL;
    }
    return searchQuery;
};
export const createSavedSearch = async (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
client, savedSearchInput, version = SearchVersion.V1) => {
    if (savedSearchInput.type === EntityListType.PEOPLE_SAVED_SEARCH) {
        savedSearchInput.query = transformSearchQueryWithValidSortField(savedSearchInput.query, true);
    }
    else {
        savedSearchInput.query = transformSearchQueryWithValidSortField(savedSearchInput.query);
    }
    savedSearchInput.query = removeArtifactsFromSearchQuery(savedSearchInput.query);
    const result = await makeGraphqlMutationWithApolloClient(client, CREATE_SAVED_SEARCH, {
        savedSearchInput: camelize(savedSearchInput)
    }, undefined);
    analytics.trackCustomEvent({
        event: CustomTrackEvent.SAVED_SEARCH_CREATED,
        properties: {
            type: savedSearchInput.type === EntityListType.COMPANY_SAVED_SEARCH
                ? 'company'
                : 'person',
            version: version
        }
    });
    if (client) {
        // finding the root query in the apollo cache with the query key getCompanyWatchlistsForTeam
        // adding the new reference created by the mutation to the list of existing references (watchlist)
        client.cache.modify({
            id: client.cache.identify({
                __typename: 'Query',
                id: 'ROOT_QUERY'
            }),
            fields: {
                getSavedSearchesForTeam(existingRefs, options) {
                    const newSSRef = options.toReference({
                        __typename: 'SavedSearch',
                        id: result.data?.createSavedSearch.id
                    });
                    const newRefList = [...existingRefs, newSSRef];
                    return newRefList;
                }
            },
            broadcast: false,
            optimistic: true
        });
    }
    return result.data?.createSavedSearch;
};
export const updateSavedSearch = async (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
client, savedSearchUrn, savedSearchInput, clearResultsCache = false) => {
    if (savedSearchInput.query) {
        savedSearchInput.query = removeArtifactsFromSearchQuery(savedSearchInput.query);
    }
    const result = await makeGraphqlMutationWithApolloClient(client, UPDATE_SAVED_SEARCH, {
        idOrUrn: savedSearchUrn,
        updateSavedSearchInput: camelize(savedSearchInput)
    }, [], (cache, { data: { updateSavedSearch } }) => {
        cache.writeQuery({
            query: GET_SAVED_SEARCH_SIMPLE,
            variables: { id: updateSavedSearch.id },
            data: {
                getSavedSearch: updateSavedSearch
            }
        });
        // We want to clear the results cache for the saved search if the query has changed
        if (clearResultsCache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'SavedSearch',
                    id: updateSavedSearch.id
                }),
                fieldName: 'results'
            });
        }
    });
    return result.data?.updateSavedSearch;
};
export const getPeopleUrnsByLinkedinUrl = (linkedin_urls) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}${PERSONS_ROUTE}/${API_V2_BULK_UPLOAD}`;
    return midtierAxios
        .post(apiUrl, linkedin_urls)
        .then((response) => response.data.result);
};
export const getApiContractDetails = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}metrics/api_contract_details`;
    return midtierAxios
        .get(apiUrl)
        .then((response) => response.data)
        .catch((error) => logger.error('Failed to get API contract details', {
        error
    }));
};
export const setDataStoreUserValue = async (key, body) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}${API_V2_DATASTORE_USER_ROUTE}/${key}`;
    const response = await midtierAxios
        .post(apiUrl, body)
        .then((response) => response.data)
        .catch((error) => logger.error('Failed to set user value for the datastore', {
        error
    }));
    return response;
};
export const authenticate = async () => {
    const firebaseToken = await getFirebaseToken();
    return midtierAxios
        .post(`users/authenticate`, {}, {
        headers: {
            authorization: firebaseToken
        }
    })
        .then((res) => {
        return res.data;
    });
};
export const getUserApiKey = () => {
    return authenticate().then((user) => user?.apikey);
};
export const getCustomerAffinityApiKeyIsSet = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity`;
    const response = await midtierAxios
        .get(apiUrl)
        .then((response) => response.data);
    return Boolean(response?.is_key_set);
};
export const setCustomerAffinityApiKey = async (apiKey) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity`;
    const response = await midtierAxios
        .put(apiUrl, { affinity_api_key: apiKey })
        .then((response) => response.data);
    return Boolean(response?.is_key_set);
};
export const revokeCustomerAffinityApiKey = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity`;
    const response = await midtierAxios
        .delete(apiUrl)
        .then((response) => response.data);
    return Boolean(response?.is_key_set);
};
export const startAffinityWatchListSync = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/syncWatchlists`;
    const response = await midtierAxios
        .post(apiUrl)
        .then((response) => response.data);
    return Boolean(response);
};
export const getAffinityWatchListSync = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/syncWatchlists`;
    const response = await midtierAxios
        .get(apiUrl)
        .then((response) => response.data);
    return response;
};
export const setCustomerAffinitySyncMode = async (syncMode) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/syncMode`;
    const response = await midtierAxios
        .put(apiUrl, { sync_mode: syncMode })
        .then((response) => response.data);
    return response?.sync_mode;
};
export const getCustomerAffinitySyncMode = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/syncMode`;
    const response = await midtierAxios
        .get(apiUrl)
        .then((response) => response.data);
    return response?.sync_mode;
};
export const getCustomerAffinityFields = async (companyId) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/company/${companyId}/fields`;
    const response = await midtierAxios
        .get(apiUrl)
        .then((response) => response.data);
    return response;
};
export const getCustomerAffinitySpendByMonth = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/credits/byMonth`;
    const response = await midtierAxios
        .get(apiUrl)
        .then((response) => response.data);
    return response;
};
export const getCustomerAffinityLists = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/organizations`;
    const response = await midtierAxios
        .get(apiUrl)
        .then((response) => response.data);
    return response;
};
export const setCustomerAffinityLists = async (lists) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/organizations`;
    const response = await midtierAxios
        .put(apiUrl, lists)
        .then((response) => response.data);
    return response;
};
export const getAffinityWatchlistStatusOwnerOptions = async (watchlist_id) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/urn:harmonic:company_watchlist:${watchlist_id}/field_options`;
    const response = await midtierAxios
        .get(apiUrl)
        .then((response) => response.data);
    return response;
};
export const pushToAffinity = async (watchlist_id, company_ids, new_affinity_owners, new_status) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/urn:harmonic:company_watchlist:${watchlist_id}/companies`;
    const company_urns = company_ids.map((id) => `urn:harmonic:company:${id}`);
    const payload = {
        company_urns,
        new_affinity_owners,
        ...(new_status !== '' && { new_status: new_status })
    };
    const response = await midtierAxios
        .post(apiUrl, payload)
        .then((response) => response.data);
    return response;
};
export const removeFromAffinity = async (watchlist_id, company_id) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/affinity/lists/urn:harmonic:company_watchlist:${watchlist_id}/companies/urn:harmonic:company:${company_id}`;
    const response = await midtierAxios
        .delete(apiUrl)
        .then((response) => response.data);
    return response;
};
export const authenticateGoogleIntegration = async (code) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}google_auth/authenticate`;
    return midtierAxios.get(`${apiUrl}?code=${code}`);
};
export const authenticateMicrosoftIntegration = async (authorizationCode) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}microsoft_auth/authenticate`;
    return midtierAxios.get(`${apiUrl}?code=${authorizationCode}`);
};
export const generateSlackAuthUrl = async () => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}customerIntegrations/slack/oauth/configUrl`;
    return midtierAxios.get(apiUrl).then((response) => {
        return response.data;
    });
};
export const getSavedSearchNotificationSubscriptions = (requestParams) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}notifications/subscription`;
    return midtierAxios
        .get(apiUrl, {
        params: requestParams
    })
        .then((response) => {
        return response.data;
    });
};
export const upsertSavedSearchNotificationSubscription = async (requestBody) => {
    const apiUrl = `${config.BASE_MIDTIER_API_URL}notifications/subscription`;
    return midtierAxios
        .put(apiUrl, requestBody)
        .then((response) => {
        return response.data;
    });
};
export const updateUserName = async (name) => {
    return midtierAxios
        .put('users/', {
        name
    })
        .then((res) => res)
        .catch((error) => {
        return error.response;
    });
};
export const performEntityActionFromSearchQuery = async (entityActionPayload, searchQuery, entityLimit) => {
    const res = await midtierAxios.post('entity_actions/search_query', snakelize({
        entityActionPayload,
        search_input: {
            query: searchQuery,
            type: entityActionPayload.targetEntityType === 'COMPANY'
                ? SearchType.COMPANIES_LIST
                : SearchType.PERSONS
        },
        entity_limit: entityLimit
    }));
    return res.data;
};
export const performEntityActionFromWatchlist = async (entityActionPayload, watchlistUrn, sortOptions, entityLimit) => {
    const res = await midtierAxios.post('entity_actions/watchlist', snakelize({ entityActionPayload, watchlistUrn, sortOptions, entityLimit }));
    return res.data;
};
export const semanticPOCLogQuery = async (loggingData) => {
    const response = await midtierAxios.post('search/companies_by_semantic_logs', loggingData);
    return response.data;
};
