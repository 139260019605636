import { jsx as _jsx } from "react/jsx-runtime";
import { TriangleDownIcon, TriangleUpIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import { isNil } from 'lodash';
import { addCommasToNumber } from 'utils/utilities';
const PercentChangeBadge = ({ tractionMetrics = {} }) => {
    const bothPercentAndValueExist = !isNil(tractionMetrics?.percentChange) && !isNil(tractionMetrics?.value);
    if (!bothPercentAndValueExist)
        return null;
    const isPositiveGrowth = tractionMetrics?.percentChange > 0;
    const isNegativeGrowth = tractionMetrics?.percentChange < 0;
    const isZeroGrowth = tractionMetrics?.percentChange === 0;
    if (isZeroGrowth)
        return null;
    return (_jsx(Badge, { color: isPositiveGrowth ? 'positive' : 'negative', leadingIcon: isPositiveGrowth && !isZeroGrowth ? TriangleUpIcon : TriangleDownIcon, label: `${tractionMetrics?.percentChange?.toFixed(0)}% (${addCommasToNumber(tractionMetrics?.change?.toFixed(0) ?? '0')})`, size: "large", dataTestId: "PercentChangeBadge" }));
};
export default PercentChangeBadge;
