export var GridExportFileType;
(function (GridExportFileType) {
    GridExportFileType["JSON"] = "JSON";
    GridExportFileType["CSV"] = "CSV";
    GridExportFileType["JSONL"] = "JSONL";
})(GridExportFileType || (GridExportFileType = {}));
export var DerivedMetricType;
(function (DerivedMetricType) {
    DerivedMetricType["Snapshot"] = "Snapshot";
    DerivedMetricType["RealChange"] = "RealChange";
    DerivedMetricType["PercentChange"] = "PercentChange";
})(DerivedMetricType || (DerivedMetricType = {}));
export var EntityAction;
(function (EntityAction) {
    EntityAction["ADD_TO_WATCHLIST"] = "ADD_TO_WATCHLIST";
    EntityAction["REMOVE_FROM_WATCHLIST"] = "REMOVE_FROM_WATCHLIST";
    EntityAction["LIKE"] = "LIKE";
    EntityAction["UNLIKE"] = "UNLIKE";
    EntityAction["HIDE"] = "HIDE";
    EntityAction["UNHIDE"] = "UNHIDE";
    EntityAction["VIEW"] = "VIEW";
    EntityAction["UNVIEW"] = "UNVIEW";
})(EntityAction || (EntityAction = {}));
