import { gql } from '@apollo/client';
export const GET_CURRENT_USER = gql `
  query GetCurrentUser {
    getCurrentUser {
      name
      entityUrn
      email
      customer {
        identifier
        name
        contactCredits
        maxLiteUserSeats
        maxUserSeats
        slackIntegrations {
          incomingWebhookChannel
          incomingWebhookChannelId
          incomingWebhookConfigurationUrl
          incomingWebhookUrl
          teamId
          teamName
          id
        }
      }
      role
      status
      connectionsIntegrationStatus {
        type
        isEnabled
      }
    }
  }
`;
