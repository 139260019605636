import { create } from 'zustand';
const useSavedSearchStore = create((set) => ({
    savedSearchCounts: {},
    incrementCount: (key) => set((state) => ({
        savedSearchCounts: {
            ...state.savedSearchCounts,
            [key]: state.savedSearchCounts && state.savedSearchCounts[key]
                ? state.savedSearchCounts[key] + 1
                : 1
        }
    })),
    decrementCount: (key) => set((state) => ({
        savedSearchCounts: {
            ...state.savedSearchCounts,
            [key]: state.savedSearchCounts && state.savedSearchCounts[key]
                ? state.savedSearchCounts[key] > 1
                    ? state.savedSearchCounts[key] - 1
                    : 0
                : 0
        }
    })),
    setSavedSearchCount: (key, count) => {
        set((state) => ({
            savedSearchCounts: {
                ...state.savedSearchCounts,
                [key]: count
            }
        }));
    }
}));
export default useSavedSearchStore;
