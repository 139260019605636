import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import { BusinessIcon, ChevronGrabberVertical, GraduationCapIcon, LinkedInIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import UserConnectionsDegree from 'components/Dashboard/CompanyV2/CompanyContact/UserConnectionsDegree';
import AvatarWithHighlightBadge from 'components/common/Highlights/AvatarWithHighlightBadge';
import dayjs from 'dayjs';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Button from 'harmonic-components/Button/Button';
import CompanyAttribute from 'harmonic-components/CompanyAttribute/CompanyAttribute';
import IconButton from 'harmonic-components/IconButton/IconButton';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { shouldLinkCompanyId } from 'utils/company';
import { COMPANY_ROUTE, DASHBOARD_ROUTE, PERSON_ROUTE } from 'utils/constants';
import { getDurationText, isSafeHostedAsset } from 'utils/utilities';
import AddPeopleToWatchlist from '../AddPeopleToWatchlist';
import GetPersonEmailButton from '../ContactData/GetEmailButton';
import HighlightsChip from '../Highlights/HighlightsChip';
import Paper from '../Paper';
import PersonUserConnections from '../PersonUserConnections';
// For creating connecting dots with line
const MultiExperienceLineWrapper = styled.div `
  .nested-experience {
    position: relative;
  }

  .nested-experience:before {
    position: absolute;
    content: ' ○';
    margin-left: -35px;
    font-size: 22px;
    top: 4px;
    color: var(--color-border-strong);
  }
  .nested-experience:after {
    position: absolute;
    left: 0;
    content: '';
    top: 27.5px;
    border-left: 1.5px solid var(--color-border-strong);
    margin-left: -29.5px;
    height: 79%;
  }
  .nested-experience:first-of-type:after {
  }
  .nested-experience:last-of-type:after {
    height: 0%;
  }
`;
const HIGHLIGHT_CAP = 3;
const PersonCardV2 = ({ id, fullName, currentTitle, profilePictureUrl, universityName, highlights, pastExperiences, linkedinUrl, userConnections }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const pastExperiencesLength = pastExperiences.length;
    useQuery(getPersonContactInformation, {
        variables: {
            id
        }
    });
    const renderPastExperience = (experience) => {
        const roles = experience?.roles ?? [];
        if (roles.length === 0)
            return null;
        const logoUrl = experience?.logoUrl;
        const containsMultipleRoles = experience?.roles?.length > 1;
        const currentRole = roles?.[0];
        let startDate = currentRole?.startDate;
        let endDate = currentRole?.endDate;
        const isCurrentPosition = currentRole?.isCurrentPosition;
        if (containsMultipleRoles) {
            startDate = roles?.[roles?.length - 1]?.startDate;
            endDate = roles?.[0].startDate;
        }
        const companyId = experience?.id;
        const companyRoute = `${DASHBOARD_ROUTE}${COMPANY_ROUTE}/${companyId}`;
        const durationText = getDurationText(startDate, endDate);
        const experienceHighlights = experience?.highlights?.map((highlight) => ({ category: highlight })) ??
            [];
        const formattedLogoUrl = isSafeHostedAsset(logoUrl ?? '')
            ? logoUrl
            : defaultCompanyLogo;
        return (_jsxs("div", { className: "bg-surface-nested rounded-br40 p-p40 border border-solid border-border", children: [_jsx("div", { children: _jsxs("div", { className: classNames('flex justify-between', !containsMultipleRoles && 'items-center'), children: [_jsxs("div", { className: "flex gap-g50", children: [_jsx(Link, { to: companyRoute, onClick: (e) => {
                                            if (!shouldLinkCompanyId(companyId))
                                                e.preventDefault();
                                        }, className: classNames(!shouldLinkCompanyId(companyId) && 'cursor-default'), children: _jsx(Avatar, { src: formattedLogoUrl, size: "huge", alt: "Company logo" }) }), _jsxs("div", { className: "flex flex-col justify-center", children: [!containsMultipleRoles && (_jsxs(_Fragment, { children: [_jsx(Link, { to: companyRoute, onClick: (e) => {
                                                            if (!shouldLinkCompanyId(companyId))
                                                                e.preventDefault();
                                                        }, className: classNames(!shouldLinkCompanyId(companyId) && 'cursor-default'), children: _jsxs("p", { className: "flex items-center text-content-strong typography-label", children: [_jsx("p", { children: experience.companyName }), currentRole?.title && (_jsxs(_Fragment, { children: [_jsx("span", { className: "mx-g40 text-content-muted", children: "\u2022" }), _jsx("p", { children: currentRole?.title })] }))] }) }), (startDate || endDate) && (_jsxs("p", { className: classNames('text-content-weak typography-label'), children: [startDate && dayjs(startDate).format('MMM YYYY'), isCurrentPosition && ' - Present', endDate && ` - ${dayjs(endDate).format('MMM YYYY')}`, durationText && (_jsxs(_Fragment, { children: [_jsx("span", { className: "mx-g40 text-content-muted", children: "\u2022" }), durationText] }))] }))] })), containsMultipleRoles && (_jsxs(_Fragment, { children: [_jsx(Link, { to: companyRoute, onClick: (e) => {
                                                            if (!shouldLinkCompanyId(companyId))
                                                                e.preventDefault();
                                                        }, className: classNames(!shouldLinkCompanyId(companyId) && 'cursor-default'), children: _jsx("p", { className: classNames('typography-label-default-strong text-content-strong'), children: experience.companyName }) }), durationText && (_jsx("p", { className: classNames('text-content-weak typography-label'), children: durationText }))] }))] })] }), _jsx("div", { children: _jsx(HighlightsChip, { highlights: experienceHighlights, limit: HIGHLIGHT_CAP }) })] }) }), containsMultipleRoles && (_jsx(MultiExperienceLineWrapper, { className: "pl-p50 ml-g90 mt-p40 flex flex-col", children: roles.map((role) => {
                        const startDate = role.startDate
                            ? dayjs(role.startDate).format('MMM YYYY')
                            : '';
                        return (_jsxs("div", { className: "nested-experience py-p40", children: [_jsx("p", { className: "text-content-default typography-label-default-strong", children: role.title }), _jsx("p", { className: "mt-p20 text-content-weak typography-label", children: startDate })] }, role.title));
                    }) }))] }));
    };
    const personRoute = `${DASHBOARD_ROUTE}${PERSON_ROUTE}/${id}`;
    const priorExperience = pastExperiences.find((exp) => Boolean(exp.roles[0].endDate));
    const priorExperienceCompany = priorExperience?.companyName ?? '';
    const priorExperienceCompanyLogo = isSafeHostedAsset(priorExperience?.logoUrl ?? '')
        ? priorExperience?.logoUrl
        : '';
    const priorExperienceCompanyId = priorExperience?.id;
    return (_jsxs(Paper, { children: [_jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "flex gap-g50", children: [_jsx("div", { className: "pb-3", children: _jsx(AvatarWithHighlightBadge, { href: personRoute, src: profilePictureUrl ?? '', highlights: highlights, personId: id }) }), _jsxs("div", { className: "flex flex-col gap-g30", children: [_jsxs("div", { children: [_jsxs("div", { className: "flex flex-row justify-start gap-x-g20 items-end lg:items-center", children: [_jsx(Link, { to: personRoute, className: "shrink-0 basis-auto", children: _jsx("p", { className: "typography-label-large-strong text-content-strong", children: _jsxs("div", { className: "flex items-center gap-x-g20", children: [fullName, _jsx("div", { className: "lg:hidden", children: linkedinUrl && (_jsx(IconButton, { icon: (props) => (_jsx(LinkedInIcon, { ...props, applyCurrentColor: false })), size: "tiny", type: "secondary", emphasis: "low", onClick: () => window.open(linkedinUrl) })) })] }) }) }), _jsxs("div", { className: "hidden lg:flex", children: [linkedinUrl && (_jsx(IconButton, { icon: (props) => (_jsx(LinkedInIcon, { ...props, applyCurrentColor: false })), size: "tiny", type: "secondary", emphasis: "low", onClick: () => window.open(linkedinUrl) })), userConnections && userConnections.length > 0 && (_jsxs("div", { className: "flex flex-row items-center gap-x-1", children: [_jsx("span", { className: "text-content-weak", children: "\u2022" }), _jsx(UserConnectionsDegree, { userConnections: userConnections })] }))] })] }), _jsx("p", { className: classNames('typography-label text-content-weak'), children: currentTitle })] }), _jsxs("div", { className: "flex flex-col gap-g10", children: [priorExperience &&
                                                priorExperienceCompanyLogo &&
                                                priorExperienceCompanyId && (_jsxs("div", { className: "flex flex-row gap-g30 items-center w-full justify-start", children: [_jsx(BusinessIcon, { className: "text-content-weak w-4 h-4" }), _jsx("p", { className: "typography-label text-content-weak text-nowrap", children: "Prior company:" }), _jsx(CompanyAttribute, { internalLink: true, name: priorExperienceCompany, src: priorExperienceCompanyLogo, href: `/dashboard/company/${priorExperienceCompanyId}` })] })), universityName && (_jsxs("p", { className: classNames('typography-label flex gap-g30 text-content-default items-center'), children: [_jsx(GraduationCapIcon, { className: "text-content-weak w-4 h-4" }), ' ', _jsx("span", { children: universityName })] })), userConnections && userConnections.length > 0 && (_jsx(PersonUserConnections, { target: { name: fullName }, userConnections: userConnections, gap: "g30", iconFill: "weak" }))] })] })] }), _jsxs("div", { className: "flex flex-row justify-start gap-2", children: [_jsx("div", { children: _jsx(GetPersonEmailButton, { personId: id, size: "compact" }) }), _jsx("div", { children: _jsx(AddPeopleToWatchlist, { personIds: [id] }) })] })] }), isExpanded && (_jsx("div", { "data-testid": "PersonCard-Experience-Container", className: "flex flex-col gap-g40 mb-p40 mt-p40", children: pastExperiences.map((experience, index) => {
                    return (_jsx("div", { children: renderPastExperience(experience) }, experience.id));
                }) })), pastExperiencesLength > 0 && (_jsx("div", { className: "flex justify-center", children: _jsx(Button, { type: "secondary", emphasis: "low", leadingIcon: ChevronGrabberVertical, label: isExpanded ? 'Show less' : 'Show more', onClick: () => setIsExpanded(!isExpanded), dataTestId: "PersonCard-Expand-Btn" }) }))] }));
};
export default PersonCardV2;
