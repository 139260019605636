import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import { isBoolean } from 'lodash';
import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { ChevronDown } from 'assets/harmonic-icons';
import classNames from 'classnames';
import styled from 'styled-components';
const StyledDropdownIcon = styled(ChevronDown) `
  top: calc(50% - 10px);
  position: absolute;
  right: 12px;
  color: #1a1d23;
`;
const TextField = (props) => {
    const { type = 'text', value, dataTestId, size = 'small', className, disabled, selectAllTextOnFirstFocus, suffix, cursorPointer, showDropdownIcon, rootClassName, inputWrapperClassName, errorText, labelTooltip, label, register, onFocus, onBlur, startIcon, id, children, 
    // Input props that can be directly spread out without any modification
    ...inputProps } = props;
    const [focused, setFocused] = React.useState(false);
    const [isFirstFocus, setIsFirstFocus] = React.useState(true);
    const [inputMaxWidth, setMaxWidth] = React.useState('auto');
    const mainValue = isBoolean(value) ? value?.toString() : value;
    const rootWrapperRef = React.useRef(null);
    // If label is provided, we subtract the width of label with input to prevent overlapping
    React.useEffect(() => {
        if (!suffix || !rootWrapperRef)
            return;
        const suffixElement = rootWrapperRef.current?.querySelector('.input-suffix');
        const totalWidth = rootWrapperRef?.current?.offsetWidth;
        const suffixElWidth = suffixElement?.clientWidth;
        if (totalWidth && suffixElWidth) {
            setMaxWidth(`${totalWidth - suffixElWidth - 4}px`);
        }
    }, [suffix]);
    React.useEffect(() => {
        if (errorText)
            setFocused(false);
    }, [errorText]);
    return (_jsxs("div", { ref: rootWrapperRef, "data-testid": dataTestId, className: `w-full ${rootClassName} flex flex-col gap-2 items-start justify-between`, children: [(children || label) && (_jsxs("div", { className: "flex items-center justify-between w-full", children: [_jsxs("div", { children: [label && (_jsx("label", { htmlFor: id, className: classNames('typography-label text-content-default'), children: label })), labelTooltip && (_jsx(Tooltip, { title: labelTooltip, arrow: true, children: _jsx(InfoIcon, { className: "ml-1 w-4.5 h-4.5 text-sky-dark" }) }))] }), children] })), _jsxs("div", { "aria-disabled": disabled, className: classnames(inputWrapperClassName, `relative w-full border-box overflow-hidden border-solid border-[1.5px]`, 'bg-int-overlay-secondary-enabled placeholder-content-muted', 'text-input-value-default typography-label-default-default rounded-br30', {
                    'border-int-fill-negative-enabled border': errorText,
                    'h-[38px]': size === 'small',
                    'h-10.5': size === 'medium',
                    'h-11.5': size === 'large',
                    'border-int-outline-secondary-enabled border': !errorText
                }, !errorText &&
                    !disabled &&
                    classNames('active:border-int-outline-secondary-pressed hover:border-int-outline-secondary-hover', 'border-int-outline-secondary-enabled focus-within:border-int-outline-secondary-selected-enabled focus-within:shadow-int-focus-outside')), children: [startIcon && (_jsx("div", { className: classnames('border-r border-solid border-border pl-p30 pr-p30 absolute', 'h-full top-0 left-0 flex items-center pointer-events-none'), children: startIcon })), _jsx("input", { "data-testid": `${dataTestId}-input`, type: type, value: mainValue, disabled: disabled, size: 1, ref: register, id: id, onFocus: (e) => {
                            setFocused(true);
                            if (isFirstFocus) {
                                setIsFirstFocus(false);
                                if (selectAllTextOnFirstFocus)
                                    e.target.select();
                            }
                            if (onFocus) {
                                onFocus(e);
                            }
                        }, onBlur: (e) => {
                            setFocused(false);
                            if (onBlur) {
                                onBlur(e);
                            }
                        }, style: {
                            maxWidth: inputMaxWidth
                        }, ...inputProps, className: classnames('w-full flex items-center h-full typography-label border-none box-border', 'overflow-ellipsis whitespace-nowrap text-input-value-default typography-label-default-default', 
                        // Hover state
                        'hover:border-int-outline-secondary-hover hover:bg-int-overlay-secondary-hover hover:text-input-value-hover', 
                        // Active state
                        'active:border-int-outline-secondary-pressed active:bg-int-overlay-secondary-pressed active:text-input-value-pressed', 
                        // Focus state
                        'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled focus-visible:bg-int-overlay-secondary-enabled', 'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled data-[open=true]:bg-int-overlay-secondary-enabled', {
                            'pl-3': !startIcon,
                            'pl-9': startIcon,
                            'pr-3': !showDropdownIcon,
                            'pr-5': showDropdownIcon,
                            'px-3 typography-label': size === 'small',
                            'px-4 typography-label-large': size === 'medium',
                            'px-4 text-lg': size === 'large',
                            'cursor-pointer': cursorPointer && !disabled,
                            'cursor-not-allowed text-content-muted placeholder-content-muted bg-int-overlay-secondary-disabled': disabled
                        }, className) }), showDropdownIcon && (_jsx(StyledDropdownIcon, { className: "pointer-events-none" })), suffix && (_jsx("div", { className: "input-suffix absolute h-full top-0 right-0 flex items-center typography-label-small pr-2 text-content-weak", children: suffix }))] }), errorText && !focused && (_jsxs("p", { className: "error-text flex items-center text-int-fill-negative-enabled typography-label-small", "data-testid": `${dataTestId}-error-text`, children: [_jsx(ReportProblemOutlinedIcon, { className: "text-int-fill-negative-enabled w-4 h-4 mr-0.5" }), _jsx("div", { dangerouslySetInnerHTML: { __html: errorText } })] }))] }));
};
export default TextField;
