import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { generateSecondaryTypeClasses } from '../../../harmonic-components/Button/utils';
import { useShallowSidebarStore } from '../../../stores/sidebarStore';
const LeftPanelClosed = ({ className }) => (_jsx("span", { className: classNames('icon icon-rounded icon-filled icon-20', className), children: "left_panel_close" }));
const LeftPanelOpen = ({ className }) => (_jsx("span", { className: classNames('icon icon-rounded icon-filled icon-20', className), children: "left_panel_open" }));
const Menu = ({ className }) => (_jsx("span", { className: classNames('icon icon-rounded icon-filled icon-20', className), children: "menu" }));
export const DashboardSidebarMenuButton = () => {
    const { isAnimating, isFloating, isCollapsed, editSidebar } = useShallowSidebarStore([
        'isAnimating',
        'isFloating',
        'isCollapsed',
        'editSidebar'
    ]);
    const handleClick = useCallback(() => {
        if (isFloating) {
            editSidebar('isCollapsed', false);
        }
        else {
            editSidebar('isCollapsed', !isCollapsed);
        }
        editSidebar('isFloating', !isFloating);
        editSidebar('isAnimating', true);
    }, [editSidebar, isFloating, isCollapsed]);
    const handleMouseEnter = useCallback(() => {
        if (isFloating && !isAnimating && isCollapsed) {
            editSidebar('isCollapsed', false);
        }
    }, [editSidebar, isFloating, isAnimating, isCollapsed]);
    if (!isFloating && !isCollapsed)
        return null;
    return (_jsx(DashboardIconButton, { icon: !isCollapsed ? _jsx(LeftPanelOpen, {}) : _jsx(Menu, {}), onClick: handleClick, onMouseEnter: handleMouseEnter }));
};
export const DashboardSidebarInvisibleButton = () => {
    const { isAnimating, isFloating, isCollapsed, editSidebar } = useShallowSidebarStore([
        'isAnimating',
        'isFloating',
        'isCollapsed',
        'editSidebar'
    ]);
    const toggleSidebarCollapsed = useCallback((isCollapsed) => {
        if (isFloating && !isAnimating) {
            editSidebar('isCollapsed', isCollapsed);
        }
    }, [editSidebar, isFloating, isAnimating]);
    const handleMouseLeave = useCallback((event) => {
        if (event.clientX <= 0) {
            toggleSidebarCollapsed(false);
        }
    }, [toggleSidebarCollapsed]);
    useEffect(() => {
        const handleMouseOutEvent = (event) => {
            // Only trigger if actually leaving the window, not just moving between elements
            if (event.relatedTarget === null) {
                handleMouseLeave(event);
            }
        };
        window.addEventListener('mouseout', handleMouseOutEvent);
        return () => {
            window.removeEventListener('mouseout', handleMouseOutEvent);
        };
    }, [handleMouseLeave]);
    if (!isCollapsed)
        return null;
    return (_jsx("div", { "data-testid": "sidebar-invisible-button", className: "fixed top-0 left-0 w-3 h-full bg-transparent z-10", onMouseEnter: () => toggleSidebarCollapsed(false) }));
};
const DashboardIconButton = ({ onMouseEnter, onClick, icon }) => {
    return (_jsx("button", { className: classNames('flex items-center text-content-weak justify-center', 'focus:shadow-none leading-none p-1', 'rounded-br20', generateSecondaryTypeClasses('low', false, false)), onMouseEnter: onMouseEnter, onClick: onClick, children: icon }));
};
export const DashboardSidebarButton = () => {
    const { isFloating, isCollapsed, editSidebar } = useShallowSidebarStore([
        'isAnimating',
        'isFloating',
        'isCollapsed',
        'editSidebar'
    ]);
    if (isFloating && !isCollapsed)
        return null;
    return (_jsx(DashboardIconButton, { icon: _jsx(LeftPanelClosed, {}), onClick: () => {
            if (isFloating) {
                editSidebar('isCollapsed', false);
            }
            else {
                editSidebar('isCollapsed', !isCollapsed);
            }
            editSidebar('isFloating', !isFloating);
            editSidebar('isAnimating', true);
        } }));
};
