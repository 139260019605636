import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { EmployeeGroupType } from '__generated__/graphql';
import classNames from 'classnames';
import { Badge } from 'common/components';
import ReportDataIssue from 'components/common/ReportDataIssue';
import { ReportSourceSection, ReportSourceView } from 'interfaces/UserReportedDataIssue';
import { get } from 'lodash';
import { getCompanyEmployees } from 'queries/getCompanyEmployees';
import React, { useEffect } from 'react';
import { EMPLOYEE_LIST_PAGE_SIZE } from 'utils/constants';
import { CompanyNavbarOptions } from '../CompanyHeader/CompanyHeader';
import { SeeAllTeamButton } from './CompanyFoundersCeo';
import EmployeesList from './EmployeesList';
const CompanyExecutives = ({ companyId, onTabChange, disableLoadMore }) => {
    const [page, setPage] = React.useState(0);
    const [employees, setEmployees] = React.useState([]);
    const { data, loading } = useQuery(getCompanyEmployees, {
        variables: {
            companyUrn: `urn:harmonic:company:${companyId}`,
            page: page * EMPLOYEE_LIST_PAGE_SIZE,
            size: EMPLOYEE_LIST_PAGE_SIZE,
            employeeGroupType: EmployeeGroupType.EXECUTIVES,
            userConnectionStatus: null
        }
    });
    const totalCount = get(data, 'getEmployeesByCompanyId.totalCount', 0);
    useEffect(() => {
        if (data) {
            const newEmployeesEdges = get(data, 'getEmployeesByCompanyId.edges', []);
            const newEmployees = newEmployeesEdges.map((edge) => edge.node);
            setEmployees([...employees, ...newEmployees]);
        }
    }, [data]);
    if (!loading && totalCount === 0)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between items-center py-p60 border-b border-solid border-border", children: [_jsxs("div", { className: "flex gap-g40 items-center", children: [_jsx("h1", { className: classNames('text-content-title typography-title-small'), children: "Executives" }), _jsx(Badge, { label: totalCount?.toString(), color: "neutral", size: "large" })] }), onTabChange === undefined && (_jsx(ReportDataIssue, { reportParams: {
                            companyUrn: 'urn:company:harmonic:' + companyId,
                            reportSourceView: ReportSourceView.COMPANY,
                            reportSourceSection: ReportSourceSection.FINANCING
                        }, placeholderText: "The company is showing incorrect executives" })), onTabChange !== undefined && (_jsx("div", { className: "flex justify-center items-center", children: _jsx(SeeAllTeamButton, { onClick: () => onTabChange(CompanyNavbarOptions.TEAM) }) }))] }), _jsx("div", { className: "py-p80", children: _jsx(EmployeesList, { employees: employees, totalCount: totalCount, onLoadMore: () => setPage(page + 1), disableLoadMore: disableLoadMore, loading: loading, currentCompanyId: companyId }) })] }));
};
export default CompanyExecutives;
