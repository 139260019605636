import { useShallowStore } from '../stores/zustandStore';
import { SPLITS } from '../utils/constants';
import useFlags from './useFlags';
export const useCompanySkipVariables = () => {
    const { tableColumns } = useShallowStore(['tableColumns']);
    const { enabled: enableListColumnInSearch } = useFlags(SPLITS.enableListColumnInSearch);
    const selectedByTableColumnId = {};
    for (const column of tableColumns) {
        selectedByTableColumnId[column.id] = column.isVisibleOnTable;
    }
    const getSkipByColumnId = (id) => {
        return !selectedByTableColumnId[id];
    };
    const skipTeamLinkedinProfiles = getSkipByColumnId('company.person_linkedin_profiles');
    const skipTeamProfiles = getSkipByColumnId('company.person_relationships');
    const skipPeople = skipTeamLinkedinProfiles && skipTeamProfiles;
    const skipUserConnections = getSkipByColumnId('company.user_connections');
    let skipAffinityLists = getSkipByColumnId('company.affinity_lists');
    const skipInvestors = getSkipByColumnId('company.investors');
    const skipLists = getSkipByColumnId('company.lists') || !enableListColumnInSearch;
    if (!skipLists) {
        skipAffinityLists = false;
    }
    // Traction metrics having multiple column IDs, e.g. company.traction_metrics.headcount.value_14d_ago,
    // so loop through the keys of the objects
    const skipTractionMetrics = !Object.keys(selectedByTableColumnId).some((key) => key.includes('company.traction_metrics'));
    return {
        skipExtended: false,
        skipPeople,
        skipUserConnections,
        skipAffinityLists,
        skipInvestors,
        skipTractionMetrics,
        skipLists,
        skipEntityActions: true
    };
};
