// Returns all the funding rounds that a investor has invested in
import { getFundingTypeDisplayName, SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import { get, isNil, isNumber, uniqBy } from 'lodash';
import { getIdFromUrn } from './urn';
import { truncateMoneyValue } from './utilities';
const FundingRoundBadgeColorMap = {
    Debt: 'expressive-42',
    'Debt Financing': 'expressive-42',
    'Venture Debt': 'expressive-42',
    'Private Equity': 'expressive-42',
    'Series K+': 'expressive-42',
    'Series Pre-Seed': 'expressive-37',
    Preseed: 'expressive-37',
    'Pre-Seed': 'expressive-37',
    Seed: 'expressive-1',
    'Series Seed': 'expressive-1',
    'Series A': 'expressive-5',
    'Series A (Ext)': 'expressive-5',
    'Series B': 'expressive-9',
    'Series B (Ext)': 'expressive-9',
    'Series C': 'expressive-12',
    'Series C (Ext)': 'expressive-12',
    'Series D': 'expressive-18',
    'Series D (Ext)': 'expressive-18',
    'Series E': 'expressive-27',
    'Series E (Ext)': 'expressive-27',
    'Series F': 'expressive-32',
    'Series F (Ext)': 'expressive-32',
    'Series G': 'expressive-37',
    'Series G (Ext)': 'expressive-37',
    'Series H': 'expressive-40',
    'Series I': 'expressive-42',
    'Series J': 'expressive-44',
    'Series 1': 'expressive-2',
    'Series A1': 'expressive-6',
    'Series A2': 'expressive-7',
    'Series 2': 'expresive-8',
    'Series A3': 'expressive-10',
    'Series C1': 'expressive-13',
    'Series B2': 'expressive-11',
    'Series AA': 'expressive-12',
    'Series 3': 'expressive-4',
    'Series D1': 'expressive-19',
    'Series C2': 'expressive-20',
    'Series B3': 'expressive-21',
    'Series BB': 'expressive-22',
    'Series E1': 'expressive-23',
    'Series D2': 'expressive-24',
    'Series C3': 'expressive-25',
    'Series AAA': 'expressive-30',
    'Series CC': 'expressive-45',
    'Series E2': 'expressive-41',
    'Series D3': 'expressive-42'
};
const FundingRoundChartColorMap = {
    Debt: '#A63A74',
    'Debt Financing': '#A63A74',
    'Pre-Seed': '#913AA6',
    Seed: '#A63A3A',
    'Series A': '#A6743A',
    'Series B': '#9FA63A',
    'Series C': '#74A63A',
    'Series D': '#3AA657',
    'Series E': '#3A74A6',
    'Series F': '#493AA6',
    'Series G': '#913AA6',
    'Series H': '#A63A91',
    'Series I': '#A63A74',
    'Series J': '#A63A57',
    'Series 1': '#A6493A',
    'Series A1': '#A6823A',
    'Series A2': '#A6913A',
    'Series 2': '#A69F3A',
    'Series A3': '#91A63A',
    'Series C1': '#65A63A',
    'Series B2': '#82A63A',
    'Series AA': '#74A63A',
    'Series 3': '#A6653A',
    'Series D1': '#3AA665',
    'Series C2': '#3AA674',
    'Series B3': '#3AA682',
    'Series BB': '#3AA691',
    'Series E1': '#3AA69F',
    'Series D2': '#3A9FA6',
    'Series C3': '#3A91A6',
    'Series AAA': '#3A49A6',
    'Series CC': '#A63A49',
    'Series E2': '#A63A82',
    'Series D3': '#A63A74'
};
export const getFundingStageBadgeColor = (stage) => {
    if (!get(FundingRoundBadgeColorMap, stage))
        return 'neutral';
    return (FundingRoundBadgeColorMap?.[stage] ??
        'expressive-32');
};
export const getFundingStageGraphColor = (stage) => {
    return FundingRoundChartColorMap?.[stage?.trim()] || '#493AA6';
};
// Eg: [investor:1]: [Seed A, Seed B, Series A]
export const getInvestorFundingRoundsMap = (fundingRounds) => {
    const investorFundingRoundsMap = {};
    // Reversing so the oldest rounds are first. This is to maintain the order of Pre-seed, seed, Series A and so on
    const reversedFundingRounds = [...fundingRounds].reverse();
    for (const fundingRound of reversedFundingRounds) {
        const fundingDisplayName = getFundingTypeDisplayName(fundingRound?.fundingRoundType);
        for (const investorInfo of fundingRound?.investors ?? []) {
            if (investorInfo?.entityUrn) {
                const currentInvestments = investorFundingRoundsMap[investorInfo.entityUrn] || [];
                const hasExistingEntry = currentInvestments.some((investment) => investment.name === fundingDisplayName);
                if (!hasExistingEntry)
                    investorFundingRoundsMap[investorInfo.entityUrn] = [
                        ...currentInvestments,
                        {
                            isLead: investorInfo.isLead ?? false,
                            name: fundingDisplayName
                        }
                    ];
            }
        }
    }
    return investorFundingRoundsMap;
};
export const getFormattedFundingTotal = (fundingTotal, fundingAttributeNullStatus) => {
    const fundingTotalValue = !isNil(fundingTotal)
        ? parseInt(fundingTotal)
        : null;
    const formattedValue = isNumber(fundingTotalValue) &&
        !isNaN(fundingTotalValue) &&
        fundingTotalValue !== 0
        ? truncateMoneyValue(fundingTotalValue)
        : get(SearchValueToDisplayNameMap, fundingAttributeNullStatus || 'UNKNOWN');
    return formattedValue;
};
export const getSortedFundingRoundInvestors = (fundingRounds) => {
    const allInvestors = fundingRounds.flatMap((round) => round.investors);
    allInvestors.sort((a, b) => (b?.isLead ? 1 : -1) - (a?.isLead ? 1 : -1));
    const uniqueInvestors = uniqBy(allInvestors, (i) => {
        const id = getIdFromUrn(i?.entityUrn);
        return id?.toString() !== '-1' ? i?.entityUrn : i?.investor?.name;
    });
    return uniqueInvestors;
};
