import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import Tag from '../../../../../harmonic-components/Tag/Tag';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { stringToExpressiveNumber } from '../../../../../utils/design';
export const Tags = ({ tags }) => {
    const memoizedTags = useMemo(() => {
        if (!tags || tags.length === 0)
            return null;
        return tags.map((tag, index) => (_jsx("div", { children: _jsx(Tag, { label: tag, color: `expressive-${stringToExpressiveNumber(tag)}`, whiteSpace: "normal" }) }, `${tag}-${index}`)));
    }, [tags]);
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 56, children: memoizedTags }) }));
};
