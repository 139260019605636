var TagType;
(function (TagType) {
    TagType["UNKNOWN"] = "UNKNOWN";
    TagType["PRODUCT_HUNT"] = "PRODUCT_HUNT";
    TagType["YC_BATCH"] = "YC_BATCH";
    TagType["INDUSTRY"] = "INDUSTRY";
    TagType["EXTERNAL_INDUSTRY"] = "EXTERNAL_INDUSTRY";
    TagType["TECHNOLOGY"] = "TECHNOLOGY";
    //V2 Tag Types
    TagType["PRODUCT_TYPE"] = "PRODUCT_TYPE";
    TagType["CUSTOMER_TYPE"] = "CUSTOMER_TYPE";
    TagType["TECHNOLOGY_TYPE"] = "TECHNOLOGY_TYPE";
    TagType["MARKET_VERTICAL"] = "MARKET_VERTICAL";
})(TagType || (TagType = {}));
export const TagTypeToLabelMapping = {
    [TagType.UNKNOWN]: 'Unknown',
    [TagType.PRODUCT_HUNT]: 'Product Hunt',
    [TagType.YC_BATCH]: 'YC Batch',
    [TagType.INDUSTRY]: 'Industry',
    [TagType.EXTERNAL_INDUSTRY]: 'External Industry',
    [TagType.TECHNOLOGY]: 'Technology',
    [TagType.PRODUCT_TYPE]: 'Product Type',
    [TagType.CUSTOMER_TYPE]: 'Customer Type',
    [TagType.TECHNOLOGY_TYPE]: 'Technology Type',
    [TagType.MARKET_VERTICAL]: 'Market Vertical'
};
