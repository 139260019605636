import { useMemo } from 'react';
import { useShallowFilterStore } from '../stores/filterStore';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
export const useSearchSort = () => {
    const { urn, location } = useDashboardLocation();
    const { savedSearchPageSort, peopleSavedSearchPageSort, companyExplorePageSort, peopleExplorePageSort, companyWatchlistPageSort, peopleWatchlistPageSort } = useShallowFilterStore([
        'savedSearchPageSort',
        'peopleSavedSearchPageSort',
        'companyExplorePageSort',
        'peopleExplorePageSort',
        'companyWatchlistPageSort',
        'peopleWatchlistPageSort'
    ]);
    // We are reading the sort value for the current page from zustand and any updates to that sort value will
    // trigger a refetch of the results.
    return useMemo(() => {
        if (!urn && location === DashboardLocation.COMPANY_SEARCH) {
            return companyExplorePageSort;
        }
        if (!urn && location === DashboardLocation.PEOPLE_SEARCH) {
            return peopleExplorePageSort;
        }
        if (urn && location === DashboardLocation.COMPANY_SEARCH) {
            return savedSearchPageSort;
        }
        if (urn && location === DashboardLocation.PEOPLE_SEARCH) {
            return peopleSavedSearchPageSort;
        }
        if (location === DashboardLocation.PERSON_LIST) {
            return peopleWatchlistPageSort;
        }
        if (location === DashboardLocation.COMPANY_LIST) {
            return companyWatchlistPageSort;
        }
        return undefined;
    }, [
        companyExplorePageSort,
        companyWatchlistPageSort,
        location,
        peopleExplorePageSort,
        peopleSavedSearchPageSort,
        peopleWatchlistPageSort,
        savedSearchPageSort,
        urn
    ]);
};
