import { gql } from '@apollo/client';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from './getPeopleWatchlists';
export const REMOVE_PEOPLE_FROM_WATCHLIST = gql `
  mutation RemovePeopleFromWatchlistWithIds(
    $watchlist: String!
    $people: [String]!
  ) {
    removePeopleFromWatchlistWithIds(id: $watchlist, people: $people) {
      ...PeopleWatchlist
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;
