import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import stealthLogo from 'assets/company-profile/stealth-logo.svg';
import classNames from 'classnames';
import AddCompanyToWatchlistButton from 'components/common/CompanyActions/AddCompanyToWatchlistButton';
import CompanyNotesButton from 'components/common/CompanyActions/CompanyNotesButton';
import LikeCompanyButton from 'components/common/CompanyActions/LikeCompanyButton';
import FoundingDate from 'components/common/FoundingDate';
import Location from 'components/common/Location';
import PersonUserConnections from 'components/common/PersonUserConnections';
import RelevanceScore from 'components/common/RelevanceScore';
import ReportDataIssue from 'components/common/ReportDataIssue';
import Socials from 'components/common/Social/Socials';
import { ReportSourceSection, ReportSourceView } from 'interfaces/UserReportedDataIssue';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { SPLITS } from 'utils/constants';
import { isSafeHostedAsset } from 'utils/utilities';
import { MAX_WIDTH_CLASS } from '../CompanyV2';
import UserConnectionsTooltip from 'components/common/UserConnectionsTooltip';
import TopNavigationBar from 'harmonic-components/TopNavigationBar/TopNavigationBar';
import useFlags from 'hooks/useFlags';
import { SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import { compact, get } from 'lodash';
import getCompanyUserConnections from 'queries/getCompanyUserConnections';
import { ReactComponent as ChevronLeftIcon } from 'assets/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/chevron-right.svg';
import { Badge } from 'common/components';
import AddCompanyToAffinityButton from 'components/common/CompanyActions/AddCompanyToAffinityButton';
import InvestorViewToggle from 'components/common/InvestorViewToggle';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Tabs from 'harmonic-components/Tabs/Tabs';
import { EntityType } from 'hooks/useDashboardLocation';
import { useEffect, useRef, useState } from 'react';
import useStore from 'stores/zustandStore';
import { getFundingStageBadgeColor } from 'utils/funding';
import { isHarmonicUser, urnFrom } from 'utils/urn';
import CompanyAbout from '../CompanyOverview/CompanyAbout';
export var CompanyNavbarOptions;
(function (CompanyNavbarOptions) {
    CompanyNavbarOptions["OVERVIEW"] = "OVERVIEW";
    CompanyNavbarOptions["TEAM"] = "TEAM";
    CompanyNavbarOptions["FINANCING"] = "FINANCING";
    CompanyNavbarOptions["SIMILAR_COMPANIES"] = "SIMILAR_COMPANIES";
    CompanyNavbarOptions["TRACTION_METRICS"] = "TRACTION_METRICS";
    CompanyNavbarOptions["CONTACT"] = "CONTACT";
    CompanyNavbarOptions["CONNECTIONS"] = "CONNECTIONS";
    CompanyNavbarOptions["LINEAGE"] = "LINEAGE";
})(CompanyNavbarOptions || (CompanyNavbarOptions = {}));
const nonStealthNavbarOptions = [
    {
        value: CompanyNavbarOptions.OVERVIEW,
        label: 'Overview'
    },
    {
        value: CompanyNavbarOptions.TEAM,
        label: 'Team '
    },
    {
        value: CompanyNavbarOptions.FINANCING,
        label: 'Financing'
    },
    {
        value: CompanyNavbarOptions.TRACTION_METRICS,
        label: 'Traction Metrics'
    },
    {
        value: CompanyNavbarOptions.CONTACT,
        label: 'Contact'
    },
    {
        value: CompanyNavbarOptions.LINEAGE,
        label: 'Lineage'
    },
    {
        value: CompanyNavbarOptions.SIMILAR_COMPANIES,
        label: 'Similar Companies',
        inBeta: true
    },
    {
        value: CompanyNavbarOptions.CONNECTIONS,
        label: 'Network',
        inBeta: true
    }
];
export const stealthNavbarOptions = [
    {
        value: CompanyNavbarOptions.OVERVIEW,
        label: 'Overview'
    },
    {
        value: CompanyNavbarOptions.CONTACT,
        label: 'Contact'
    },
    {
        value: CompanyNavbarOptions.CONNECTIONS,
        label: 'Network',
        inBeta: true
    }
];
const CompanyHeader = ({ companyId, isSticky, isStealthCompany, selectedTab, onTabChange }) => {
    // Scroll buttons for the mobile navbar
    const scrollContainerRef = useRef(null);
    const [showLeftScroll, setShowLeftScroll] = useState(false);
    const [showRightScroll, setShowRightScroll] = useState(false);
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            checkScrollButtons();
            scrollContainer.addEventListener('scroll', checkScrollButtons);
            window.addEventListener('resize', checkScrollButtons);
            return () => {
                scrollContainer.removeEventListener('scroll', checkScrollButtons);
                window.removeEventListener('resize', checkScrollButtons);
            };
        }
    }, []);
    const { enabled: enableCompanyEngagementTab } = useFlags(SPLITS.enableCompanyEngagementTab);
    const { enabled: enableCompanyLineageTab } = useFlags(SPLITS.showLineageOnCompanyPage);
    const { enabled: enablePushToAffinity } = useFlags(SPLITS.enablePushToAffinity);
    const previousPageTitle = useStore((state) => state.previousPageTitle);
    const { data } = useQuery(getCompanyProfileHeader, {
        variables: {
            id: companyId
        },
        fetchPolicy: 'cache-only'
    });
    const customerUrn = useStore((state) => state.customerUrn);
    const { data: userConnectionsData } = useQuery(getCompanyUserConnections, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const userConnections = compact(compact(userConnectionsData?.getCompanyById?.userConnections).map((connection) => connection.user)) ?? [];
    if (!data?.getCompanyById)
        return null;
    const logo = data.getCompanyById.logoUrl;
    const companyName = data.getCompanyById.name;
    const location = data.getCompanyById.location;
    const foundingDate = data.getCompanyById.foundingDate?.date;
    const companyStage = data.getCompanyById.stage;
    const convertedFundingStage = (companyStage && get(SearchValueToDisplayNameMap, companyStage)) ?? '';
    const website = data.getCompanyById.website;
    const linkedin = data.getCompanyById.socials?.linkedin;
    const crunchbase = data.getCompanyById.socials?.crunchbase;
    const pitchbook = data.getCompanyById.socials?.pitchbook;
    const angellist = data.getCompanyById.socials?.angellist;
    const twitter = data.getCompanyById.socials?.twitter;
    const facebook = data.getCompanyById.socials?.facebook;
    const relevanceScore = data.getCompanyById.relevanceScore;
    const instagram = data.getCompanyById.socials?.instagram;
    const investorUrn = data.getCompanyById.investorUrn;
    const fundingInspectorUrl = `https://harmonic.retool.com/app/fundinginspector#companyId=${companyId}`;
    const navbarOptions = isStealthCompany
        ? stealthNavbarOptions
        : nonStealthNavbarOptions;
    const currentOption = navbarOptions.find((option) => option.value === selectedTab)?.value;
    const companyLogoUrl = isStealthCompany
        ? stealthLogo
        : isSafeHostedAsset(logo ?? '')
            ? logo
            : defaultCompanyLogo;
    let filteredNavbarOptions = navbarOptions;
    if (!enableCompanyEngagementTab) {
        filteredNavbarOptions = filteredNavbarOptions.filter((option) => option.value !== CompanyNavbarOptions.CONNECTIONS);
    }
    if (enableCompanyEngagementTab) {
        filteredNavbarOptions = filteredNavbarOptions.filter((option) => option.value !== CompanyNavbarOptions.CONTACT);
    }
    if (!enableCompanyLineageTab) {
        filteredNavbarOptions = filteredNavbarOptions.filter((option) => option.value !== CompanyNavbarOptions.LINEAGE);
    }
    const navigationTitle = previousPageTitle
        ? `${previousPageTitle} / ${companyName}  /`
        : companyName;
    const titleComponent = (_jsxs("div", { className: "flex gap-g40 items-center", children: [_jsx(Avatar, { src: companyLogoUrl ?? '', alt: "Company Logo" }), _jsx("p", { className: classNames('typography-title-medium text-content-title'), children: companyName }), investorUrn && (_jsx(InvestorViewToggle, { baseEntityUrn: urnFrom(EntityType.COMPANY, companyId), investorUrn: investorUrn }))] }));
    const mainCompanyActions = (_jsxs("div", { className: "flex flex-row items-center gap-g20 lg:gap-g40", children: [_jsx(AddCompanyToWatchlistButton, { companyId: companyId }), enablePushToAffinity && (_jsx(AddCompanyToAffinityButton, { companyId: companyId, isDisabled: isStealthCompany ?? false, tooltipText: isStealthCompany
                    ? 'Only companies with a website URL can be added'
                    : undefined })), _jsx(LikeCompanyButton, { companyId: companyId }), _jsx(CompanyNotesButton, { companyId: companyId }), _jsx(ReportDataIssue, { showCircularBorder: true, reportParams: {
                    companyUrn: `urn:company:harmonic:${companyId}`,
                    reportSourceView: ReportSourceView.COMPANY,
                    reportSourceSection: ReportSourceSection[currentOption]
                } })] }));
    const relevanceScoreComponent = (_jsxs("div", { className: "flex flex-row items-center gap-x-g20 lg:gap-x-g40", children: [relevanceScore && _jsx(RelevanceScore, { score: relevanceScore }), convertedFundingStage && (_jsx(Badge, { label: convertedFundingStage, color: getFundingStageBadgeColor(convertedFundingStage), size: "large", intensity: "subtle", dataTestId: "CompanyHeader-FundingStage" }))] }));
    const socialMediaComponent = (_jsx(Socials, { id: companyId, type: "company", websiteUrl: website?.url, linkedinUrl: linkedin?.url, crunchbaseUrl: crunchbase?.url, pitchbookUrl: pitchbook?.url, angellistUrl: angellist?.url, twitterUrl: twitter?.url, facebookUrl: facebook?.url, instagramUrl: instagram?.url, ...(isHarmonicUser(customerUrn) && {
            fundingInspectorUrl: fundingInspectorUrl
        }) }));
    const locationAndFoundingDate = (_jsxs("div", { className: "flex items-center", children: [_jsx(Location, { city: location?.city, state: location?.state, country: location?.country, fontSize: "small" }), foundingDate && (_jsx("div", { className: "ml-p50", children: _jsx(FoundingDate, { date: foundingDate }) }))] }));
    const linkedInNetwork = userConnectionsData && userConnections.length > 0 && (_jsxs("div", { className: "flex items-center justify-between gap-g20", children: [_jsx("button", { onClick: () => onTabChange(CompanyNavbarOptions.CONNECTIONS), children: _jsx(PersonUserConnections, { userConnections: userConnections }) }), _jsx("div", { children: _jsx(UserConnectionsTooltip, {}) })] }));
    const companyAboutComponent = _jsx(CompanyAbout, { companyId: companyId });
    const checkScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setShowLeftScroll(scrollLeft > 0);
            setShowRightScroll(scrollLeft < scrollWidth - clientWidth);
        }
    };
    const scroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 200; // Adjust this value as needed
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };
    const desktopNavBar = (_jsx(Tabs, { tabs: filteredNavbarOptions.map((navbar) => ({
            ...navbar,
            variation: 'label'
        })), selectedTabValue: selectedTab, onTabChange: (newTabValue) => onTabChange(newTabValue) }));
    const stickyNavBar = (_jsxs("div", { className: "flex flex-col gap-g50 w-full h-fit bg-transparent shadow-search px-p70 py-p50", children: [_jsxs("div", { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex gap-g50 items-center", children: [_jsx(Avatar, { src: companyLogoUrl ?? '', alt: "Company Logo", size: "large" }), _jsx("p", { className: classNames('typography-label text-content-title'), children: companyName })] }), mainCompanyActions] }), desktopNavBar] }));
    const mobileNavBar = (_jsxs("div", { className: "relative", children: [showLeftScroll && (_jsx("button", { onClick: () => scroll('left'), className: "absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-gradient-to-r from-white to-transparent pl-p20 pr-p40 py-p20", children: _jsx(ChevronLeftIcon, { className: "w-4 h-4 text-content-primary" }) })), showRightScroll && (_jsx("button", { onClick: () => scroll('right'), className: "absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-gradient-to-l from-white to-transparent pr-p20 pl-p40 py-p20", children: _jsx(ChevronRightIcon, { className: "w-4 h-4 text-content-primary" }) })), _jsx("div", { ref: scrollContainerRef, className: "overflow-x-auto scrollbar-hide relative", children: _jsx("div", { className: "flex w-full min-w-max", children: _jsx(Tabs, { tabs: filteredNavbarOptions.map((navbar) => ({
                            ...navbar,
                            variation: 'label'
                        })), selectedTabValue: selectedTab, onTabChange: (newTabValue) => onTabChange(newTabValue) }) }) })] }));
    return (_jsxs("div", { children: [_jsx(TopNavigationBar, { name: navigationTitle }), _jsxs("div", { "data-testid": "CompanyHeader", className: `pt-[53px] ${MAX_WIDTH_CLASS} relative`, children: [_jsxs("div", { className: "flex flex-col pb-p60 gap-g50 pt-p80", children: [_jsxs("div", { className: "flex flex-col gap-g50 lg:flex-row lg:items-start lg:justify-between", children: [titleComponent, mainCompanyActions] }), _jsx("div", { children: relevanceScoreComponent }), _jsxs("div", { className: "flex gap-x-g50 gap-y-g50 flex-col", children: [_jsxs("div", { className: "flex flex-col gap-g50 lg:flex-row", children: [socialMediaComponent, locationAndFoundingDate] }), linkedInNetwork] }), companyAboutComponent] }), _jsx("div", { className: "lg:hidden", children: mobileNavBar }), _jsx("div", { className: classNames('hidden', {
                            'lg:flex w-full': !isSticky
                        }), children: desktopNavBar }), isSticky && (_jsx("div", { className: "hidden lg:block sticky z-10 top-[53px]", children: stickyNavBar }))] })] }));
};
export default CompanyHeader;
