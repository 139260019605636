import styled from 'styled-components';
export const LoadingOverlayContainer = styled.div `
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);

  & img {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
  }

  & p {
    position: fixed;
    top: 55%;
    left: 48%;
    margin-right: -50%;
  }
`;
export const LoadingOverlayMessageContainer = styled.p `
  padding-top: 2rem;
`;
