import gql from 'graphql-tag';
import { CustomFieldsFragment } from './getWatchlist';
const GET_WATCHLIST_FRAGMENT = gql `
  fragment CompanyWatchlistWithCustomFields on CompanyWatchlist {
    id
    entityUrn
    name
    customFields {
      ...CustomFields
    }
  }
  ${CustomFieldsFragment}
`;
export const GET_WATCHLIST_WITH_CUSTOM_FIELDS = gql `
  query GetCompanyWatchlistWithCustomFields($idOrUrn: String!) {
    getCompanyWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      ...CompanyWatchlistWithCustomFields
    }
  }
  ${GET_WATCHLIST_FRAGMENT}
`;
