import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
export const DRAWER_WIDTH = 240;
const COLLAPSED_DRAWER_WIDTH = 56;
const TOOLBAR_HEIGHT = 147;
const DashboardPageWrapper = withTheme(styled.div `
  width: 100%;
  .root {
    display: flex;
    background-color: #ffffff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .error {
    margin-top: 200px;
    text-align: center;
    line-height: 3;
  }

  .toolbar {
    ${(props) => props.theme.mixins.toolbar}
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .toolbar-height-empty-div {
    height: ${TOOLBAR_HEIGHT}px;
  }
  .app-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .app-menu--icon {
      margin: 0;
    }
  }

  .app-menu--button {
    min-width: auto;
    margin-right: -6px;
  }

  .app-logo {
    width: 28px;
  }

  .sidebar-list {
    :last-child {
      margin-bottom: 10px;
    }
  }

  .list-icon {
    color: black;
    width: 32px;
    margin-right: 8px;
    cursor: pointer;
  }

  .list-icon-feed {
    width: 20px;
    height: 20px;
    position: relative;
    left: 6px;
  }

  .list-item {
    display: flex !important;

    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & * {
      font-weight: 700;
    }
  }

  .list-item-selected {
    display: flex !important;
    & * {
      color: var(--color-blue);
    }
  }

  .list-item-action {
    color: var(--color-dark-grey);
  }

  .nested-sidebar-item {
    height: 30px;
    font-size: 14px;
  }
  .nested {
    height: 28px;
    padding-left: ${COLLAPSED_DRAWER_WIDTH}px;
    font-family: var(--font-description);
    & * {
      font-family: var(--font-description) !important;
      color: black;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }
  .nested-selected {
    height: 28px;
    padding-left: ${COLLAPSED_DRAWER_WIDTH}px;
    font-family: var(--font-description);
    & * {
      font-family: var(--font-description) !important;
      color: var(--color-blue);
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .grid-actions-toolbar {
    display: flex;
    min-height: 50px;
    max-height: 50px;
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;

      .pagination-controls {
        flex-wrap: wrap;
      }
    }
    @media (max-width: 1025px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .grid-actions-container {
    margin-left: -13px;
    display: flex;
    align-items: center;
  }
  .pagination-container {
    display: flex;
    align-items: center;
  }
  .pagination-controls {
    display: flex;
    align-items: center;
    margin-left: -10px;
  }

  .feeds-loading-icon {
    padding-right: 20px;
  }

  .list-item-text {
    font-weight: 700;
    border-bottom: 0;
    cursor: pointer;

    &:before {
      border-bottom: 0;
    }
  }

  .list-item-text-icon {
    position: relative;
    right: 3px;
    top: -1px;
  }

  .saved-search-list-item {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
  }

  .saved-search-list-title {
    padding-left: 2px;
    margin-left: -2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }

  .button-icon .MuiIconButton-root:hover {
    background-color: none;
  }

  .light-tooltip {
    font-family: var(--font-description);
  }

  .display-name-icon {
    margin-top: 5px;
  }

  .display-name-label {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .menu-item-label {
  }

  .loading {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.01);

    & img {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-right: -50%;
    }

    & p {
      position: fixed;
      top: 55%;
      left: 48%;
      margin-right: -50%;
    }
  }

  .content-grid {
    @media only screen and (max-width: 768px) {
      position: 'absolute';
    }
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 5px;
  }

  .dashboard-header {
    background-color: var(--color-background-grey);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    position: static;
    height: ${TOOLBAR_HEIGHT}px;
  }

  .grid-toolbar {
    display: flex;
    align-items: center;
  }

  .cursor-active {
    cursor: pointer;
  }

  .pagination-controls {
    display: flex;
    align-items: center;
  }

  .pagination-rows-selection {
    margin-left: 10px;
    font-size: 10pt;
  }

  .pagination-text {
    color: #2196f3;
  }

  .grid-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .feed-name {
      font-weight: 600;
      font-size: 21px;
    }

    .icon-btn {
      border-radius: 5px;
      width: 60px;
      height: 35px;
      margin-right: 15px;
      color: black;
    }

    .toggle-filter {
      display: flex;
      align-items: center;
      margin-bottom: -2px;
      color: var(--color-blue);
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        & * {
          font-size: 18px;
        }
      }
    }
  }

  .toolbar-button {
    display: flex;
    align-items: center;
    // min-height: 40px;
    font-family: var(--font-description);
    font-size: 14px;
    text-transform: initial;
    // margin: 0.5rem;
  }

  .toolbar-item {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }

  .toolbar-loading-icon {
    margin-right: 10px;
  }

  .empty-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    line-height: 16px;
    font-size: 14px;
    font-family: var(--font-description);
    color: var(--color-empty-grey);
  }

  .arrow-back-icon {
    margin: auto 10px auto auto;
    cursor: pointer;
  }

  .logo {
    margin: auto;
    border: 1px solid var(--color-border);
    border-radius: 50%;
  }

  .company-title {
    margin-left: 10px;
    margin: auto auto auto 20px;
  }

  .company-content {
    display: block;
    font-size: 20px;
    font-weight: bold;
    font-family: var(--font-description);
  }

  .content-wrapper {
    overflow: hidden;
    max-width: 900px;
    margin-top: 10px;
  }

  .padding-wrapper-person {
    padding: 0 0 30px 30px;
  }

  .padding-wrapper-company {
    padding: 0 30px 30px 30px;
  }

  .divider {
    margin: 5px 0px 30px 0px;
  }

  .mt15 {
    margin-top: 15px;
  }

  .p0 {
    padding: 0px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .custom-width-tooltip {
    font-size: 15px;
    padding: ${(props) => props.theme.spacing(1)}px;
  }
`);
export { DashboardPageWrapper };
