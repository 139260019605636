import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BellIcon from 'assets/harmonic-icons/bell';
import CheckIcon from 'assets/harmonic-icons/check';
import harmonicLogo from 'assets/harmonic-icons/harmonic-gradient-logo.svg';
import Button from 'harmonic-components/Button/Button';
const ComingSoonModal = ({ comingSoonText, joinedText, submitAction, hasJoined, isLoading }) => {
    const dummyRow = (opacity, sizes) => {
        return (_jsx("div", { className: "grid grid-cols-8", style: { opacity: opacity + '%' }, children: sizes.map((pair, index) => (_jsx("div", { className: "px-p60 pt-p60", children: _jsx("div", { className: "bg-slate-500 rounded-br20", style: { width: pair[0] + '%', height: pair[1] + '%' }, children: "\u00A0" }) }, `fake-row-${index}}`))) }));
    };
    const rectangleSizes = [
        [
            ['50', '50'],
            ['80', '50'],
            ['30', '50'],
            ['50', '50'],
            ['60', '50'],
            ['70', '50'],
            ['30', '50'],
            ['60', '50']
        ],
        [
            ['70', '70'],
            ['70', '50'],
            ['50', '50'],
            ['40', '50'],
            ['50', '50'],
            ['30', '50'],
            ['40', '50'],
            ['70', '50']
        ],
        [
            ['70', '70'],
            ['30', '50'],
            ['60', '50'],
            ['30', '50'],
            ['40', '50'],
            ['30', '50'],
            ['30', '50'],
            ['80', '50']
        ],
        [
            ['80', '70'],
            ['70', '50'],
            ['30', '50'],
            ['50', '50'],
            ['50', '50'],
            ['30', '50'],
            ['30', '50'],
            ['70', '50']
        ]
    ];
    return (_jsxs("div", { className: "grid my-g80", children: [_jsxs("div", { className: "col-start-1 row-start-1 opacity-25 md:hidden lg:block", children: [dummyRow('35', rectangleSizes[0]), dummyRow('25', rectangleSizes[1]), dummyRow('15', rectangleSizes[2]), dummyRow('5', rectangleSizes[3])] }), _jsx("div", { className: "col-start-1 row-start-1 opacity-1000", children: _jsx("div", { className: "flex justify-center border-border border border-solid p-p80 rounded-br30", children: _jsxs("div", { className: "max-w-[415px]", children: [_jsx("div", { className: "flex justify-center", children: _jsx("img", { src: harmonicLogo, className: "mb-p60 w-[60px] h-[60px]" }) }), _jsx("div", { className: "typography-title-extra-small text-content-strong text-center mb-g40", children: hasJoined ? joinedText : comingSoonText }), _jsx("div", { className: "flex justify-center ", children: _jsx(Button, { dataTestId: "JoinCapWaitlist-Submit-Button", type: "secondary", emphasis: "medium", leadingIcon: hasJoined ? CheckIcon : BellIcon, label: hasJoined ? 'Joined' : 'Join the waitlist', loading: isLoading, isDisabled: hasJoined, onClick: submitAction }) })] }) }) })] }));
};
export default ComingSoonModal;
