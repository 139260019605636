import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CircularProgress, Link } from '@material-ui/core';
import AuthActionPageWrapper from 'components/Auth/AuthActionPageWrapper';
import { getApp } from 'firebase/app';
import { applyActionCode, getAuth } from 'firebase/auth';
import * as React from 'react';
import { logger } from 'utils/logger';
var VerificationStates;
(function (VerificationStates) {
    VerificationStates[VerificationStates["VERIFIED"] = 0] = "VERIFIED";
    VerificationStates[VerificationStates["EXPIRED"] = 1] = "EXPIRED";
    VerificationStates[VerificationStates["ERROR"] = 2] = "ERROR";
})(VerificationStates || (VerificationStates = {}));
const EmailVerifyHandler = (props) => {
    const { actionCode } = props;
    const auth = getAuth(getApp());
    const [loading, setLoading] = React.useState(true);
    const [verificationState, setVerificationState] = React.useState(undefined);
    const processEmailVerificationCode = React.useCallback(async () => {
        try {
            // Attempt to apply email verification using Firebase
            await applyActionCode(auth, actionCode);
            // Email address has been verified.
            setVerificationState(VerificationStates.VERIFIED);
        }
        catch (error) {
            const firebaseError = error;
            switch (firebaseError && firebaseError.code) {
                case 'auth/expired-action-code':
                    setVerificationState(VerificationStates.EXPIRED);
                    break;
                case 'auth/invalid-action-code':
                    // This error from Firebase can mean two things:
                    //   1. The code is malformed
                    //   2. The code was already verified
                    // Case #2 is way more probably to happen than #1, that is why we treat it
                    // as such so the user have valid feedback in the majority of the cases.
                    setVerificationState(VerificationStates.VERIFIED);
                    break;
                default:
                    // Any other case, something went wrong
                    setVerificationState(VerificationStates.ERROR);
            }
            logger.error(`Error verifying email: ${firebaseError && firebaseError.code}: `, {
                error,
                code_area: 'email_verification'
            });
        }
        finally {
            setLoading(false);
        }
    }, [actionCode, auth]);
    React.useEffect(() => {
        processEmailVerificationCode();
    }, [processEmailVerificationCode]);
    // prettier-ignore
    return (_jsx(AuthActionPageWrapper, { children: _jsxs("form", { className: "form-wrapper", children: [loading && _jsx(CircularProgress, {}), !loading && (_jsxs(_Fragment, { children: [verificationState === VerificationStates.ERROR && (_jsxs(_Fragment, { children: [_jsx("div", { className: "heading-text", children: "Invalid Action Code" }), _jsxs("div", { className: "validation-text", children: ["There was a problem verifying your account. Please contact ", _jsx(Link, { href: "mailto:support@harmonic.ai", children: "support@harmonic.ai" }), "."] })] })), verificationState === VerificationStates.VERIFIED && (_jsxs(_Fragment, { children: [_jsx("div", { className: "heading-text", children: "Email Verified!" }), _jsx("div", { className: "validation-text", children: "Your email address has been successfully verified. You may now proceed to the app." })] })), verificationState === VerificationStates.EXPIRED && (_jsxs(_Fragment, { children: [_jsx("div", { className: "heading-text", children: "Email verification expired" }), _jsx("div", { className: "validation-text", children: "Your verification code has expired. Please login to receive another verification email." })] })), _jsx(Link, { href: "/login", className: "back-link", children: "Back to login" })] }))] }) }));
};
export default EmailVerifyHandler;
