import { gql } from '@apollo/client';
export const CREATE_WATCHLIST = gql `
  mutation CreateWatchlist($watchlistInput: CompanyWatchlistInput!) {
    createCompanyWatchlist(watchlistInput: $watchlistInput) {
      id
      entityUrn
      companyIds
      name
      userWatchlistType
      sharedWithTeam
      owner {
        ... on User {
          entityUrn
        }
        ... on Customer {
          identifier
        }
      }
    }
  }
`;
export const CREATE_WATCHLIST_MOCKED_RESPONSE = {
    data: {
        createCompanyWatchlist: {
            id: 'ab1d04d3-2d3b-4d37-83ae-c0175e3420c9',
            entityUrn: 'urn:harmonic:company_watchlist:ab1d04d3-2d3b-4d37-83ae-c0175e3420c9',
            companyIds: [],
            name: 'New list',
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            sharedWithTeam: false,
            owner: {
                entityUrn: 'urn:harmonic:user:357',
                __typename: 'User'
            },
            __typename: 'CompanyWatchlist'
        }
    }
};
