import { uniqBy } from 'lodash';
import { useAppState } from './useAppState';
const MAX_NUM_USERS_TO_SHOW = 2;
export const useGroupedUserConnections = ({ userConnections }) => {
    const currentUserEmail = useAppState((state) => state.auth.user?.email ?? '');
    const userConnectionsToRender = uniqBy(userConnections, (userConnection) => userConnection.email)
        .map((userConnection) => ({
        person: userConnection,
        userName: userConnection.email === currentUserEmail
            ? 'You'
            : userConnection.name ?? userConnection.email ?? ''
    }))
        .sort((a) => (a.userName === 'You' ? -1 : 1));
    if (userConnectionsToRender.length == 0) {
        return;
    }
    const groupedUserConnectionsMap = {};
    userConnectionsToRender.forEach((connection) => {
        if (!(connection.userName in groupedUserConnectionsMap)) {
            groupedUserConnectionsMap[connection.userName] = [];
        }
        groupedUserConnectionsMap[connection.userName].push(connection);
    });
    const groupedUserConnectionsToRender = Object.values(groupedUserConnectionsMap);
    const connectionsToShowWithoutHover = groupedUserConnectionsToRender.slice(0, MAX_NUM_USERS_TO_SHOW);
    const connectionsToShowBehindHover = groupedUserConnectionsToRender.slice(MAX_NUM_USERS_TO_SHOW);
    return {
        userConnectionsToRender,
        groupedUserConnectionsToRender,
        connectionsToShowWithoutHover,
        connectionsToShowBehindHover
    };
};
