import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ParticipantResponseStatusType } from '__generated__/graphql';
import { CalendarIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import TruncatedList from 'harmonic-components/TruncatedList/TruncatedList';
import SingleParticipant from './SingleParticipant';
const RoundedCalendarIcon = () => (_jsx("div", { "data-testid": "RoundedCalendarIcon", className: "flex items-center p-p20 bg-surface-sentiment-informative rounded-br20", children: _jsx(CalendarIcon, { applyCurrentColor: false, className: "fill-content-sentiment-informative w-3 h-3" }) }));
const CalendarEventCard = ({ name, startTime, participants, participantsResponseStatusMap }) => {
    const formattedTime = startTime
        ? dayjs(startTime).format('MM/DD/YYYY')
        : 'NA';
    const participantsWhoAccepted = participants?.filter((participant) => participantsResponseStatusMap?.[participant?.emailAddress] ===
        ParticipantResponseStatusType.ACCEPTED) ?? [];
    const participantsWhoDeclined = participants?.filter((participant) => participantsResponseStatusMap?.[participant?.emailAddress] ===
        ParticipantResponseStatusType.DECLINED) ?? [];
    const participantsWithoutStatus = participants?.filter((participant) => participantsResponseStatusMap?.[participant?.emailAddress] !=
        ParticipantResponseStatusType.ACCEPTED &&
        participantsResponseStatusMap?.[participant?.emailAddress] !=
            ParticipantResponseStatusType.DECLINED) ?? [];
    return (_jsxs("div", { className: "border border-solid border-border rounded-md", children: [_jsx("div", { className: classNames('flex p-p40', name && 'border-b border-solid border-border'), children: _jsxs("div", { className: "flex-1 flex flex-row justify-between items-center", children: [_jsxs("div", { className: "flex mr-24 h-5 gap-g30", children: [!name && _jsx(RoundedCalendarIcon, {}), _jsx(TruncatedList, { height: 20, wrapperClassName: "items-center", children: participantsWhoAccepted?.map((participant) => (_jsx(SingleParticipant, { ...participant, responseStatus: participantsResponseStatusMap?.[participant?.emailAddress] }, participant.emailAddress))) }), (participantsWhoDeclined?.length > 0 ||
                                    participantsWithoutStatus?.length > 0) && (_jsxs("div", { className: "flex gap-g30", children: [participantsWhoDeclined?.map((participant) => {
                                            return (_jsx(SingleParticipant, { ...participant, responseStatus: participantsResponseStatusMap?.[participant?.emailAddress] }, participant.emailAddress));
                                        }), participantsWithoutStatus?.map((participant) => {
                                            return (_jsx(SingleParticipant, { ...participant, responseStatus: participantsResponseStatusMap?.[participant?.emailAddress] }, participant.emailAddress));
                                        })] }))] }), _jsx("p", { className: "text-content-weak typography-label-small-default", children: formattedTime })] }) }), name && (_jsxs("div", { "data-testid": "EventTitle", className: "flex p-p40 gap-g30 items-center", children: [_jsx(RoundedCalendarIcon, {}), _jsx("div", { children: _jsx("p", { className: "text-content-default typography-label-default-default", children: name }) })] }))] }));
};
export default CalendarEventCard;
