import { ListNamedViewDisplayType } from '__generated__/graphql';
import useFetchSingleWatchlist from 'hooks/useFetchSingleWatchlist';
import useFlags from 'hooks/useFlags';
import { isNil } from 'lodash';
import { EntityType } from '../../../../../hooks/useDashboardLocation';
import { useShallowTableStore } from '../../../../../stores/tableStore';
import { LOADING_ROW_ID, SPLITS } from '../../../../../utils/constants';
export const useIsExtendedLoading = (props) => {
    const { loadedExtendedUrns } = useShallowTableStore(['loadedExtendedUrns']);
    const { enabled: enableKanbanView } = useFlags(SPLITS.enableKanbanView);
    const { companyWatchlist } = useFetchSingleWatchlist({
        fetchPolicy: 'cache-only'
    });
    const view = companyWatchlist?.namedViews?.[0]?.displayType;
    const entityType = props?.data?.person?.id
        ? EntityType.PERSON
        : props?.data?.company?.id
            ? EntityType.COMPANY
            : undefined;
    const id = entityType === EntityType.PERSON
        ? props?.data?.person?.id
        : entityType === EntityType.COMPANY
            ? props?.data?.company?.id
            : undefined;
    const urn = entityType ? `urn:harmonic:${entityType}:${id}` : undefined;
    const shouldShowLoading = urn ? !loadedExtendedUrns[urn] : false;
    if ((shouldShowLoading || props.data?.id === LOADING_ROW_ID) &&
        (isNil(companyWatchlist) ||
            (!isNil(view) && view !== ListNamedViewDisplayType.BOARD) ||
            !enableKanbanView)) {
        return true;
    }
    return false;
};
