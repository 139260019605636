import { SearchStateFilter } from '__generated__/graphql';
import useFilterStore from 'stores/filterStore';
import { BooleanParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { QUERY_PARAM_AUTO_EXPORT_CSV } from 'utils/constants';
import { getURLParamValue } from 'utils/utilities';
import useDashboardLocation from './useDashboardLocation';
const useTableUrlParams = () => {
    const { urn } = useDashboardLocation();
    const searchState = useFilterStore((state) => state.searchStates);
    searchState;
    const [searchStateFilter, setSearchStateFilter] = useQueryParam('searchState', withDefault(StringParam, (urn && searchState[urn]) || SearchStateFilter.NONE));
    const [highlightNewState, setHighlightNewState] = useQueryParam('highlightNew', withDefault(BooleanParam, true));
    const autoExportCsv = getURLParamValue(QUERY_PARAM_AUTO_EXPORT_CSV);
    return {
        autoExportCsv,
        searchStateFilter,
        setSearchStateFilter,
        highlightNewState,
        setHighlightNewState
    };
};
export default useTableUrlParams;
