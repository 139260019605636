import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { useModal } from 'hooks/useModal';
import { EntityListModalType, EntityListType } from 'interfaces/SearchModel/Search';
import { capitalize, isString } from 'lodash';
import React, { useState } from 'react';
import { Form } from '../components/common/Form';
export const UpsertEntityListModal = ({ entityName, onClose, onSubmit, modalType, entityType }) => {
    const [isLoading, setIsLoading] = useState(false);
    let defaultEntityNameTextInputValue = '';
    if (modalType === EntityListModalType.DuplicateList) {
        // We are cloning an existing list. Use the previous name as a placeholder.
        defaultEntityNameTextInputValue = `${entityName} (copy)`;
    }
    const [entityNameTextInputValue, setEntityNameTextInputValuedName] = React.useState(defaultEntityNameTextInputValue);
    const onEntityNameTextInputChange = (event) => {
        if (isString(event.target.value)) {
            setEntityNameTextInputValuedName(event.target.value);
        }
    };
    const entityTypeText = entityType === EntityListType.COMPANY_SAVED_SEARCH
        ? 'company search'
        : entityType === EntityListType.PEOPLE_SAVED_SEARCH
            ? 'people search'
            : entityType === EntityListType.COMPANY_WATCHLIST
                ? 'company list'
                : entityType === EntityListType.PEOPLE_WATCHLIST
                    ? 'people list'
                    : '';
    const actionTitleText = modalType === EntityListModalType.DuplicateList
        ? 'Duplicate'
        : 'Create new';
    const title = capitalize(`${actionTitleText} ${entityName ?? entityTypeText}`);
    const actionButtonLabel = modalType === EntityListModalType.DuplicateList ? 'Duplicate' : 'Create';
    const onActionButtonClicked = async () => {
        setIsLoading(true);
        await onSubmit(entityNameTextInputValue);
        onClose();
    };
    return (_jsxs(Form, { onSubmit: onActionButtonClicked, children: [_jsx(ModalTitle, { title: title }), _jsxs("div", { className: "px-p50 pt-p50 pb-p80 flex flex-col", children: [_jsx("div", { className: "typography-paragraph text-content-default", id: "alert-dialog-description", children: modalType === EntityListModalType.DuplicateList && (_jsxs("p", { className: "mb-g50", children: ["A new ", entityTypeText, " identical to", ' ', _jsx("strong", { children: entityName }), " will be created."] })) }), _jsx(TextInput, { "data-testid": "UpdateEntityListModal-NameTextField", value: entityNameTextInputValue, onChange: onEntityNameTextInputChange, label: "Name" })] }), _jsx(ModalFooter, { children: _jsx(Button, { dataTestId: "EntityListModal-UpsertBtn", onClick: onActionButtonClicked, type: "primary", emphasis: "high", loading: isLoading, label: actionButtonLabel, isDisabled: isLoading }) })] }));
};
export function useUpsertEntityListModal() {
    const { show, isOpen, close } = useModal();
    const showUpsertEntityListModal = (props) => {
        show(_jsx(UpsertEntityListModal, { ...props, onClose: close }));
    };
    return {
        showUpsertEntityListModal,
        isUpsertEntityListModalOpen: isOpen,
        closeUpsertEntityListModal: close
    };
}
