import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SquareArrowTopRightIcon from 'assets/harmonic-icons/square-arrow-top-right';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useState } from 'react';
import { hostnameFromURL } from 'utils/utilities';
const LinkAttribute = ({ link, collapsedInward = false, onClick, dataTestId }) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const onLinkClick = () => {
        window.open(link, '_blank');
        if (onClick)
            onClick();
    };
    // If the link item is in a stack, ie on a grid cell, it’s width doesn’t change on hover, and the text get’s truncated to allow for the copy action.
    if (collapsedInward) {
        return (_jsxs("div", { className: classNames('-ml-p30 pl-p30 pr-p10 inline-flex items-center gap-g10 h-[24px]', 'hover:bg-int-overlay-secondary-hover rounded-br30 relative'), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsx("a", { "data-testid": dataTestId, href: link, target: "_blank", rel: "noreferrer", className: classNames('text-content-default typography-label border-b border-solid border-border line-clamp-1', {
                        'w-[calc(100%-24px)]': isHovered
                    }), onClick: onClick, children: hostnameFromURL(link) }), isHovered && (_jsx("div", { className: "absolute right-[2px] top-[2px]", children: _jsx(IconButton, { onClick: onLinkClick, icon: SquareArrowTopRightIcon, type: "secondary", emphasis: "low", size: "tiny" }) }))] }));
    }
    // If the link item is singularly displayed, or in a vertical list, the action on hover appears to the right of the link, since there’s enough space to do so without moving the other items on the list
    return (_jsxs("div", { className: classNames('group -ml-p30 pl-p30 pr-p10 inline-flex items-center gap-g10 h-[24px]', 'hover:bg-int-overlay-secondary-hover rounded-br30'), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsx("a", { "data-testid": dataTestId, href: link, target: "_blank", rel: "noreferrer", className: classNames('text-content-default typography-label border-b border-solid border-border line-clamp-1'), onClick: onClick, children: hostnameFromURL(link) }), _jsx("div", { className: "invisible group-hover:visible", children: _jsx(IconButton, { onClick: onLinkClick, icon: SquareArrowTopRightIcon, type: "secondary", emphasis: "low", size: "tiny" }) })] }));
};
export default LinkAttribute;
