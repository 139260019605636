export var UserStatusType;
(function (UserStatusType) {
    UserStatusType["ACTIVE"] = "ACTIVE";
    UserStatusType["NOT_ASSIGNED_TO_TEAM"] = "NOT_ASSIGNED_TO_TEAM";
    UserStatusType["PAUSED"] = "PAUSED";
    UserStatusType["PENDING_PILOT_AGREEMENT_ACCEPTANCE"] = "PENDING_PILOT_AGREEMENT_ACCEPTANCE";
    UserStatusType["NO_CONSOLE_ACCESS"] = "NO_CONSOLE_ACCESS";
})(UserStatusType || (UserStatusType = {}));
export var AuthErrorCodes;
(function (AuthErrorCodes) {
    AuthErrorCodes["EMAIL_ALREADY_IN_USE"] = "auth/email-already-in-use";
    AuthErrorCodes["INVALID_EMAIL"] = "auth/invalid-email";
    AuthErrorCodes["USER_NOT_FOUND"] = "auth/user-not-found";
    AuthErrorCodes["INVALID_PASSWORD"] = "auth/wrong-password";
})(AuthErrorCodes || (AuthErrorCodes = {}));
export var LogoutReason;
(function (LogoutReason) {
    LogoutReason[LogoutReason["UserLoggedOut"] = 0] = "UserLoggedOut";
    LogoutReason[LogoutReason["SessionExpired"] = 1] = "SessionExpired";
})(LogoutReason || (LogoutReason = {}));
