export const updateIntercomWithAuth = (result, update) => {
    if (result.user_metadata && result.token) {
        update({
            userId: result.token.user_id,
            name: result.token.displayName,
            email: result.token.email,
            createdAt: Date.now().toString(),
            company: {
                companyId: result.user_metadata.customer_urn
            }
        });
    }
};
