import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ReactComponent as BoxDollarCircle } from 'assets/bxs-dollar-circle.svg';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import { getCompanyFinancing } from 'queries/getCompanyFinancingV2';
import { getFormattedFundingTotal } from 'utils/funding';
const FundingTotal = ({ companyId }) => {
    const { data } = useQuery(getCompanyFinancing, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const companyFunding = data?.getCompanyById?.funding;
    const fundingTotal = getFormattedFundingTotal(companyFunding?.fundingTotal, null);
    return (_jsx(MetricCard, { icon: BoxDollarCircle, value: fundingTotal, label: "Funding total" }));
};
export default FundingTotal;
