import { create } from 'zustand';
import { persist } from 'zustand/middleware';
const initialState = {
    localFlags: {},
    globalEnableHotkeys: false,
    exploreCompaniesVisibleColumns: [],
    explorePeopleVisibleColumns: []
};
const usePersistedZustandStore = create(persist((set, get) => ({
    toggleLocalFlagTreatment: (flagName) => {
        set(() => ({
            localFlags: {
                ...get().localFlags,
                [flagName]: {
                    treatment: get().localFlags[flagName]?.treatment === 'on' ? 'off' : 'on',
                    config: get().localFlags[flagName]?.config
                }
            }
        }));
    },
    setLocalFlags: (flags) => {
        set(() => ({
            localFlags: flags
        }));
    },
    editStore: (key, payload) => set(() => ({
        ...get(),
        [key]: payload
    })),
    ...initialState,
    reset: () => set(() => ({ ...get(), ...initialState }))
}), {
    name: `persisted-global-store`
}));
export default usePersistedZustandStore;
