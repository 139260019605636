import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactComponent as EmailUnlockImage } from 'assets/email-unlock.svg';
import Email from 'harmonic-components/Email/Email';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import React from 'react';
const EmailList = ({ email }) => {
    return (_jsxs("div", { children: [_jsx("p", { className: "flex justify-center typography-label-default-strong pt-p50", children: "Click to copy the email address" }), _jsx("div", { "data-testid": "EmailList", className: "flex flex-row items-start justify-center p-4", children: _jsx("div", { className: "flex flex-col pl-2 gap-g20", children: _jsx("div", { className: "flex flex-col items-center justify-center", children: _jsx(Email, { email: email }, email) }, email) }) })] }));
};
const PersonEmailStaticModal = ({ name, emailAddress }) => {
    return (_jsxs("div", { className: "w-px-480", children: [_jsx(ModalTitle, { title: `${name}'s email address` }), _jsxs("div", { className: "px-p50 py-p80", children: [_jsx("div", { className: "flex w-full justify-center", children: _jsx(EmailUnlockImage, {}) }), _jsx(EmailList, { email: emailAddress })] })] }));
};
export default PersonEmailStaticModal;
