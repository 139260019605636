import { useApolloClient, useMutation } from '@apollo/client';
import { ADD_PEOPLE_TO_WATCHLIST } from 'queries/addPeopleToWatchlist';
import { ADD_PEOPLE_TO_WATCHLIST_WITH_CANONICALS } from 'queries/addPeopleToWatchlistByCanonical';
import { GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST } from 'queries/getUserImportsByPeopleList';
import { WATCHLIST_WITH_PEOPLE } from 'queries/getWatchlistWithPeople';
import { useCallback } from 'react';
import { useShallowTableStore } from 'stores/tableStore';
import { displayToast } from 'utils/toasts';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from '../../queries/getPeopleWatchlists';
import { logger } from '../../utils/logger';
const defaultOptions = {
    disableToast: false
};
const useAddPeopleToList = ({ disableToast } = defaultOptions) => {
    const client = useApolloClient();
    const [addPeopleToWatchlist, { loading: loadingAddPeopleToWatchlist, error: errorAddPeopleToWatchlist }] = useMutation(ADD_PEOPLE_TO_WATCHLIST);
    const [addPeopleToWatchlistWithCanonicals, { loading: loadingAddPeopleToWatchlistWithCanonicals, error: errorAddPeopleToWatchlistWithCanonicals }] = useMutation(ADD_PEOPLE_TO_WATCHLIST_WITH_CANONICALS);
    const { editTableStoreData } = useShallowTableStore(['editTableStoreData']);
    const _deselectRows = useCallback(() => {
        editTableStoreData('selectedRowIds', []);
    }, [editTableStoreData]);
    const addPeopleToWatchlistByCanonical = async (watchlistUrn, canonicals, fileName, flatfileBatchId) => {
        return addPeopleToWatchlistWithCanonicals({
            variables: {
                urn: watchlistUrn,
                canonicals,
                fileName: fileName ?? null,
                flatfileBatchId: flatfileBatchId ?? null
            },
            refetchQueries: [
                {
                    query: WATCHLIST_WITH_PEOPLE,
                    variables: {
                        idOrUrn: watchlistUrn
                    }
                },
                {
                    query: GET_USER_PEOPLE_IMPORTS_BY_PEOPLE_LIST,
                    variables: {
                        peopleListIdOrUrn: watchlistUrn,
                        page: 0,
                        size: 10
                    }
                }
            ]
        });
    };
    const addPeopleToList = async (watchlistId, people) => {
        const isBulkAction = people.length > 1;
        const payload = {
            watchlist: watchlistId,
            people: people.map((id) => `${id}`)
        };
        await addPeopleToWatchlist({
            variables: payload,
            // Update the Person refs in the cache to reflect being added to the watchlist
            update: (cache) => {
                people.forEach((person) => {
                    cache.modify({
                        id: cache.identify({
                            __typename: 'Person',
                            id: person
                        }),
                        fields: {
                            watchlists(existingWatchlists = []) {
                                return [
                                    ...existingWatchlists,
                                    {
                                        __ref: cache.identify({
                                            __typename: 'PeopleWatchlist',
                                            id: watchlistId
                                        })
                                    }
                                ];
                            }
                        }
                    });
                });
            },
            // Refetch the people list to reflect the updates to the watchlist
            onCompleted: () => {
                let watchlist;
                try {
                    watchlist = client.readFragment({
                        id: `PeopleWatchlist:${watchlistId}`,
                        fragment: GET_PEOPLE_WATCHLIST_FRAGMENT,
                        fragmentName: 'PeopleWatchlist'
                    });
                }
                catch (err) {
                    logger.error(`PeopleWatchlist:${watchlist} not found in cache`, {
                        err,
                        code_area: 'grid'
                    });
                }
                if (!disableToast) {
                    displayToast({
                        primaryText: isBulkAction
                            ? `Added ${people.length} people to list${watchlist ? ` ${watchlist?.name}` : ''}`
                            : `Added person to list${watchlist ? ` ${watchlist?.name}` : ''}`,
                        secondaryText: 'Go to list',
                        link: `/dashboard/people_watchlist/urn:harmonic:people_watchlist:${watchlistId}`
                    });
                }
                _deselectRows();
            },
            onError: (error) => {
                if (error.graphQLErrors?.[0].extensions?.response?.status === 403) {
                    if (!disableToast) {
                        displayToast({
                            mode: 'error',
                            primaryText: error.graphQLErrors?.[0].extensions?.response?.body?.detail
                        });
                    }
                }
            },
            // Variables have to exactly match the query variables of PeopleWatchlistData page where it is used to properly update the cache.
            // We should also look into creating proper merge in apollo configs
            refetchQueries: () => [
                {
                    query: WATCHLIST_WITH_PEOPLE,
                    variables: {
                        idOrUrn: `urn:harmonic:people_watchlist:${watchlistId}`
                    }
                }
            ]
        });
    };
    return {
        addPeopleToList,
        addPeopleToWatchlistByCanonical,
        loading: loadingAddPeopleToWatchlist || loadingAddPeopleToWatchlistWithCanonicals,
        error: errorAddPeopleToWatchlist || errorAddPeopleToWatchlistWithCanonicals
    };
};
export default useAddPeopleToList;
