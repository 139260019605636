import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactComponent as AccelLogo } from 'assets/customers/logo-accel.svg';
import { ReactComponent as BrexLogo } from 'assets/customers/logo-brex.svg';
import { ReactComponent as NEALogo } from 'assets/customers/logo-nea.svg';
import { ReactComponent as NotionLogo } from 'assets/customers/logo-notion.svg';
import * as React from 'react';
const TrustedByCustomerLogos = () => {
    return (_jsxs("div", { className: "w-full", children: [_jsx("p", { className: "text-center text-content-weak typography-label-default-strong leading-6", children: "Trusted by Industry-leading Teams" }), _jsxs("div", { className: "flex items-center flex-wrap justify-center  gap-4", children: [_jsx(NotionLogo, { className: "w-22 h-auto", title: "Notion" }), _jsx(NEALogo, { className: "w-16 h-auto", title: "New enterprise associates" }), _jsx(AccelLogo, { className: "w-16 h-auto", title: "Accel" }), _jsx(BrexLogo, { className: "w-20 h-auto", title: "Brex" })] })] }));
};
export default TrustedByCustomerLogos;
