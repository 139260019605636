import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dropdownAnimationData from 'assets/dropdown-loader-animation-lottie.json';
import classNames from 'classnames';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import Lottie from 'lottie-react';
import { useEffect, useRef } from 'react';
const DropdownLoader = () => {
    return (_jsx("div", { className: "h-[204px] flex justify-center items-center w-full", children: _jsx(Lottie, { animationData: dropdownAnimationData }) }));
};
const Dropdown = ({ filterTerm, onFilterTermChange, filterPlaceholder = 'Filter', children, dataTestId, loading, inverted, maxHeight = '500px', scrollIntoView = true }) => {
    const dropdownRef = useRef(null);
    useEffect(() => {
        // Scroll into view if dropdown isn't fully visible
        if (scrollIntoView) {
            setTimeout(() => {
                if (dropdownRef?.current) {
                    const elementRect = dropdownRef.current.getBoundingClientRect();
                    if (elementRect.top < 0 ||
                        elementRect.bottom > window.innerHeight ||
                        elementRect.left < 0 ||
                        elementRect.right > window.innerWidth) {
                        dropdownRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    }
                }
            }, 50);
        }
    }, [dropdownRef, scrollIntoView]);
    return (_jsxs("div", { ref: dropdownRef, "data-testid": dataTestId, style: { maxHeight: maxHeight }, className: classNames('flex flex-col px-p20 pb-p20 items-start self-stretch rounded-br40 border border-solid border-border', `shadow-static-elevation-floating overflow-y-auto`, {
            'bg-surface-default': !inverted,
            'bg-surface-inverted-raised': inverted,
            'pt-p20': !onFilterTermChange
        }), children: [onFilterTermChange && (_jsx("div", { "data-testid": "dropdown-filter", className: classNames('w-full py-p20 sticky top-0 left-0 right-0 z-10', !inverted && 'bg-white', inverted && 'bg-surface-inverted-raised'), children: _jsx(SearchInput, { fullWidth: true, value: filterTerm ?? '', onChange: onFilterTermChange, placeholder: filterPlaceholder, inverted: inverted }) })), loading ? _jsx(DropdownLoader, {}) : children] }));
};
export default Dropdown;
