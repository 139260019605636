import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { compact, get, uniq } from 'lodash';
import React, { useEffect } from 'react';
import useStore from 'stores/zustandStore';
import { getCustomerAffinityFields } from 'utils/midtierApi';
import { AffinityIcon, ChartOutlineIcon, CrossIcon } from 'assets/harmonic-icons';
import { HarmonicLoader } from 'components/common/ResultsWrapper/LoadingOverlay';
import IconButton from 'harmonic-components/IconButton/IconButton';
import useFlags from 'hooks/useFlags';
import PersonOutlineIcon from 'icons/PersonOutlineIcon';
import { SPLITS } from 'utils/constants';
const MockedAffinityLists = [
    {
        name: 'Affinity List 1',
        affinityId: 1,
        fields: [
            {
                name: 'Status',
                value: { text: 'Qualified' }
            },
            {
                name: 'Owners',
                value: { primary_email: 'carter@harmonic.ai' }
            }
        ]
    }
];
const MockedAffinityFields = [
    {
        name: 'Status',
        value: { text: 'Qualified' },
        value_type: 0
    },
    {
        name: 'Owners',
        value: { primary_email: 'Carter Headley' },
        value_type: 0
    }
];
export const GET_AFFINITY_LISTS_QUERY = gql `
  query GetCompaniesAffinityListsById($id: Int!) {
    getCompanyById(id: $id) {
      id
      name
      affinityLists {
        name
        affinityId
      }
    }
  }
`;
const CompanyAffinityListCard = (props) => {
    const { name, fields } = props;
    const statusText = fields.find((field) => field.name == 'Status')?.value?.text ?? 'None';
    const owner = fields.find((field) => field.name == 'Owners')?.value;
    const ownerText = owner?.first_name && owner?.last_name
        ? owner?.first_name + ' ' + owner?.last_name
        : owner?.primary_email ?? 'None';
    return (_jsxs("div", { className: "flex flex-col items-start self-stretch p-p20 rounded-br40 bg-surface-background", children: [_jsxs("div", { className: "flex px-p40 py-p20 items-center gap-g20", children: [_jsx(AffinityIcon, { className: "text-surface-sentiment-highlight-informative" }), _jsx("h4", { className: " typography-label-default-default text-content-default", children: name })] }), _jsxs("div", { className: "flex flex-col p-p50 bg-surface-default w-full rounded-br20 gap-g50", children: [_jsxs("div", { className: "flex", children: [_jsxs("div", { className: "w-[118px] text-content-weak flex items-center", children: [_jsx(ChartOutlineIcon, {}), _jsx("p", { className: "pl-p20 typography-label-default-default", children: "Deal stage" })] }), _jsx("p", { className: "text-content-strong typography-label-default-default", children: statusText })] }), _jsxs("div", { className: "flex", children: [_jsxs("div", { className: "w-[118px] text-content-weak flex  items-center", children: [_jsx(PersonOutlineIcon, {}), _jsx("p", { className: "pl-p20 typography-label-default-default", children: "Owner" })] }), _jsx("p", { className: "text-content-strong typography-label-default-default", children: ownerText })] })] })] }));
};
const CompanyAffinityFieldsDrawer = () => {
    const companyId = useStore((state) => state.affinityFieldsDrawerCompanyId);
    const isAffinityFieldsDrawerOpen = useStore((state) => state.isAffinityFieldsDrawerOpen);
    const editStore = useStore((state) => state.editStoreData);
    const onClose = () => {
        editStore('isAffinityFieldsDrawerOpen', false);
    };
    const { enabled: affinityIntegrationMocked } = useFlags(SPLITS.mockAffinityFrontend);
    const [affinityFieldsData, setAffinityFieldsData] = React.useState();
    useEffect(() => {
        if (companyId < 1)
            return;
        if (affinityIntegrationMocked) {
            setAffinityFieldsData(MockedAffinityFields);
            return;
        }
        const getAffinityFields = async () => {
            setAffinityFieldsData(undefined);
            const response = await getCustomerAffinityFields(companyId);
            setAffinityFieldsData(response);
        };
        getAffinityFields();
    }, [companyId, affinityIntegrationMocked]);
    const affinityListIdToFieldsMap = React.useMemo(() => {
        if (!affinityFieldsData)
            return;
        const map = {};
        const listIds = uniq(compact(affinityFieldsData.map((list) => list.list_id)));
        listIds.forEach((listId) => {
            map[listId] = affinityFieldsData.filter((list) => list.list_id === listId);
        });
        return map;
    }, [affinityFieldsData]);
    const { data, error } = useQuery(GET_AFFINITY_LISTS_QUERY, {
        variables: { id: companyId },
        skip: companyId < 1,
        fetchPolicy: 'cache-only'
    });
    const companyName = data?.getCompanyById?.name ?? '';
    const affinityLists = data?.getCompanyById?.affinityLists ?? [];
    if (!isAffinityFieldsDrawerOpen)
        return null;
    return (_jsxs("div", { className: classNames('m-auto fixed z-100 right-0 top-0 w-px-550 h-full bg-white flex flex-col', 'border border-gray-300 border-solid shadow-md overflow-y-scroll'), children: [_jsxs("div", { className: "flex w-full py-p20 pr-p30 pl-p60 items-center self-stretch border-b border-border border-solid bg-surface-elevated", onClick: onClose, children: [_jsx("h2", { className: " typography-title-extraSmall text-content-core text-xl flex-1", children: companyName }), _jsx(IconButton, { onClick: onClose, icon: CrossIcon, emphasis: "low" })] }), affinityFieldsData === undefined && (_jsx("div", { className: "flex items-center justify-center h-full", children: _jsx(HarmonicLoader, { showText: false }) })), _jsxs("div", { className: "p-p50 flex flex-col items-start gap-g50 self-stretch flex-1", children: [error && (_jsx("div", { children: "An unexpected error occurred. Please try again later." })), affinityIntegrationMocked &&
                        MockedAffinityLists.map((list) => (_jsx(CompanyAffinityListCard, { name: list?.name ?? '', fields: MockedAffinityFields }, list?.affinityId))), !affinityIntegrationMocked && affinityLists.length === 0 && (_jsx("div", { children: "No lists found" })), affinityLists &&
                        affinityFieldsData &&
                        affinityLists.map((list) => (_jsx(CompanyAffinityListCard, { name: list?.name ?? '', fields: get(affinityListIdToFieldsMap, list?.affinityId ?? -1) ?? [] }, list?.affinityId)))] })] }));
};
export default CompanyAffinityFieldsDrawer;
