import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import { Badge } from 'common/components';
import { compact, uniq } from 'lodash';
import { getCompaniesAllEntityActions } from 'queries/getCompaniesAllEntityActions';
import React from 'react';
import analytics, { CustomTrackEvent } from 'utils/analytics';
import SimilarCompaniesContentLoader from './SimilarCompaniesContentLoader';
import SimilarCompany from './SimilarCompany';
export const getSimilarCompanies = gql `
  fragment SimilarCompanies on Company {
    id
    logoUrl
    headcount
    tractionMetrics {
      headcount {
        ago90d {
          percentChange
          value
          change
        }
      }
    }
    name
    description
    funding {
      fundingTotal
      fundingStage
    }
    foundingDate {
      date
    }
    fundingAttributeNullStatus
    tagsV2 {
      displayValue
      type
      entityUrn
    }
    relevanceScore
  }
  query GetSimilarCompaniesV2($urn: String!, $size: Int!) {
    getSimilarCompanies(urn: $urn, size: $size) {
      edges {
        node {
          ...SimilarCompanies
        }
      }
      totalCount
    }
  }
`;
const SimilarCompanies = ({ companyId }) => {
    const { data } = useQuery(getSimilarCompanies, {
        variables: {
            urn: `urn:harmonic:company:${companyId}`,
            size: 10
        },
        fetchPolicy: 'cache-only'
    });
    const handleOnQualificationClick = (similarCompanyId, isPositiveQualification) => {
        analytics.trackCustomEvent({
            event: CustomTrackEvent.SIMILAR_COMPANIES_QUALIFICATION,
            properties: {
                companyId,
                similarCompanyId,
                qualification: isPositiveQualification
            }
        });
    };
    const similarCompanies = uniq(compact(data?.getSimilarCompanies?.edges)).map((edge) => edge.node);
    const similarCompaniesIds = similarCompanies.map((company) => company.id);
    // Prefetch company actions
    useQuery(getCompaniesAllEntityActions, {
        variables: { ids: similarCompaniesIds, extended: false },
        skip: !similarCompaniesIds.length
    });
    return (_jsxs("div", { children: [_jsxs("div", { className: "py-p60 flex items-center gap-g40 border-b border-solid border-border", children: [_jsx("p", { className: "text-content-title typography-title-medium", children: "Similar companies" }), _jsx(Badge, { label: similarCompanies?.length?.toString() ?? '', color: "neutral" })] }), _jsx("div", { className: "py-6 w-full", children: !data ? (_jsx(SimilarCompaniesContentLoader, {})) : (_jsx("div", { "data-testid": "SimilarCompanies", className: "flex flex-col gap-y-4", children: similarCompanies.map((similarCompany) => (_jsx(SimilarCompany, { similarCompany: similarCompany, onQualificationClick: handleOnQualificationClick }, similarCompany.id))) })) })] }));
};
export default SimilarCompanies;
