import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SquareArrowTopRightIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import InvestorCard from 'components/common/CompanyCard/InvestorCard';
import { dayjsExt } from 'config/dayjs';
import Button from 'harmonic-components/Button/Button';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { useState } from 'react';
import { DATE_FORMAT } from 'utils/constants';
import { getFundingStageBadgeColor } from 'utils/funding';
import { truncateMoneyValue } from 'utils/utilities';
const CompanySingleRbr = ({ fundingRound, investorsFundingRoundMap, investorsMap, limit = 4 }) => {
    const [showAllMode, setShowAllMode] = useState(false);
    const fundingRoundType = getFundingTypeDisplayName(fundingRound?.fundingRoundType);
    const fundingRoundDate = fundingRound?.announcementDate
        ? dayjsExt.utc(fundingRound?.announcementDate).format(DATE_FORMAT)
        : 'Unknown';
    const fundingAmount = fundingRound?.fundingAmount > 0
        ? truncateMoneyValue(fundingRound?.fundingAmount)
        : 'Undisclosed';
    const postMoneyValuationTitle = 'Post-Money';
    const postMoneyValuation = fundingRound?.postMoneyValuation > 0
        ? truncateMoneyValue(fundingRound?.postMoneyValuation)
        : undefined;
    const toggleShowAllMode = () => {
        setShowAllMode(!showAllMode);
    };
    const sourceUrl = fundingRound?.sourceUrl;
    // sort function mutates the original array. Hence, used this spread operator approach
    const sortedInvestors = [...(fundingRound?.investors ?? [])].sort((a, b) => {
        if (a?.isLead && !b?.isLead)
            return -1;
        if (!a?.isLead && b?.isLead)
            return 1;
        return 0;
    });
    const investors = showAllMode
        ? sortedInvestors
        : sortedInvestors.slice(0, limit);
    const isMoreThanLimit = sortedInvestors.length > limit;
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex justify-between items-end", children: [_jsxs("div", { className: "flex flex-col gap-g30", children: [_jsxs("div", { className: "flex gap-g50 items-center", children: [_jsx(Badge, { label: fundingRoundType, color: getFundingStageBadgeColor(fundingRoundType), size: "large", intensity: "subtle" }), _jsx("p", { className: "text-content-weak typography-label", children: fundingRoundDate })] }), _jsx("p", { className: "text-content-strong typography-title-medium", children: fundingAmount }), postMoneyValuation && (_jsx(Badge, { label: `${postMoneyValuation} ${postMoneyValuationTitle}`, color: "expressive-7", size: "large", dataTestId: "CompanySingleRbr-PostMoneyValuation-Value" }))] }), sourceUrl && (_jsx("div", { children: _jsx(Button, { onClick: () => window.open(sourceUrl), label: "Read press", type: "secondary", emphasis: "high", trailingIcon: SquareArrowTopRightIcon, dataTestId: "FundingSourceUrl" }) }))] }), _jsx("div", { className: "my-g80 grid sm:grid-cols-2 gap-g40", children: investors.map((investor) => {
                    const investorName = investor?.investorName;
                    const logoUrl = investorName
                        ? investorsMap[investorName]?.logoUrl
                        : '';
                    const isLead = investor?.isLead;
                    const entityUrn = investor?.investorUrn
                        ? investor.investorUrn
                        : investor?.entityUrn;
                    return (_jsx(InvestorCard, { currentRound: fundingRoundType, investorName: investorName, logoUrl: logoUrl, showLeadChip: isLead, entityUrn: entityUrn, dataTestId: "CompanyFinancing-Rbr-Investor", rounds: investorsFundingRoundMap?.[investor?.entityUrn] ?? [] }, investorName));
                }) }), isMoreThanLimit && (_jsx("div", { className: "flex justify-center", children: _jsx(Button, { label: showAllMode ? 'Load less' : 'Load more', type: "secondary", emphasis: "low", onClick: toggleShowAllMode, dataTestId: "CompanySingleRbr-Toggle-Btn" }) }))] }, fundingRoundType));
};
export default CompanySingleRbr;
