import { INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY, INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY } from 'interfaces/SearchModel/Search';
import { useAppState } from './useAppState';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
const useSearchQuery = () => {
    const { location: dashboardLocation } = useDashboardLocation();
    const isPeopleSearchPage = dashboardLocation === DashboardLocation.PEOPLE_SEARCH;
    const initialSearchFilterGroupQuery = isPeopleSearchPage
        ? INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY
        : INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY;
    const searchModel = useAppState((state) => state.dashboard.searchModel);
    return {
        initialSearchFilterGroupQuery,
        searchModel
    };
};
export default useSearchQuery;
