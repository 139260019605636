import { gql } from '@apollo/client';
const getPersonContactInformation = gql `
  query GetPersonContactInformation($id: Int!) {
    getPersonById(id: $id) {
      id
      fullName
      contact {
        emails
      }
      emailEnrichmentStatus {
        enrichedAt
      }
    }
  }
`;
export default getPersonContactInformation;
