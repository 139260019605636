import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ConnectionIntegrationType } from '__generated__/graphql';
import HarmonicLogo from 'assets/harmonic-icons/harmonic-gradient-logo.svg';
import SyncingStateIcon from 'assets/harmonic-icons/syncing-state.svg';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';
import { useMemo } from 'react';
import ConnectGoogleCard from './Network/ConnectGoogleCard';
import ConnectLinkedInCard from './Network/ConnectLinkedInCard';
import Shimmer from './Shimmer';
export const NoConnectionsPageContent = ({ isLinkedinIntegrated, isGmailIntegrated, isLoading, onGoogleSuccess }) => {
    // If the list is empty but at least one integration is enabled, we show a syncing state
    const isSyncing = isLinkedinIntegrated || isGmailIntegrated;
    return (_jsx("div", { className: "w-full h-full flex justify-center", children: _jsxs("div", { className: "flex flex-col items-center gap-g60 -mt-24", children: [_jsx("img", { src: isSyncing ? SyncingStateIcon : HarmonicLogo, style: { width: 60, height: 60 } }), _jsxs("div", { className: "flex flex-col items-center gap-g40 self-stretch mb-2", children: [_jsx("p", { className: "text-content-strong text-center typography-title-extraSmall", children: isSyncing
                                ? 'Sync in progress...'
                                : 'Integrate your network in a few clicks' }), _jsx("p", { className: "text-content-weak typography-paragraph-default-default", children: isSyncing ? (_jsxs("div", { children: ["This may take up to 24 hours.", _jsx("br", {}), isLinkedinIntegrated && !isGmailIntegrated
                                        ? 'Complete your setup by connecting your email and calendar.'
                                        : !isLinkedinIntegrated && isGmailIntegrated
                                            ? 'Complete your setup by connecting your LinkedIn.'
                                            : ''] })) : (_jsx("p", { children: "Map everyone you've emailed, met, or connected with on LinkedIn onto the complete startup database." })) })] }), isLoading ? (_jsxs("div", { className: "flex flex-col gap-g60", children: [_jsx(Shimmer, { className: "w-[584px] h-[139px] rounded-md" }), _jsx(Shimmer, { className: "w-[584px] h-[139px] rounded-md" })] })) : (_jsxs(_Fragment, { children: [!isLinkedinIntegrated && _jsx(ConnectLinkedInCard, {}), !isGmailIntegrated && (_jsx(ConnectGoogleCard, { onSuccess: onGoogleSuccess }))] }))] }) }));
};
const NoConnectionsPage = () => {
    const { data: userData, loading: integrationStateLoading, refetch } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'cache-first'
    });
    const integrationStatus = userData?.getCurrentUser?.connectionsIntegrationStatus;
    const isLinkedinIntegrated = useMemo(() => integrationStatus?.find((integ) => integ?.type === ConnectionIntegrationType.LINKEDIN)?.isEnabled ?? false, [integrationStatus]);
    const isGmailIntegrated = useMemo(() => integrationStatus?.find((integ) => integ?.type === ConnectionIntegrationType.GOOGLE)?.isEnabled ?? false, [integrationStatus]);
    return (_jsx(NoConnectionsPageContent, { isLinkedinIntegrated: isLinkedinIntegrated, isGmailIntegrated: isGmailIntegrated, isLoading: integrationStateLoading, onGoogleSuccess: refetch }));
};
export default NoConnectionsPage;
