import { TeamRole } from '__generated__/graphql';
import { useAuthState } from './useAppState';
export var Permission;
(function (Permission) {
    Permission["ExportCsv"] = "EXPORT_CSV";
    Permission["PeopleSearch"] = "PEOPLE_SEARCH";
    Permission["PeopleList"] = "PEOPLE_LIST";
    Permission["PersonProfile"] = "PERSON_PROFILE";
    // Lite user and Member user terms may change.
    Permission["CanInviteStandardUser"] = "CAN_INVITE_STANDARD_USER";
    Permission["CanInviteEnterpriseUser"] = "CAN_INVITE_ENTERPRISE_USER";
    Permission["PauseUnpauseUser"] = "CAN_PAUSE_UNPAUSE_USER";
    Permission["EditRole"] = "EDIT_ROLE";
    Permission["ManageApiKey"] = "MANAGE_API_KEY";
    Permission["CreateApiKey"] = "CREATE_API_KEY";
    Permission["DeleteApiKey"] = "DELETE_API_KEY";
})(Permission || (Permission = {}));
const UserPermissions = {
    //Enterprise Admin
    [TeamRole.ADMIN]: [
        Permission.ExportCsv,
        Permission.PeopleSearch,
        Permission.PeopleList,
        Permission.PersonProfile,
        Permission.CanInviteStandardUser,
        Permission.CanInviteEnterpriseUser,
        Permission.PauseUnpauseUser,
        Permission.EditRole,
        Permission.CreateApiKey,
        Permission.DeleteApiKey,
        Permission.ManageApiKey
    ],
    // Limited Admin(Standard User Admin)
    [TeamRole.LIMITED_ADMIN]: [
        Permission.CanInviteStandardUser,
        Permission.PauseUnpauseUser,
        Permission.PeopleSearch,
        Permission.PeopleList,
        Permission.PersonProfile
    ],
    // Enterprise User
    [TeamRole.MEMBER]: [
        Permission.ExportCsv,
        Permission.PeopleSearch,
        Permission.PeopleList,
        Permission.PersonProfile,
        Permission.CanInviteStandardUser
    ],
    // Standard User
    [TeamRole.LIMITED]: [
        Permission.CanInviteStandardUser,
        Permission.PeopleSearch,
        Permission.PeopleList,
        Permission.PersonProfile
    ]
};
const usePermissions = () => {
    const auth = useAuthState();
    const userHasPermission = (permission) => {
        if (!auth.isAuthenticated)
            return false;
        if (auth?.role === TeamRole.ADMIN)
            return true;
        return UserPermissions?.[auth.role]?.includes(permission);
    };
    return {
        userHasPermission
    };
};
export default usePermissions;
