import { gql } from '@apollo/client';
export const GET_ALL_TEAM_MEMBERS = gql `
  query GetAllTeamMembersByCustomer($urn: String!) {
    getAllTeamMembersByCustomer(urn: $urn) {
      role
      status
      user {
        email
        entityUrn
        name
      }
    }
  }
`;
export const GET_ALL_TEAM_MEMBERS_WITH_CONNECTION_STATUS = gql `
  query GetAllTeamMembersByCustomerWithConnectionStatus($urn: String!) {
    getAllTeamMembersByCustomer(urn: $urn) {
      role
      status
      user {
        email
        entityUrn
        name
        connectionsIntegrationStatus {
          type
          isEnabled
        }
      }
    }
  }
`;
