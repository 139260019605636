import PersonColumnsConfig from 'interfaces/PersonColumnsConfig';
import { SPLITS } from 'utils/constants';
const DefaultPeopleSearchColumns = [
    {
        id: PersonColumnsConfig.ID.value,
        name: PersonColumnsConfig.ID.label,
        isVisibleOnTable: false
    },
    {
        id: PersonColumnsConfig.FULL_NAME.value,
        name: PersonColumnsConfig.FULL_NAME.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.FIRST_NAME.value,
        name: PersonColumnsConfig.FIRST_NAME.label,
        isVisibleOnTable: false
    },
    {
        id: PersonColumnsConfig.LAST_NAME.value,
        name: PersonColumnsConfig.LAST_NAME.label,
        isVisibleOnTable: false
    },
    {
        id: PersonColumnsConfig.CURRENT_COMPANY.value,
        name: PersonColumnsConfig.CURRENT_COMPANY.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.PERSON_HIGHLIGHTS.value,
        name: PersonColumnsConfig.PERSON_HIGHLIGHTS.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.CURRENT_TITLE.value,
        name: PersonColumnsConfig.CURRENT_TITLE.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.CURRENT_DEPARTMENT.value,
        name: PersonColumnsConfig.CURRENT_DEPARTMENT.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.CURRENT_LOCATION.value,
        name: PersonColumnsConfig.CURRENT_LOCATION.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.LINKEDIN_HEADLINE.value,
        name: PersonColumnsConfig.LINKEDIN_HEADLINE.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.CURRENT_COMPANY_TAGS.value,
        name: PersonColumnsConfig.CURRENT_COMPANY_TAGS.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.PREVIOUS_COMPANY.value,
        name: PersonColumnsConfig.PREVIOUS_COMPANY.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.PREVIOUS_COMPANY_TAGS.value,
        name: PersonColumnsConfig.PREVIOUS_COMPANY_TAGS.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.EDUCATION.value,
        name: PersonColumnsConfig.EDUCATION.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.PREVIOUS_COMPANIES.value,
        name: PersonColumnsConfig.PREVIOUS_COMPANIES.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.LINKEDIN.value,
        name: PersonColumnsConfig.LINKEDIN.label,
        isVisibleOnTable: true
    },
    {
        name: PersonColumnsConfig.EMAILS.label,
        id: PersonColumnsConfig.EMAILS.value,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.TEAM_CONNECTIONS.value,
        name: PersonColumnsConfig.TEAM_CONNECTIONS.label,
        isVisibleOnTable: true
    },
    {
        id: PersonColumnsConfig.LAST_MEETING_DATE.value,
        name: PersonColumnsConfig.LAST_MEETING_DATE.label,
        isVisibleOnTable: false,
        splitFlagToEnable: SPLITS.showCorrespondencesInSearch
    },
    {
        id: PersonColumnsConfig.LAST_MEETING_WITH.value,
        name: PersonColumnsConfig.LAST_MEETING_WITH.label,
        isVisibleOnTable: false,
        splitFlagToEnable: SPLITS.showCorrespondencesInSearch
    },
    {
        id: PersonColumnsConfig.LAST_EMAIL_AT.value,
        name: PersonColumnsConfig.LAST_EMAIL_AT.label,
        isVisibleOnTable: false,
        splitFlagToEnable: SPLITS.showCorrespondencesInSearch
    },
    {
        id: PersonColumnsConfig.LAST_CONTACTED_BY.value,
        name: PersonColumnsConfig.LAST_CONTACTED_BY.label,
        isVisibleOnTable: false,
        splitFlagToEnable: SPLITS.showCorrespondencesInSearch
    }
]
    .map((item, idx) => Object.assign({}, item, { order: idx }))
    .filter(({ isVisibleOnTable }) => isVisibleOnTable);
export default DefaultPeopleSearchColumns;
