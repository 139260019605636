import { jsx as _jsx } from "react/jsx-runtime";
import { get, uniqBy } from 'lodash';
import PersonAttribute from '../../../../../harmonic-components/PersonAttribute/PersonAttribute';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { useAppState } from '../../../../../hooks/useAppState';
import { DASHBOARD_ROUTE } from '../../../../../utils/constants';
import { CompanyNavbarOptions } from '../../../CompanyV2/CompanyHeader/CompanyHeader';
import { NoDataSubRenderer } from '../CellRenderers';
export const UserConnections = (props) => {
    const company = get(props, 'node.data.company');
    const person = get(props, 'node.data.person');
    const field = props.colDef?.field ?? '';
    const currentUserEmail = useAppState((state) => state.auth.user?.email ?? '');
    const connections = (props.value ?? []).map((connection) => ({
        ...connection,
        user: {
            ...connection.user,
            name: 
            // Show name as "You" if the connection is the currently logged in user
            connection.user?.email === currentUserEmail
                ? 'You'
                : connection.user?.name
        }
    }));
    const items = uniqBy(connections, (connection) => connection.user?.email).map((connection) => ({
        text: connection.user?.name ?? connection.user?.email ?? '',
        href: field.includes('company.')
            ? `${DASHBOARD_ROUTE}/company/${company?.id}?selectedTab=${CompanyNavbarOptions.CONNECTIONS}`
            : `${DASHBOARD_ROUTE}/person/${person?.id}`
    }));
    if (items.length === 0) {
        return _jsx(NoDataSubRenderer, { type: "connections" });
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: items.map((item, index) => (_jsx("div", { children: _jsx(PersonAttribute, { src: "", name: item.text, href: item.href }) }, index))) }) }));
};
