import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Badge } from 'common/components';
import { isNil } from 'lodash';
export var TabType;
(function (TabType) {
    TabType["default"] = "default";
    TabType["inverted"] = "inverted";
})(TabType || (TabType = {}));
const Tab = (props) => {
    const isIconTab = props.variation === 'icon';
    return (_jsx("button", { "data-testid": `Tab-${props.variation}-${props.label}`, "aria-selected": props.selected, "aria-disabled": props.disabled, className: classNames(
        // shared styles
        `flex cursor-pointer items-center justify-center border-solid border-transparent`, 
        // focus styles
        'focus-visible:shadow-int-focus-inside', props.type === 'default' &&
            classNames('text-content-weak', 'aria-disabled:text-content-muted', 'aria-selected:text-content-strong', 'hover:text-content-strong', props.selected
                ? 'hover:aria-selected:border-int-outline-secondary-selected-hover'
                : 'hover:border-int-overlay-secondary-hover', props.selected
                ? 'active:border-int-outline-secondary-selected-pressed'
                : 'active:border-int-overlay-secondary-pressed'), props.type === 'inverted' &&
            classNames('bg-surface-inverted-elevated', 'text-border-inverted-weak', 'aria-disabled:text-content-inverted-muted', 'aria-selected:text-content-inverted-strong', 'hover:text-content-inverted-strong', props.selected
                ? 'hover:aria-selected:border-int-outline-secondary-selected-hover'
                : 'hover:border-int-overlay-inverted-hover', props.selected
                ? 'active:border-int-outline-secondary-selected-pressed'
                : 'active:border-int-overlay-inverted-pressed'), isIconTab &&
            classNames('hover:bg-int-overlay-secondary-hover hover:text-content-strong', 'active:bg-int-overlay-secondary-pressed'), 
        // disabled styles
        `aria-disabled:pointer-events-none`, 
        // selected styles
        `aria-selected:border-int-outline-secondary-selected-enabled`, 
        // tab specific styles
        isIconTab
            ? 'flex-1 max-w-[160px] p-p20 border-t-2'
            : `py-p50 px-p60 justify-center border-b-2 typography-label-default-strong`), onClick: () => {
            props.onClick(props.value);
        }, children: props.variation === 'label' ? (_jsxs("div", { className: classNames('flex items-center'), children: [_jsx("p", { className: classNames(!isNil(props.count) && 'pr-p50', props.inBeta && 'pr-p20'), children: props.label }), props.inBeta && (_jsx(Badge, { label: "Beta", size: "small", color: "neutral", intensity: "highlight", borderRadius: "square" })), props.count !== undefined && (_jsx("div", { "data-testid": `Tab-${props.variation}-${props.label}-count`, "aria-selected": props.selected, className: classNames(`flex typography-label-small-default text-center min-w-4 min-h-4 px-p20 justify-center rounded-br20`, props.type === 'default' &&
                        classNames('text-content-sentiment-neutral', 'bg-surface-sentiment-neutral', 'aria-selected:text-content-onSentiment-informative', 'aria-selected:bg-surface-sentiment-informative'), props.type === 'inverted' &&
                        classNames('text-content-inverted-default', 'bg-surface-inverted-raised', 'aria-selected:text-content-inverted-title', 'aria-selected:bg-surface-sentiment-highlight-informative')), children: props.count }))] })) : (_jsx(props.icon, {})) }, `${props.label}-${props.variation}-${props.type}`));
};
const Tabs = ({ type = TabType.default, ...props }) => {
    return (_jsx("div", { className: classNames('flex flex-row basis-full border-solid border-b-[1px]', type === TabType.inverted &&
            'bg-surface-inverted-elevated border-border-inverted-weak', type === TabType.default && 'border-border-weak'), children: props.tabs.map((tabProps, i) => (_jsx(Tab, { ...tabProps, selected: props.selectedTabValue === tabProps.value, onClick: props.onTabChange, type: type }, i))) }));
};
export default Tabs;
