import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CalendarDaysIcon from 'assets/harmonic-icons/calendar-days';
import { formatFoundingDate } from 'components/common/FoundingDate/utils';
import { get } from 'lodash';
import { dayjsExt } from '../../../../../config/dayjs';
import { NoDataSubRenderer } from '../CellRenderers';
export const DateCell = (props) => {
    const date = get(props, 'value');
    if (date &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props.colDef?.searchModelIdentifier === 'company_founding_date') {
        return (_jsxs("div", { className: "flex items-center gap-g20", children: [_jsx(CalendarDaysIcon, { className: "w-4 h-4 text-content-weak" }), _jsx("span", { className: "typography-label-default-number text-content-default", children: formatFoundingDate(date) })] }));
    }
    return date ? (_jsxs("div", { className: "flex items-center gap-g20", children: [_jsx(CalendarDaysIcon, { className: "w-4 h-4 text-content-weak" }), _jsx("span", { className: "typography-label-default-number text-content-default", children: dayjsExt.utc(date).format('MM/DD/YYYY') })] })) : (_jsx(NoDataSubRenderer, {}));
};
