import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import TextInput from 'harmonic-components/TextInput/TextInput';
import { toString } from 'lodash';
const SelectorWithValue = ({ selector, hideValueInput = false, value, onValueChange, fullWidth = false }) => {
    return (_jsxs("div", { className: "flex flex-row items-center gap-2", children: [!hideValueInput && (_jsx("div", { className: "w-16", children: _jsx(TextInput, { dataTestId: "selector-with-value-input", value: toString(value) ?? '', placeholder: "0", onChange: onValueChange }) })), _jsx("div", { className: classNames(!fullWidth && 'min-w-36', fullWidth && 'w-full'), children: _jsx(Select, { multiple: false, getLabelFromValue: (value) => {
                        return (selector.options.find((option) => option.value === value)
                            ?.label ?? '');
                    }, selected: selector.value, children: selector.options.map((listItem) => (_jsx(SelectListItem, { label: listItem.label, value: listItem.value, onClick: () => selector.onChange(listItem.value), variant: ListVariant.default }, listItem.value))) }) })] }));
};
export default SelectorWithValue;
