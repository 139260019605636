import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CopyIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { Tooltip } from 'common/components';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useState } from 'react';
const Email = ({ email, dataTestId, collapsedInward, className }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const onCopyClick = () => {
        window.navigator.clipboard.writeText(email);
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
    };
    // If the email item is in a stack, ie on a grid cell, it’s width doesn’t change on hover, and the text get’s truncated to allow for the copy action.
    if (collapsedInward) {
        return (_jsxs("div", { className: classNames('pl-p30 pr-p10 inline-flex items-center gap-x-g10 h-[24px]', 'hover:bg-int-overlay-secondary-hover rounded-br30 relative', className), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsx("span", { className: classNames('text-content-default typography-label border-b border-solid border-border line-clamp-1', {
                        'w-[calc(100%-24px)]': isHovered
                    }), children: email }), isHovered && (_jsx("div", { className: "absolute right-[2px] top-[2px]", children: _jsx(Tooltip, { title: "Copied", open: showTooltip, children: _jsx(IconButton, { onClick: onCopyClick, icon: CopyIcon, type: "secondary", emphasis: "low", size: "tiny" }) }) }))] }));
    }
    // If the email item is singularly displayed, or in a vertical list, the action on hover appears to the right of the email, since there’s enough space to do so without moving the other items on the list
    return (_jsxs("div", { "data-testid": dataTestId, className: classNames('group pl-p30 pr-p10 inline-flex items-center gap-g10 h-[24px]', 'hover:bg-int-overlay-secondary-hover rounded-br30', className), onClick: onCopyClick, children: [_jsx("span", { className: "text-content-default typography-label border-b border-solid border-border line-clamp-1", children: email }), _jsx("div", { className: "invisible group-hover:visible", children: _jsx(Tooltip, { title: "Copied", open: showTooltip, children: _jsx(IconButton, { onClick: onCopyClick, icon: CopyIcon, type: "secondary", emphasis: "low", size: "tiny" }) }) })] }));
};
export default Email;
