import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import harmonicLogo from 'assets/harmonic-icons/harmonic-gradient-logo.svg';
import Button from 'harmonic-components/Button/Button';
import * as React from 'react';
import { NotFoundMode, SITE_STATUS_URL, SUPPORT_EMAIL_ADDRESS } from 'utils/constants';
import { useAuthState } from '../../hooks/useAppState';
import NoConnectionsPage from './NoConnectionsPage';
const SupportEmail = () => (_jsx("a", { className: "underline", href: `mailto:${SUPPORT_EMAIL_ADDRESS}`, children: SUPPORT_EMAIL_ADDRESS }));
const StatusUrl = () => (_jsx("a", { href: SITE_STATUS_URL, className: "underline", children: SITE_STATUS_URL }));
const NotFound = ({ mode = NotFoundMode.DEFAULT }) => {
    const authState = useAuthState();
    let message, title;
    if (mode === NotFoundMode.SAVED_SEARCH_FORBIDDEN) {
        title = 'Oops... looks like you don’t have permission!';
        // prettier-ignore
        message = (_jsxs(_Fragment, { children: ["This saved search is not accessible to ", authState.user?.email || 'your user', ". Please reach out to ", _jsx(SupportEmail, {}), " to request access."] }));
    }
    if (mode === NotFoundMode.NO_RESULTS_SAVED_SEARCH) {
        title = 'No results for your query';
        message = _jsx(_Fragment, { children: "Please refine your filters and try again." });
    }
    if (mode === NotFoundMode.NO_RESULTS_WATCHLIST) {
        title = 'No companies are in this list';
        message = _jsx(_Fragment, { children: "Add companies from search results or a company page." });
    }
    if (mode === NotFoundMode.NO_RESULTS_PEOPLE_WATCHLIST) {
        title = 'No people are in this list';
        message = _jsx(_Fragment, { children: "Add people from search results or a person page." });
    }
    if (mode === NotFoundMode.NO_RESULTS_CONNECTIONS) {
        return _jsx(NoConnectionsPage, {});
    }
    if (mode === NotFoundMode.NO_CUSTOMER_INVESTOR_FOUND) {
        title = 'No linked investor found';
        message = _jsx(_Fragment, { children: "Reach out to support to access investor data." });
    }
    if (mode === NotFoundMode.WATCHLIST_FORBIDDEN) {
        title = 'Oops... looks like you don’t have permission!';
        message = (_jsxs(_Fragment, { children: ["This watchlist is not accessible to ", _jsx("br", {}), authState.user?.email || 'your user', ". Please reach out to ", _jsx("br", {}), _jsx(SupportEmail, {}), " to request access."] }));
    }
    if (mode === NotFoundMode.SAVED_SEARCH_ERROR) {
        title = 'Oops! An unexpected error occurred.';
        message = (_jsxs(_Fragment, { children: ["Please try again in a few minutes. If this problem persists, ", _jsx("br", {}), ' ', "reach out to support@harmonic.ai"] }));
    }
    if (mode === NotFoundMode.WATCHLIST_NOT_EXIST) {
        title = 'The page you are viewing is no longer available';
    }
    if (mode === NotFoundMode.DEFAULT) {
        // prettier-ignore
        message = (_jsxs(_Fragment, { children: ["Please check if there is a typo in the URL, or reach out to ", _jsx(SupportEmail, {}), " if you expected something to be here."] }));
        // prettier-ignore
        title = (_jsxs(_Fragment, { children: ["We can't find what you're looking for! Please check out ", _jsx(StatusUrl, {}), " for potential known outage"] }));
    }
    const showContactSupport = mode === NotFoundMode.SAVED_SEARCH_ERROR ||
        mode === NotFoundMode.WATCHLIST_FORBIDDEN;
    const showHomeButton = mode === NotFoundMode.WATCHLIST_NOT_EXIST;
    return (_jsx("div", { className: "w-full h-full flex justify-center items-center", children: _jsxs("div", { className: "flex flex-col items-center gap-g60", children: [_jsx("img", { src: harmonicLogo, style: { width: 60, height: 60 } }), _jsxs("div", { className: "flex flex-col items-center gap-g40 self-stretch", children: [_jsx("p", { className: "text-content-strong text-center typography-title-extraSmall", children: title }), _jsx("p", { className: "text-content-weak typography-paragraph-default-default", children: message })] }), _jsxs("div", { className: "flex gap-g30", children: [showContactSupport && (_jsx("a", { href: `mailto:${SUPPORT_EMAIL_ADDRESS}`, children: _jsx(Button, { type: "secondary", emphasis: "high", label: "Contact support", dataTestId: "ContactSupportBtn", onClick: () => null }) })), showHomeButton && (_jsx(Button, { type: "secondary", emphasis: "medium", label: "Home", dataTestId: "HomeBtn", onClick: () => (window.location.href = '/') }))] })] }) }));
};
export default NotFound;
