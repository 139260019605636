import { gql } from '@apollo/client';
export const ADD_COMPANIES_TO_WATCHLIST = gql `
  mutation AddCompaniesToWatchlist($id: String!, $companies: [String]!) {
    addCompaniesToWatchlistWithIds(id: $id, companies: $companies) {
      id
      entityUrn
      companyIds
      name
      sharedWithTeam
      userWatchlistType
    }
  }
`;
export const ADD_COMPANIES_TO_WATCHLIST_MOCKED_RESPONSE = {
    data: {
        addCompaniesToWatchlistWithIds: {
            id: 'ab1d04d3-2d3b-4d37-83ae-c0175e3420c9',
            entityUrn: 'urn:harmonic:company_watchlist:ab1d04d3-2d3b-4d37-83ae-c0175e3420c9',
            companyIds: ['1'],
            name: 'New list',
            sharedWithTeam: false,
            userWatchlistType: 'USER_MANAGED_WATCHLIST',
            __typename: 'CompanyWatchlist'
        }
    }
};
