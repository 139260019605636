import { gql } from '@apollo/client';
export const ADD_PEOPLE_TO_WATCHLIST_WITH_CANONICALS = gql `
  mutation AddPeopleToWatchlistWithCanonicals(
    $urn: PeopleWatchlistUrn!
    $fileName: String
    $flatfileBatchId: String
    $canonicals: [PersonCanonicalsInput!]!
  ) {
    addPeopleToWatchlistWithCanonicals(
      urn: $urn
      fileName: $fileName
      flatfileBatchId: $flatfileBatchId
      canonicals: $canonicals
    ) {
      userPeopleImportUrn
      totalPeopleCount
      peopleFoundCount
      peopleNotFoundCount
      peopleInvalidCount
    }
  }
`;
