import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { compact } from 'lodash';
import { getCompaniesNames } from 'queries/getCompaniesNames';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCompanyLineageRelationshipsByChild, getCompanyLineageRelationshipsByParent } from 'utils/hasuraApi';
/**
 * !!!!!
 * This component is a PLACEHOLDER - meant only to be
 * exposed to a small set of users behind a feature flag
 * and only to be used for internal QA
 * !!!!!
 */
const CompanyLineage = (props) => {
    const { companyId } = props;
    const [companyLineageByParentData, setCompanyLineageByParentData] = useState([]);
    const [companyLineageByChildData, setCompanyLineageByChildData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchCompanyLineage = async () => {
            const companyLineageByParentData = await getCompanyLineageRelationshipsByParent(companyId);
            setCompanyLineageByParentData(companyLineageByParentData);
            const companyLineageByChildData = await getCompanyLineageRelationshipsByChild(companyId);
            setCompanyLineageByChildData(companyLineageByChildData);
            setLoading(false);
        };
        fetchCompanyLineage();
    }, [companyId]);
    const { data: companyNameData, loading: companyNameDataLoading } = useQuery(getCompaniesNames, {
        variables: {
            ids: compact(companyLineageByParentData
                .map((relationship) => relationship.child_company_id)
                .concat(companyLineageByChildData.map((relationship) => relationship.parent_company_id)))
        },
        skip: loading,
        fetchPolicy: 'network-only'
    });
    const subsidiaryOfRelationships = companyLineageByChildData.filter((relationship) => relationship.relationship_type === 'Subsidiary');
    const acquisitionByRelationships = companyLineageByChildData.filter((relationship) => relationship.relationship_type === 'Acquisition');
    const subsidiaryRelationships = companyLineageByParentData.filter((relationship) => relationship.relationship_type === 'Subsidiary');
    const acquisitionRelationships = companyLineageByParentData.filter((relationship) => relationship.relationship_type === 'Acquisition');
    if (loading || companyNameDataLoading) {
        return _jsx("div", { children: "Loading..." });
    }
    const Subsidiaries = (props) => {
        const { relationships, showChildOrParent } = props;
        return (_jsxs("table", { className: "gap-4", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Company" }), _jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Company ID" }), _jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Is Surfacable" })] }) }), _jsx("tbody", { children: relationships.map((relationship) => {
                        const companyId = showChildOrParent == 'child'
                            ? relationship.child_company_id
                            : relationship.parent_company_id;
                        return (_jsxs("tr", { children: [_jsx("td", { className: "border px-4 py-2", children: _jsx(Link, { to: `/dashboard/company/${companyId}`, className: "text-blue-500 underline", children: companyNameData?.getCompaniesByIds?.find((company) => company?.id === companyId)?.name }) }), _jsx("td", { className: "border px-4 py-2", children: companyId }), _jsx("td", { className: "border px-4 py-2", children: relationship.is_surfacable ? 'Yes' : 'No' })] }, relationship.id));
                    }) })] }));
    };
    const Acquisitions = (props) => {
        const { relationships, showChildOrParent } = props;
        return (_jsxs("table", { className: "gap-4", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Company" }), _jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Company ID" }), _jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Acquisition Date" }), _jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Acquisition Amount" }), _jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Is Merged After Acquisition" }), _jsx("th", { className: "px-4 py-2 text-left font-bold", children: "Is Surfacable" })] }) }), _jsx("tbody", { children: relationships.map((relationship) => {
                        const companyId = showChildOrParent == 'child'
                            ? relationship.child_company_id
                            : relationship.parent_company_id;
                        return (_jsxs("tr", { children: [_jsx("td", { className: "border px-4 py-2", children: _jsx(Link, { to: `/dashboard/company/${companyId}`, className: "text-blue-500 underline", children: companyNameData?.getCompaniesByIds?.find((company) => company?.id === companyId)?.name }) }), _jsx("td", { className: "border px-4 py-2", children: companyId }), _jsx("td", { className: "border px-4 py-2", children: relationship.acquisition_date }), _jsx("td", { className: "border px-4 py-2", children: relationship.acquisition_amount }), _jsx("td", { className: "border px-4 py-2", children: relationship.is_merged_after_acquisition ? 'Yes' : 'No' }), _jsx("td", { className: "border px-4 py-2", children: relationship.is_surfacable ? 'Yes' : 'No' })] }, relationship.id));
                    }) })] }));
    };
    return (_jsxs("div", { className: "flex flex-col gap-8", children: [_jsxs("div", { children: [_jsx("h2", { className: "mb-4 text-xl font-bold", children: "Subsidiary of" }), subsidiaryOfRelationships.length > 0 ? (_jsx(Subsidiaries, { relationships: subsidiaryOfRelationships, showChildOrParent: "parent" })) : (_jsx("div", { className: "mx-4", children: "No parents to show" }))] }), _jsxs("div", { children: [_jsx("h2", { className: "mb-4 text-xl font-bold", children: "Acquired by" }), acquisitionByRelationships.length > 0 ? (_jsx(Acquisitions, { relationships: acquisitionByRelationships, showChildOrParent: "parent" })) : (_jsx("div", { className: "mx-4", children: "No acquirers to show" }))] }), _jsxs("div", { children: [_jsx("h2", { className: "mb-4 text-xl font-bold", children: "Subsidiaries" }), subsidiaryRelationships.length > 0 ? (_jsx(Subsidiaries, { relationships: subsidiaryRelationships, showChildOrParent: "child" })) : (_jsx("div", { className: "mx-4", children: "No subsidiaries to show" }))] }), _jsxs("div", { children: [_jsx("h2", { className: "mb-4 text-xl font-bold", children: "Acquisitions" }), acquisitionRelationships.length > 0 ? (_jsx(Acquisitions, { relationships: acquisitionRelationships, showChildOrParent: "child" })) : (_jsx("div", { className: "mx-4", children: "No acquisitions to show" }))] })] }));
};
export default CompanyLineage;
