import { dayjsExt } from 'config/dayjs';
export const formatFoundingDate = (date) => {
    const foundingDateObj = dayjsExt.utc(date);
    if (!foundingDateObj.isValid()) {
        throw new Error('Invalid founding date');
    }
    // we consider the date to be year granularity if the month is January and the date is 1
    // in this case we only display the year
    const dateIsYearGranularity = foundingDateObj.get('month') === 0 && foundingDateObj.get('date') === 1;
    const foundingDateFormatted = foundingDateObj.format(dateIsYearGranularity ? 'YYYY' : 'MMM YYYY');
    return foundingDateFormatted;
};
