import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useFlags from 'hooks/useFlags';
import React from 'react';
import { SPLITS } from 'utils/constants';
import CompanyContactEmailsPhone from './CompanyContactEmailsPhone';
import CompanyInNetwork from './CompanyInNetwork';
const CompanyContact = ({ companyId }) => {
    const { enabled: showUserConnectionsOnCompanyPage } = useFlags(SPLITS.showUserConnectionsOnCompanyPage);
    return (_jsxs("div", { "data-testid": "CompanyContact", className: "flex flex-col justify-start items-start gap-g110", children: [showUserConnectionsOnCompanyPage && (_jsx(CompanyInNetwork, { companyId: companyId })), _jsx(CompanyContactEmailsPhone, { companyId: companyId })] }));
};
export default CompanyContact;
