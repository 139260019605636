export var PersonExperienceDataTypes;
(function (PersonExperienceDataTypes) {
    PersonExperienceDataTypes["NAME"] = "name";
    PersonExperienceDataTypes["TITLE"] = "title";
    PersonExperienceDataTypes["DEPARTMENT"] = "department";
    PersonExperienceDataTypes["TAGS"] = "tags";
})(PersonExperienceDataTypes || (PersonExperienceDataTypes = {}));
export var PersonExperienceDataTimeTypes;
(function (PersonExperienceDataTimeTypes) {
    PersonExperienceDataTimeTypes["CURRENT"] = "current";
    PersonExperienceDataTimeTypes["PREVIOUS"] = "previous";
    PersonExperienceDataTimeTypes["ALL_PREVIOUS"] = "all_previous";
})(PersonExperienceDataTimeTypes || (PersonExperienceDataTimeTypes = {}));
