import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { addUserToCompanyCapWaitlist, getUserCompanyCapWaitlistEntry } from 'utils/hasuraApi';
import { makeNamePosessive } from 'utils/utilities';
import ComingSoonModal from './ComingSoonModal';
const CapTableWaitlistForm = ({ companyId, companyName }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasJoined, setHasJoined] = useState(false);
    const onSubmit = async () => {
        setIsLoading(true);
        const response = await addUserToCompanyCapWaitlist(companyId);
        const returning = response.insert_company_cap_table_waitlist_entries &&
            response.insert_company_cap_table_waitlist_entries.returning;
        if (returning.length > 0) {
            setHasJoined(true);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            const response = await getUserCompanyCapWaitlistEntry(companyId);
            if (response.company_cap_table_waitlist_entries &&
                response.company_cap_table_waitlist_entries.length > 0) {
                setHasJoined(true);
            }
        };
        fetchData();
    }, []);
    const comingSoonText = () => {
        if (companyName) {
            return `Get alerted once ${makeNamePosessive(companyName)} cap table is available`;
        }
        else {
            return '';
        }
    };
    const joinedText = () => {
        if (companyName) {
            return `You’ll get an alert once  ${makeNamePosessive(companyName)} cap table details are on Harmonic`;
        }
        else {
            return '';
        }
    };
    return (_jsx(ComingSoonModal, { comingSoonText: comingSoonText(), joinedText: joinedText(), submitAction: onSubmit, hasJoined: hasJoined, isLoading: isLoading }));
};
export default CapTableWaitlistForm;
