import { jsx as _jsx } from "react/jsx-runtime";
import { Chart as ChartJS, Line } from 'react-chartjs-2';
import { chartAreaRoundedBorderPlugin } from 'utils/chart';
import { numberSuffixFormatter } from 'utils/utilities';
ChartJS.register(chartAreaRoundedBorderPlugin);
const StackedLineChart = ({ yAxisPrefix = '', datasets }) => {
    const lineChartData = {
        datasets: datasets.map((dataset) => ({
            label: dataset.label,
            fill: true,
            tension: 0.1,
            borderWidth: 0.75,
            backgroundColor: dataset.backgroundColor,
            borderColor: dataset.borderColor,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'round',
            pointBorderColor: dataset.borderColor,
            pointBackgroundColor: dataset.backgroundColor,
            pointBorderWidth: 0,
            pointHoverRadius: 2,
            pointRadius: 0,
            pointHitRadius: 5,
            spanGaps: true,
            data: dataset.data,
            stack: 'combined' // Stack all datasets
        }))
    };
    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: 'MMM DD, YYYY',
                    unit: 'month'
                },
                ticks: {
                    maxTicksLimit: 7
                }
            },
            y: {
                stacked: true,
                min: 0,
                ticks: {
                    callback: (value) => `${yAxisPrefix}${numberSuffixFormatter(value)}`
                }
            }
        },
        plugins: {
            legend: {
                display: true, // Enable legend
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    title: (ctx) => ctx[0]?.dataset?.label?.toUpperCase() || '',
                    label: (ctx) => [ctx.formattedValue, ctx.label]
                }
            }
        }
    };
    //eslint-disable-next-line
    //@ts-ignore:next-line
    return _jsx(Line, { type: "line", data: lineChartData, options: lineOptions });
};
export default StackedLineChart;
