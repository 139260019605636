import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from 'harmonic-components/Modal/Modal';
import React, { createContext, useContext } from 'react';
const ModalContext = createContext(undefined);
const ModalComponent = ({ children }) => {
    const { close, options, isOpen } = useModal();
    return (_jsx(Modal, { ...options, isOpen: isOpen, onClose: options.onClose || close, children: children }));
};
const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [options, setOptions] = React.useState({});
    const [currentModalComponent, setCurrentModalComponent] = React.useState();
    const show = (component, options) => {
        setCurrentModalComponent(component);
        setOptions(options ?? {});
        setIsOpen(true);
    };
    const close = () => {
        setIsOpen(false);
        setTimeout(() => {
            setCurrentModalComponent(undefined);
        }, 500);
    };
    // If you need to render modal options dyncamically in the body of the modal
    const updateOptions = (newOptions) => {
        setOptions({ ...options, ...newOptions });
    };
    const state = {
        options,
        isOpen,
        show,
        close,
        updateOptions
    };
    return (_jsxs(ModalContext.Provider, { value: state, children: [children, _jsx(ModalComponent, { children: currentModalComponent })] }));
};
export function useModal() {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within UseModalProvider');
    }
    return context;
}
export default ModalProvider;
