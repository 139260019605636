import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import InternalLink from 'components/common/InternalLink';
import Avatar from 'harmonic-components/Avatar/Avatar';
const CompanyAttribute = ({ src, href, name, disabled = false, internalLink = false, className }) => {
    const wrapperClass = classNames('flex items-start gap-g30 cursor-pointer px-p20 py-p10 hover:bg-int-overlay-secondary-hover', 'aria-disabled:bg-int-overlay-secondary-disabled aria-disabled:cursor-default rounded-br30');
    const mainContent = (_jsxs(_Fragment, { children: [_jsx(Avatar, { size: "medium", kind: "logo", src: src, alt: "company-profile-picture" }), _jsx("p", { className: "hidden lg:inline text-content-strong typography-label-default-default line-clamp-1", children: name })] }));
    if (!internalLink)
        return (_jsx("a", { href: !disabled ? href : undefined, "aria-disabled": disabled, className: classNames(wrapperClass, className), target: "_blank", rel: "noopener noreferrer", children: mainContent }));
    return (_jsx(InternalLink, { to: { pathname: href }, children: _jsx("div", { className: wrapperClass, children: mainContent }) }));
};
export default CompanyAttribute;
