import { jsx as _jsx } from "react/jsx-runtime";
import { CalendarCheckIcon } from 'assets/harmonic-icons';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import './datepicker.css';
import classNames from 'classnames';
import dayjs from 'dayjs';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { useOutsideClick } from 'utils/hooks';
import DatePickerHeader from './DatePickerHeader';
import DatePickerInput from './DatePickerInput';
export var InputDateFormat;
(function (InputDateFormat) {
    InputDateFormat["DD_MM_YYYY"] = "DD_MM_YYYY";
    InputDateFormat["MM_DD_YYYY"] = "MM_DD_YYYY";
    InputDateFormat["YYYY_MM_DD"] = "YYYY_MM_DD";
    InputDateFormat["YYYY-MM-DD"] = "YYYY-MM-DD";
})(InputDateFormat || (InputDateFormat = {}));
const ListDateFormatMap = {
    [InputDateFormat.DD_MM_YYYY]: 'dd/MM/yyyy',
    [InputDateFormat.MM_DD_YYYY]: 'MM/dd/yyyy',
    [InputDateFormat.YYYY_MM_DD]: 'yyyy/MM/dd',
    [InputDateFormat['YYYY-MM-DD']]: 'yyyy-MM-dd' // if we are using input type='date', this is the only acceptable format
};
const ListDateFormatPlaceholderMap = {
    [InputDateFormat.DD_MM_YYYY]: 'DD/MM/YYYY',
    [InputDateFormat.MM_DD_YYYY]: 'MM/DD/YYYY',
    [InputDateFormat.YYYY_MM_DD]: 'YYYY/MM/DD',
    [InputDateFormat['YYYY-MM-DD']]: 'YYYY-MM-DD'
};
const SingleDatePicker = ({ selectedDate, onChange, fullWidth, minHeight, initiallyOpen = false, alwaysOpen = false, autoFocus = false, native = true, dateFormat = InputDateFormat['YYYY-MM-DD'] }) => {
    const [isOpen, setIsOpen] = useState(initiallyOpen);
    const calendarRef = useOutsideClick(() => {
        setIsOpen(false);
    });
    const onDateChange = (date) => {
        onChange(date ? dayjs(date).format('YYYY-MM-DD') : null);
        setIsOpen(false);
    };
    selectedDate = selectedDate ? dayjs(selectedDate).toString() : null;
    const isOpenState = alwaysOpen || isOpen;
    return (_jsx("div", { className: classNames(fullWidth && 'w-full'), ref: calendarRef, children: _jsx(ReactDatePicker
        //eslint-disable-next-line
        //@ts-ignore
        , { 
            //eslint-disable-next-line
            //@ts-ignore
            selected: selectedDate, open: isOpenState, onChange: (date) => onDateChange(date), onInputClick: () => setIsOpen(true), onClickOutside: () => setIsOpen(false), dateFormat: ListDateFormatMap[dateFormat], calendarClassName: "shadow-static-elevation-floating flex flex-col datepicker-calendar", showPopperArrow: false, wrapperClassName: classNames(fullWidth && 'w-full'), renderCustomHeader: ({ ...props }) => {
                return _jsx(DatePickerHeader, { ...props, selectedDate: selectedDate });
            }, customInput: 
            //eslint-disable-next-line
            //@ts-ignore
            _jsx(DatePickerInput, { autoFocus: autoFocus, initialFocus: initiallyOpen || alwaysOpen, minHeight: minHeight, native: native, inputPlaceholder: ListDateFormatPlaceholderMap[dateFormat] }), children: _jsx("div", { "data-testid": "Calendar-Action-Btn", className: "border-t border-solid border-border p-p30", children: _jsx(ListItem, { variant: ListVariant.default, primaryIcon: CalendarCheckIcon, value: "Today", label: "Today", onClick: () => {
                        onDateChange(new Date());
                    } }) }) }) }));
};
export default SingleDatePicker;
