import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PaperPlaneIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import TruncatedList from 'harmonic-components/TruncatedList/TruncatedList';
import SingleParticipant from './SingleParticipant';
const RoundedPaperPlaneIcon = () => (_jsx("div", { "data-testid": "RoundedPaperPlaneIcon", className: "flex items-center p-p20 bg-surface-sentiment-neutral rounded-br20", children: _jsx(PaperPlaneIcon, {}) }));
const EmailMessageCard = ({ name, startTime, participants }) => {
    const formattedTime = startTime
        ? dayjs(startTime).format('MM/DD/YYYY')
        : 'NA';
    return (_jsxs("div", { className: "border border-solid border-border rounded-br30 bg-surface-default", children: [_jsxs("div", { className: classNames('flex items-center justify-between p-p40', name && 'border-b border-solid border-border'), children: [_jsxs("div", { className: "flex mr-24 h-5 gap-g30", children: [!name && _jsx(RoundedPaperPlaneIcon, {}), _jsx(TruncatedList, { height: 20, wrapperClassName: "items-center", children: participants?.map((participant) => (_jsx(SingleParticipant, { ...participant }, participant.emailAddress))) })] }), _jsx("p", { className: "text-content-weak typography-label-small-default", children: formattedTime })] }), name && (_jsxs("div", { "data-testid": "EmailSubject", className: "flex flex-row gap-g30 p-p40", children: [_jsx(RoundedPaperPlaneIcon, {}), _jsx("div", { children: _jsx("p", { className: "text-content-default typography-label-default-default", children: name }) })] }))] }));
};
export default EmailMessageCard;
