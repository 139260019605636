import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import EmailIcon from 'assets/harmonic-icons/email-icon';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useModal } from 'hooks/useModal';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import React from 'react';
import PersonEmailUnlockModal from './PersonEmailUnlockModal';
const GetPersonEmailButtonContentLoader = () => {
    return _jsx("div", { className: "w-8 h-8 rounded-full animated-box-pulse" });
};
const GetPersonEmailButton = ({ personId, size = 'default' }) => {
    const { data: personContactInformation } = useQuery(getPersonContactInformation, {
        variables: {
            id: personId
        },
        fetchPolicy: 'cache-only'
    });
    const isUnlocked = Boolean(personContactInformation?.getPersonById?.contact?.emails?.length ||
        personContactInformation?.getPersonById?.emailEnrichmentStatus
            ?.enrichedAt !== null);
    const { show } = useModal();
    const handleOnUnlock = () => {
        show(_jsx(PersonEmailUnlockModal, { personId: personId }));
    };
    if (!personContactInformation) {
        return _jsx(GetPersonEmailButtonContentLoader, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "lg:hidden", children: _jsx(IconButton, { icon: EmailIcon, onClick: handleOnUnlock, type: "primary", emphasis: isUnlocked ? 'low' : 'high', size: "compact", dataTestId: "GetEmailTextIcon", ...(isUnlocked && { type: 'primary' }) }) }), _jsx("div", { className: "hidden lg:block", children: _jsx(Button, { leadingIcon: EmailIcon, onClick: handleOnUnlock, label: isUnlocked ? 'Contact' : 'Get email', type: "primary", emphasis: isUnlocked ? 'low' : 'high', size: size, dataTestId: "GetEmailText", ...(isUnlocked && { type: 'primary' }) }) })] }));
};
export default GetPersonEmailButton;
