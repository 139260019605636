import { useQuery } from '@apollo/client';
import { compact } from 'lodash';
import uniq from 'lodash/uniq';
import { getCompanyWatchlists } from 'queries/getWatchlists';
import { useMemo } from 'react';
import { UserWatchlistType, getSingletonWatchlist } from 'utils/watchlist';
const useFetchWatchlists = (options) => {
    const { data, ...rest } = useQuery(getCompanyWatchlists);
    const watchlists = useMemo(() => uniq(data?.getCompanyWatchlistsForTeam ?? []), [data]);
    const likedCompaniesWatchlist = useMemo(() => getSingletonWatchlist(compact(watchlists), UserWatchlistType.USER_FAVORITED_ENTITIES), [watchlists]);
    const hiddenCompaniesWatchlist = useMemo(() => getSingletonWatchlist(compact(watchlists), UserWatchlistType.USER_HIDDEN_ENTITIES), [watchlists]);
    const affinityLists = useMemo(() => compact(watchlists).filter((watchlist) => watchlist.userWatchlistType ===
        UserWatchlistType.CUSTOMER_AFFINITY_SYNCED_LIST), [watchlists]);
    return {
        watchlists,
        likedCompaniesWatchlist,
        hiddenCompaniesWatchlist,
        affinityLists,
        data,
        ...rest
    };
};
export default useFetchWatchlists;
