import { jsx as _jsx } from "react/jsx-runtime";
import { CommonCellRenderer } from '../CellRenderers/CommonCellRenderer';
import { COMPANY_HEADER_DESCRIPTION, COMPANY_HEADER_EXTERNAL_DESCRIPTION, COMPANY_HEADER_LEGAL_NAME, CommonCompanyDetailHeaders, GrowthMetricsHeaders } from './CommonHeaders';
import { formatHeadersForCellRenderers } from './utils';
const COMPANY_HEADER_ROW_ACTIONS = {
    field: 'company.row_actions',
    cellRenderer: 'CompanyNameWithCheckboxCellRenderer',
    headerName: 'Name',
    sortable: true,
    searchModelSortable: true,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_sortable_name',
    initialPinned: true,
    headerComponent: 'BulkSelectCheckboxRenderer',
    minWidth: 235,
    suppressSizeToFit: true,
    sortType: 'text',
    valueGetter: (params) => params.data
};
const COMPANY_HEADER_ID = {
    field: 'company.id',
    headerName: 'ID',
    toggleable: true,
    hide: true,
    width: 70,
    sortType: 'number'
};
const COMPANY_HEADER_RELEVANCE_SCORE = {
    field: 'company.relevance_score',
    headerName: 'Relevance Score',
    toggleable: true,
    initialWidth: 230,
    searchModelSortable: false,
    searchModelFilterable: false,
    hide: false,
    cellRenderer: 'RelevanceScoreCellRenderer',
    headerComponent: 'TooltipHeaderRenderer',
    customTooltip: 'Configure relevance score in Settings'
};
const COMPANY_HEADER_CREATED_AT = {
    field: 'company.created_at',
    headerName: 'Date added to Harmonic',
    toggleable: true,
    hide: true,
    initialWidth: 190,
    searchModelSortable: true,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_created_at',
    cellRenderer: 'DateCellRenderer',
    sortType: 'date'
};
const COMPANY_HEADER_ENTRY_CREATED_AT = {
    field: 'company.entry_created_at',
    headerName: 'Date added to list',
    toggleable: true,
    hide: true,
    initialWidth: 250,
    cellRenderer: 'DateCellRenderer'
};
const COMPANY_HEADER_UPDATED_AT = {
    field: 'company.updated_at',
    headerName: 'Updated Date',
    toggleable: true,
    hide: true,
    initialWidth: 150,
    searchModelSortable: true,
    searchModelFilterable: true,
    searchModelIdentifier: 'company_updated_at',
    cellRenderer: 'DateCellRenderer',
    sortType: 'date'
};
const CommonCompanyHeaders = [
    COMPANY_HEADER_ROW_ACTIONS,
    COMPANY_HEADER_ID,
    COMPANY_HEADER_RELEVANCE_SCORE,
    COMPANY_HEADER_LEGAL_NAME,
    COMPANY_HEADER_DESCRIPTION,
    COMPANY_HEADER_EXTERNAL_DESCRIPTION,
    COMPANY_HEADER_CREATED_AT,
    COMPANY_HEADER_ENTRY_CREATED_AT,
    COMPANY_HEADER_UPDATED_AT
];
const _CompanyHeaders = [
    ...CommonCompanyHeaders,
    ...CommonCompanyDetailHeaders,
    ...GrowthMetricsHeaders.map((header) => ({ ...header, hide: true }))
];
/*
  CommonCompanyHeadersByField is a map of header fields that are returned by the query without extended data.
*/
const CommonCompanyHeadersByField = formatHeadersForCellRenderers({
    headers: CommonCompanyHeaders,
    excludeHeaders: [COMPANY_HEADER_RELEVANCE_SCORE]
});
const CompanyHeaders = _CompanyHeaders.map((header) => {
    return {
        ...header,
        cellRenderer: (params) => (_jsx(CommonCellRenderer, { header: header, headers: CommonCompanyHeadersByField, ...params }))
    };
});
export const companySortableHeaderFields = [
    'company_id',
    ...CompanyHeaders.filter((header) => header.searchModelSortable && header.searchModelIdentifier).map((header) => header.searchModelIdentifier)
];
export default CompanyHeaders;
