import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import { CrossIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import Select from 'harmonic-components/Select/Select';
import { BackendCustomRangeModeType } from 'interfaces/SearchV2';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_CUSTOM_RANGE, formatCustomRange, getInternalModeFromBackendMode } from 'utils/searchV2/searchV2';
import RangeInlineDatePicker from './RangeInlineDatePicker';
import RollingRange from './RollingRange';
import SideActions from './SideActions';
import SingleInlineDatePicker from './SingleInlineDatepicker';
//Backend stores only two modes: custom and custom_rolling
//Our frontend has 4 modes: before, after, range, rolling_range
//We need to map the backend modes to the frontend modes
//custom -> before, after, range
//custom_rolling -> rolling_range
export var InternalModeType;
(function (InternalModeType) {
    InternalModeType["BEFORE"] = "before";
    InternalModeType["AFTER"] = "after";
    InternalModeType["RANGE"] = "range";
    InternalModeType["ROLLING_RANGE"] = "rolling_range";
    InternalModeType["IN"] = "in";
})(InternalModeType || (InternalModeType = {}));
const dayModeOptions = [
    {
        label: 'is on or before',
        value: InternalModeType.BEFORE
    },
    {
        label: 'is on or after',
        value: InternalModeType.AFTER
    },
    {
        label: 'is between',
        value: InternalModeType.RANGE
    },
    {
        label: 'is in rolling range',
        value: InternalModeType.ROLLING_RANGE
    }
];
const yearModeOptions = [
    {
        label: 'is between',
        value: InternalModeType.RANGE
    },
    {
        label: 'is in',
        value: InternalModeType.IN
    },
    {
        label: 'is in or before',
        value: InternalModeType.BEFORE
    },
    {
        label: 'is in or after',
        value: InternalModeType.AFTER
    },
    {
        label: 'is in rolling range',
        value: InternalModeType.ROLLING_RANGE
    }
];
export var DateTypeEnum;
(function (DateTypeEnum) {
    DateTypeEnum["DAY"] = "day";
    DateTypeEnum["YEAR"] = "year";
})(DateTypeEnum || (DateTypeEnum = {}));
const DateModal = ({ label, mode, value, onChange, open, onClose, anchorEl, dateType = DateTypeEnum.DAY }) => {
    const [stateMode, setStateMode] = useState(InternalModeType.BEFORE);
    const [beforeOrAfterDate, setBeforeOrAfterDate] = useState(null);
    const [rangeDate, setRangeDate] = useState([null, null]);
    const [rollingRangeValue, setRollingRangeValue] = useState(formatCustomRange(DEFAULT_CUSTOM_RANGE));
    if (dateType !== DateTypeEnum.DAY && dateType !== DateTypeEnum.YEAR) {
        throw new Error('Invalid date type');
    }
    const modeOptions = dateType === DateTypeEnum.YEAR ? yearModeOptions : dayModeOptions;
    useEffect(() => {
        if (!mode)
            return;
        const internalMode = getInternalModeFromBackendMode(mode, value);
        setStateMode(internalMode);
        if (internalMode === InternalModeType.BEFORE) {
            setBeforeOrAfterDate(value?.[1]);
        }
        if (internalMode === InternalModeType.AFTER) {
            setBeforeOrAfterDate(value?.[0]);
        }
        if (internalMode === InternalModeType.RANGE ||
            internalMode === InternalModeType.IN) {
            setRangeDate(value);
        }
        if (internalMode === InternalModeType.ROLLING_RANGE) {
            setRollingRangeValue(value);
        }
    }, [mode, value]);
    const onSideActionChange = (value) => {
        if (stateMode === InternalModeType.BEFORE ||
            stateMode === InternalModeType.AFTER) {
            setBeforeOrAfterDate(value);
        }
        else if (stateMode === InternalModeType.RANGE ||
            stateMode === InternalModeType.IN) {
            setRangeDate(value);
        }
    };
    const onApplyFilter = () => {
        if (stateMode === InternalModeType.BEFORE) {
            onChange(BackendCustomRangeModeType.CUSTOM, [null, beforeOrAfterDate]);
        }
        if (stateMode === InternalModeType.AFTER) {
            onChange(BackendCustomRangeModeType.CUSTOM, [beforeOrAfterDate, null]);
        }
        if (stateMode === InternalModeType.RANGE ||
            stateMode === InternalModeType.IN) {
            onChange(BackendCustomRangeModeType.CUSTOM, rangeDate);
        }
        if (stateMode === InternalModeType.ROLLING_RANGE) {
            onChange(BackendCustomRangeModeType.CUSTOM_ROLLING, rollingRangeValue);
        }
        onClose();
    };
    const onClearFilter = () => {
        onChange(undefined, undefined);
        onClose();
    };
    const isButtonDisabled = useMemo(() => {
        if (stateMode === InternalModeType.BEFORE ||
            stateMode === InternalModeType.AFTER) {
            return isNil(beforeOrAfterDate);
        }
        if (stateMode === InternalModeType.RANGE) {
            return !rangeDate?.[0] && !rangeDate?.[1];
        }
        return false;
    }, [stateMode, beforeOrAfterDate, rangeDate]);
    const modeSelectComponent = (_jsx(Select, { selected: stateMode, multiple: false, getLabelFromValue: (value) => {
            return (modeOptions.find((option) => option.value === value)?.label ?? '');
        }, dataTestId: "CustomRange-Mode-Select", children: modeOptions.map((option) => (_jsx(ListItem, { variant: ListVariant.default, value: option.value, label: option.label, onClick: () => setStateMode(option.value) }, option.value))) }));
    return (_jsx(Popover, { open: open, anchorEl: anchorEl, onClose: onClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        }, classes: {
            paper: 'border-border border-solid shadow-static-elevation-floating mt-p30'
        }, disableAutoFocus: true, disableEnforceFocus: true, children: _jsxs("div", { className: "w-[560px]", "data-testid": `date-modal-${dateType}`, children: [_jsxs("div", { className: "p-p50 flex justify-between gap-g50 border-b border-solid border-border", children: [_jsx("p", { className: "typography-label", children: label || 'Custom range' }), _jsx(IconButton, { size: "tiny", type: "secondary", emphasis: "low", onClick: onClose, icon: CrossIcon })] }), stateMode === InternalModeType.ROLLING_RANGE && (_jsxs("div", { className: "p-p50", children: [_jsx("div", { className: "w-1/2", children: modeSelectComponent }), _jsx("div", { className: "h-[300px]", children: _jsx(RollingRange, { value: rollingRangeValue, onChange: (newVal) => setRollingRangeValue(newVal), dateType: dateType }) }), _jsx("div", { className: "pt-p40 border-t border-solid border-border flex", children: _jsx(Button, { label: "Apply filter", fullWidth: true, type: "secondary", emphasis: "high", isDisabled: isButtonDisabled, dataTestId: "CustomRange-Apply-Btn", onClick: onApplyFilter }) })] })), stateMode !== InternalModeType.ROLLING_RANGE && (_jsxs("div", { className: "grid grid-cols-2 gap-g50 p-p50 min-h-[250px]", children: [_jsx("div", { className: "flex flex-col gap-g40 justify-between", children: _jsxs("div", { className: "flex flex-col gap-g40", children: [modeSelectComponent, _jsx(SideActions, { mode: stateMode, onChange: onSideActionChange, dateType: dateType })] }) }), _jsxs("div", { children: [(stateMode === InternalModeType.BEFORE ||
                                    stateMode === InternalModeType.AFTER) && (_jsx(SingleInlineDatePicker, { selectedDate: beforeOrAfterDate, onChange: (date) => {
                                        setBeforeOrAfterDate(date);
                                    }, dateType: dateType, internalModeType: stateMode })), stateMode === InternalModeType.IN && (_jsx(SingleInlineDatePicker, { selectedDate: rangeDate[0], onChange: (date) => {
                                        if (date) {
                                            // In 2022 means 2022-01-01 to 2022-12-31
                                            // date is the first day of the year
                                            setRangeDate([date, `${date.split('-')[0]}-12-31`]);
                                        }
                                    }, dateType: dateType })), stateMode === InternalModeType.RANGE && (_jsx(RangeInlineDatePicker, { value: rangeDate, onChange: (date) => setRangeDate(date), dateType: dateType })), _jsxs("div", { className: "pt-p40 border-t border-solid border-border flex gap-g20", children: [_jsx(Button, { label: "Clear", fullWidth: true, type: "secondary", emphasis: "high", dataTestId: "CustomRange-Clear-Btn", onClick: onClearFilter }), _jsx(Button, { label: "Apply", fullWidth: true, type: "primary", emphasis: "high", isDisabled: isButtonDisabled, dataTestId: "CustomRange-Apply-Btn", onClick: onApplyFilter })] })] })] }))] }) }));
};
export default DateModal;
