import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { DEFAULT_DATE_SELECTOR_OPTIONS, TimeUnit, YEAR_ONLY_DATE_SELECTOR_OPTIONS } from 'interfaces/SearchV2';
import { useMemo } from 'react';
import { formatCustomRange, parseCustomRange } from 'utils/searchV2/searchV2';
import SelectorWithValue from '../SelectorWithValue';
import { DateTypeEnum } from './DateModal';
const RollingRange = ({ value, onChange, dateType }) => {
    const customRange = useMemo(() => {
        return parseCustomRange(value);
    }, [value]);
    const isYear = dateType === DateTypeEnum.YEAR;
    return (_jsx("div", { "data-testid": "RollingRange", children: _jsxs("div", { className: classNames('mt-2 flex rounded-md py-3 px-2 bg-surface-raised typography-label w-full'), children: [_jsxs("div", { children: [_jsx("div", { className: "typography-label", children: "Between" }), _jsxs("div", { className: "mt-g40 flex flex-row items-center", children: [customRange?.start.timeUnit !== undefined && (_jsx(SelectorWithValue, { selector: {
                                        options: !isYear
                                            ? DEFAULT_DATE_SELECTOR_OPTIONS
                                            : YEAR_ONLY_DATE_SELECTOR_OPTIONS,
                                        value: customRange?.start.timeUnit,
                                        onChange: (value) => {
                                            const updatedRange = {
                                                ...customRange,
                                                start: {
                                                    timeUnit: value,
                                                    value: 0
                                                }
                                            };
                                            onChange(formatCustomRange(updatedRange));
                                        }
                                    }, value: customRange?.start.value, hideValueInput: customRange?.start.timeUnit === TimeUnit.TODAY, onValueChange: (e) => {
                                        const newValue = e.target.value.replace(/[^0-9]/g, '');
                                        const updatedRange = {
                                            ...customRange,
                                            start: {
                                                timeUnit: customRange?.start.timeUnit,
                                                value: newValue ? parseInt(newValue) : undefined
                                            }
                                        };
                                        onChange(formatCustomRange(updatedRange));
                                    } })), _jsx("span", { className: "mx-g50 typography-label", children: "-" })] })] }), customRange?.end.timeUnit !== undefined && (_jsxs("div", { children: [_jsx("div", { className: "typography-label", children: "and" }), _jsx("div", { className: "mt-g40", children: _jsx(SelectorWithValue, { selector: {
                                    options: !isYear
                                        ? DEFAULT_DATE_SELECTOR_OPTIONS
                                        : YEAR_ONLY_DATE_SELECTOR_OPTIONS,
                                    value: customRange?.end.timeUnit,
                                    onChange: (value) => {
                                        const updatedRange = {
                                            ...customRange,
                                            end: {
                                                timeUnit: value,
                                                value: 0
                                            }
                                        };
                                        onChange(formatCustomRange(updatedRange));
                                    }
                                }, value: customRange?.end.value, hideValueInput: customRange?.end.timeUnit === TimeUnit.TODAY, onValueChange: (e) => {
                                    const newValue = e.target.value.replace(/[^0-9]/g, '');
                                    const updatedRange = {
                                        ...customRange,
                                        end: {
                                            timeUnit: customRange?.end.timeUnit,
                                            value: newValue ? parseInt(newValue) : undefined
                                        }
                                    };
                                    onChange(formatCustomRange(updatedRange));
                                } }) })] }))] }) }));
};
export default RollingRange;
