import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PeopleAltOutlined } from '@material-ui/icons';
import { LinkedInIcon } from 'assets/harmonic-icons';
import InternalLink from 'components/common/InternalLink';
import Paper from 'components/common/Paper';
import PersonUserConnections from 'components/common/PersonUserConnections';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { compact, uniq } from 'lodash';
import { useState } from 'react';
import AvatarWithHighlightBadge from '../../../common/Highlights/AvatarWithHighlightBadge';
import UserConnectionsDegree from './UserConnectionsDegree';
const LoadMore = ({ onClick }) => {
    return (_jsx("button", { className: "text-content-headerGrayAlt text-base", onClick: onClick, children: "Load More" }));
};
const PersonWithConnectionsCard = ({ personWithConnections, currentCompanyId }) => {
    const person = personWithConnections.person;
    const personEmail = personWithConnections.personEmail;
    const userConnections = personWithConnections.userConnections;
    const id = person?.id;
    const current = person?.experience?.find((exp) => {
        return exp?.isCurrentPosition && exp.company.id === currentCompanyId;
    });
    const currentTitle = current?.title;
    const highlights = uniq(compact(person?.highlights?.map((highlight) => highlight?.category))) ?? [];
    return (_jsx(Paper, { children: _jsxs("div", { "data-testid": "UserConnectionsList-PersonWithConnectionsCard", className: "flex flex-row items-start gap-x-4 min-h-18", children: [id && (_jsx(AvatarWithHighlightBadge, { href: `/dashboard/person/${id}`, src: person?.profilePictureUrl ?? '', highlights: highlights, personId: id })), _jsxs("div", { className: "gap-y-1 w-full", children: [_jsxs("div", { className: "flex flex-row items center justify-start gap-x-g20", children: [_jsx(InternalLink, { to: { pathname: `/dashboard/person/${id}` }, children: _jsx("p", { className: "text-content-standaloneGray typography-label-large-strong line-clamp-1", children: person?.fullName }) }), person?.socials?.linkedin?.url && (_jsx(IconButton, { icon: (props) => (_jsx(LinkedInIcon, { ...props, applyCurrentColor: false })), onClick: () => window.open(person.socials?.linkedin?.url), size: "tiny", type: "secondary", emphasis: "low" })), _jsxs("div", { "data-testid": "LinkedinOutlink", className: "flex flex-row items-center gap-x-g10", children: [_jsx("span", { className: "text-content-weak", children: "\u2022" }), _jsx("p", { className: "text-content-headerGray", children: _jsx(UserConnectionsDegree, { userConnections: userConnections }) })] })] }), _jsx("p", { className: "line-clamp-1 text-content-weak typography-label", children: currentTitle }), _jsx("p", { children: _jsx(PersonUserConnections, { target: { name: person?.fullName ?? '' }, userConnections: userConnections, iconFill: "weak" }) })] })] }) }, `person-${person?.id}-${personEmail}`));
};
const UserConnectionsListContentLoader = () => {
    const rows = [...Array(8)];
    return (_jsx("div", { "data-testid": "UserConnectionsListContentLoader", children: rows.map((_, index) => {
            return (_jsx("div", { className: "flex w-full animated-box-pulse h-24 py-1.5" }, index));
        }) }));
};
const UserConnectionsList = ({ peopleWithConnections, loading, currentCompanyId }) => {
    const showContentLoader = loading && PeopleAltOutlined.length === 0;
    const [numShowing, setNumShowing] = useState(5);
    if (showContentLoader) {
        return _jsx(UserConnectionsListContentLoader, {});
    }
    return (_jsxs("div", { "data-testid": "UserConnectionsList", className: "w-full", children: [_jsx("div", { className: "flex flex-col space-y-4", children: peopleWithConnections
                    .slice(0, numShowing)
                    .map((personWithConnections) => (_jsx(PersonWithConnectionsCard, { personWithConnections: personWithConnections, currentCompanyId: currentCompanyId }, `person-${personWithConnections.person?.id}-${personWithConnections.personEmail}`))) }), _jsx("div", { className: "flex justify-center items-center mt-4", children: numShowing < peopleWithConnections.length && (_jsx(LoadMore, { onClick: () => setNumShowing(numShowing + 5) })) })] }));
};
export default UserConnectionsList;
