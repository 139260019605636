import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from 'common/components';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { getFundingStageBadgeColor } from 'utils/funding';
const CapTableCell = ({ children }) => {
    return (_jsx("div", { className: "border-solid border-b border-border p-p40 typography-label-small-default text-content-default", children: children || '—' }));
};
const CapTableHeader = () => {
    return (_jsxs("div", { className: "grid grid-cols-7", children: [_jsx(CapTableHeaderCell, { children: "Stock" }), _jsx(CapTableHeaderCell, { children: "# of shares" }), _jsx(CapTableHeaderCell, { children: "Value" }), _jsx(CapTableHeaderCell, { children: "Issue price" }), _jsx(CapTableHeaderCell, { children: "Liquidation" }), _jsx(CapTableHeaderCell, { children: "Pref. multiple" }), _jsx(CapTableHeaderCell, { children: "Conversion" })] }));
};
const CapTableHeaderCell = ({ children }) => {
    return (_jsx("div", { className: "border-solid border-b py-p50 px-p40 border-border typography-label-small-default text-content-strong", children: children }));
};
const getRoundDisplay = (round, roundNumber) => {
    if (roundNumber) {
        return getFundingTypeDisplayName(round) + '-' + roundNumber;
    }
    else {
        return getFundingTypeDisplayName(round);
    }
};
const getRoundColor = (round) => {
    return getFundingStageBadgeColor(getFundingTypeDisplayName(round));
};
const CapTable = ({ companyId, capTableData }) => {
    return (_jsx("div", { className: "border-t border-r border-l border-border border-solid rounded-br30 overflow-x-scroll", children: _jsxs("div", { className: "min-w-[600px]", children: [_jsx(CapTableHeader, {}), capTableData?.map((capTableRow, index) => (_jsxs("div", { className: "grid grid-cols-7", children: [_jsx(CapTableCell, { children: _jsx(Badge, { label: getRoundDisplay(capTableRow.round, capTableRow.roundNumber), color: getRoundColor(capTableRow.round), size: "small", intensity: "subtle", borderRadius: "square" }) }), _jsx(CapTableCell, { children: capTableRow.shares && capTableRow.shares.toLocaleString() }), _jsx(CapTableCell, { children: capTableRow.value && '$' + capTableRow.value }), _jsx(CapTableCell, { children: capTableRow.issuePrice && '$' + capTableRow.issuePrice }), _jsx(CapTableCell, { children: capTableRow.liquidation && '$' + capTableRow.liquidation }), _jsx(CapTableCell, { children: capTableRow.pref_multiple && capTableRow.pref_multiple + 'x' }), _jsx(CapTableCell, { children: capTableRow.conversion && '$' + capTableRow.conversion })] }, 'grid-row-' + index)))] }) }));
};
export default CapTable;
