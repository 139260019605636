import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useAddEntityListModal } from 'hooks/useAddEntityListModal';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import { EntityListType, INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import React, { useState } from 'react';
import { UserWatchlistType } from 'utils/watchlist';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import ToggleCompanyListsDropdown from '../EntityActions/ToggleCompanyListsDropdown';
import AddCompanytoWatchlistButtonContentLoader from './AddCompanyToWatchlistButtonContentLoader';
const AddCompanyToWatchlistButton = ({ companyId, type = 'button' }) => {
    const [createWatchlistModalOpen, setCreateWatchlistModalOpen] = useState(false);
    const [watchlistActionsPopoverAnchorEl, setWatchlistActionsPopoverAnchorEl] = useState(null);
    const WatchlistActionsPopoverOpen = Boolean(watchlistActionsPopoverAnchorEl);
    const handleWatchlistActionsPopoverClose = () => {
        setWatchlistActionsPopoverAnchorEl(null);
    };
    const handleWatchlistActionPopoverOpen = (event) => {
        event.stopPropagation();
        setWatchlistActionsPopoverAnchorEl(event.currentTarget);
    };
    const { watchlists: companyWatchlists, loading: companyWatchlistLoading } = useFetchWatchlists();
    const { addCompaniesToWatchlist, getWatchlistContainingCompanies } = useWatchlistActions();
    const onCreateWatchlistSuccess = async ({ entityListId }) => {
        await addCompaniesToWatchlist({
            watchlistId: entityListId,
            shouldNavigate: true,
            companyIds: [companyId]
        });
        handleWatchlistActionsPopoverClose();
    };
    const { showAddEntityListModal } = useAddEntityListModal({
        entityType: EntityListType.COMPANY_WATCHLIST,
        searchQuery: INITIAL_SEARCH_MODEL,
        onSuccess: onCreateWatchlistSuccess
    });
    const filteredCompanyWatchlists = companyWatchlists?.filter((watchlist) => watchlist?.userWatchlistType === UserWatchlistType.USER_MANAGED_WATCHLIST);
    if (companyWatchlistLoading)
        return _jsx(AddCompanytoWatchlistButtonContentLoader, {});
    return (_jsxs(_Fragment, { children: [type === 'button' && (_jsx(Button, { leadingIcon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "primary", emphasis: "high", label: "Add to...", dataTestId: "AddCompanyToWatchlistButton-Plus" })), type === 'icon' && (_jsx(IconButton, { icon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "secondary", emphasis: "high", dataTestId: "AddCompanyToWatchlistButton-Plus" })), type === 'inline' && (_jsx(IconButton, { icon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "secondary", emphasis: "low", dataTestId: "AddCompanyToWatchlistButton-Plus" })), type === 'inline-small' && (_jsx(IconButton, { icon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "secondary", emphasis: "low", dataTestId: "AddCompanyToWatchlistButton-Plus", size: "compact" })), _jsx(ToggleCompanyListsDropdown, { open: WatchlistActionsPopoverOpen, anchorEl: watchlistActionsPopoverAnchorEl, onClose: handleWatchlistActionsPopoverClose, companyIds: [companyId] })] }));
};
export default AddCompanyToWatchlistButton;
