import { DateListCustomFieldValueFormat, ListCustomFieldType, NumberListCustomFieldValueFormat } from '__generated__/graphql';
import { DollarIcon, HashtagIcon, PercentIcon, PersonCircleIcon, StatusIcon } from 'assets/harmonic-icons';
import { CalendarIcon, CheckboxIcon, MultiSelectIcon, NumberIcon, SelectIcon, TextIcon, URLIcon } from '../../../../assets/harmonic-icons';
export const EditFieldTypeOptions = [
    {
        label: 'Text',
        value: ListCustomFieldType.TEXT,
        icon: TextIcon
    },
    {
        label: 'Number',
        value: ListCustomFieldType.NUMBER,
        icon: NumberIcon
    },
    {
        label: 'Person',
        value: ListCustomFieldType.PERSON,
        icon: PersonCircleIcon
    },
    {
        label: 'Date',
        value: ListCustomFieldType.DATE,
        icon: CalendarIcon
    },
    {
        label: 'Status',
        value: ListCustomFieldType.STATUS,
        icon: StatusIcon
    },
    {
        label: 'Single select',
        value: ListCustomFieldType.SINGLE_SELECT,
        icon: SelectIcon
    },
    {
        label: 'Multi select',
        value: ListCustomFieldType.MULTI_SELECT,
        icon: MultiSelectIcon
    },
    {
        label: 'URL',
        value: ListCustomFieldType.WEBSITE,
        icon: URLIcon
    },
    {
        label: 'Checkbox',
        value: ListCustomFieldType.CHECKBOX,
        icon: CheckboxIcon
    }
];
export const EditFieldTypeOptionLabel = EditFieldTypeOptions.reduce((acc, option) => ({ ...acc, [option.value]: option.label }), {});
export const EditFieldTypeOptionIcon = EditFieldTypeOptions.reduce((acc, option) => ({ ...acc, [option.value]: option.icon }), {});
export var PersonType;
(function (PersonType) {
    PersonType["CURRENT_USER"] = "current user";
    PersonType["EMPTY"] = "empty";
})(PersonType || (PersonType = {}));
export const PersonOptions = [
    {
        value: PersonType.CURRENT_USER,
        label: 'Current user'
    },
    {
        value: PersonType.EMPTY,
        label: 'Empty'
    }
];
export const PersonOptionLabel = PersonOptions.reduce((acc, option) => ({ ...acc, [option.value]: option.label }), {});
export const DateOptions = [
    {
        value: DateListCustomFieldValueFormat.MM_DD_YYYY,
        label: 'MM/DD/YYYY'
    },
    {
        value: DateListCustomFieldValueFormat.DD_MM_YYYY,
        label: 'DD/MM/YYYY'
    },
    {
        value: DateListCustomFieldValueFormat.YYYY_MM_DD,
        label: 'YYYY/MM/DD'
    }
];
export const DateOptionLabel = DateOptions.reduce((acc, option) => ({ ...acc, [option.value]: option.label }), {});
export const FormatOptions = [
    {
        value: NumberListCustomFieldValueFormat.NUMBER,
        label: 'Number',
        icon: HashtagIcon
    },
    {
        value: NumberListCustomFieldValueFormat.PERCENT,
        label: 'Percent',
        icon: PercentIcon
    },
    {
        value: NumberListCustomFieldValueFormat.US_DOLLAR,
        label: 'US dollar',
        icon: DollarIcon
    }
];
export const FormatOptionLabel = FormatOptions.reduce((acc, option) => ({ ...acc, [option.value]: option.label }), {});
