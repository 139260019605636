import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogContent } from '@material-ui/core';
import { CrossLargeIcon, TwoStarsIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import { Permission } from 'hooks/usePermissions';
import React from 'react';
import { HARMONIC_PLAN_TIER_DOC } from 'utils/constants';
const UpgradeToProModal = ({ isOpen, handleClose, mode }) => {
    const onUpgradeClick = () => {
        window.open(HARMONIC_PLAN_TIER_DOC, '_blank');
    };
    let title = 'Upgrade to Enterprise to access';
    let description = 'Upgrade now to access this feature.';
    if (mode === Permission.ExportCsv) {
        title = 'Export as CSV';
        description =
            'Upgrade your plan to Enterprise to export all results to CSV';
    }
    if (mode === Permission.PeopleSearch) {
        title = 'Explore 110M+ people profiles';
        description = 'Upgrade your plan to Enterprise to unlock People Search.';
    }
    if (mode === Permission.PersonProfile) {
        title = 'Explore 110M+ people profiles';
        description = 'Upgrade your plan to Enterprise to unlock people profiles.';
    }
    if (mode === Permission.PeopleList) {
        title = 'Track job changes across interesting profiles';
        description =
            'Upgrade your plan to Enterprise to add people to lists and receive alerts when they have career changes.';
    }
    const enterpriseBadge = (_jsx(Badge, { label: "Enterprise", leadingIcon: TwoStarsIcon, color: 'informative' }));
    return (_jsx(Dialog, { className: "p-0 mx-auto", open: isOpen, classes: {
            paper: 'rounded-br40 bg-surface-default text-left align-middle shadow-static-elevation-floating transition-all w-fit w-max-96'
        }, onClose: handleClose, fullWidth: true, children: _jsxs(DialogContent, { className: "p-0 min-w-96 relative", children: [_jsx(ModalTitle, { title: title, titleBadge: enterpriseBadge }), _jsx("p", { className: "typography-paragraph text-content-default px-p50 pt-p50 pb-p80", children: description }), _jsx(ModalFooter, { children: _jsx(Button, { label: 'Learn more', emphasis: "high", type: "primary", onClick: onUpgradeClick }) }), _jsx("div", { className: "absolute right-0 top-0 pr-p40 pt-p20", children: _jsx(IconButton, { "data-testid": "Modal-Close-Button", icon: CrossLargeIcon, onClick: handleClose, type: "secondary", emphasis: "low" }) })] }) }));
};
export default UpgradeToProModal;
