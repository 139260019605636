import { gql } from '@apollo/client';
import { SAVED_SEARCH_SIMPLE_FRAGMENT } from './getSavedSearch';
export const UPDATE_SAVED_SEARCH = gql `
  mutation UpdateSavedSearch(
    $idOrUrn: String!
    $updateSavedSearchInput: SavedSearchUpdateInput!
  ) {
    updateSavedSearch(
      idOrUrn: $idOrUrn
      updateSavedSearchInput: $updateSavedSearchInput
    ) {
      ...SavedSearchSimpleMetadata
    }
  }
  ${SAVED_SEARCH_SIMPLE_FRAGMENT}
`;
