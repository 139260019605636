import { useApolloClient } from '@apollo/client';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';
const useUser = () => {
    const client = useApolloClient(); // Get access to the Apollo Client instance
    const setIntegrationStatus = (type, isEnabled) => {
        const currentUser = client.cache.readQuery({
            query: GET_CURRENT_USER
        });
        if (currentUser) {
            const updatedIntegrationStatus = currentUser?.getCurrentUser?.connectionsIntegrationStatus?.map((integration) => {
                if (integration?.type === type) {
                    return {
                        ...integration,
                        isEnabled
                    };
                }
                return integration;
            });
            const updatedCurrentUser = {
                ...currentUser.getCurrentUser,
                connectionsIntegrationStatus: updatedIntegrationStatus
            };
            client.cache.writeQuery({
                query: GET_CURRENT_USER,
                data: {
                    getCurrentUser: updatedCurrentUser
                }
            });
        }
    };
    return { setIntegrationStatus };
};
export default useUser;
