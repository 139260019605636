import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
export const LightningIcon = _ref => {
  let {
    applyCurrentColor = true,
    className
  } = _ref;
  return _jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "current",
    className: classNames(applyCurrentColor && 'fill-current', className),
    children: _jsx("path", {
      d: "M9.33277 1.57049C9.33277 0.752743 8.27769 0.424206 7.81343 1.09738L2.1149 9.36025C1.73365 9.91307 2.12937 10.6667 2.80091 10.6667H6.66611V14.4296C6.66611 15.2473 7.72119 15.5759 8.18545 14.9027L13.884 6.6398C14.2652 6.08699 13.8695 5.33336 13.198 5.33336H9.33277V1.57049Z",
      fill: "current"
    })
  });
};