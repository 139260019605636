import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Tooltip } from 'common/components';
import dayjs from 'dayjs';
import Button from 'harmonic-components/Button/Button';
import { isNil } from 'lodash';
import { useState } from 'react';
import { CHART_DEFAULT_MINIMUM_START_DATE } from 'utils/chart';
import { getFundingStageBadgeColor } from 'utils/funding';
import { truncateMoneyValue } from 'utils/utilities';
export const formatBarDataPointsForLegends = (barChartDataPoints, minimumDate) => {
    const sortedBarChartDataPoints = [...barChartDataPoints].sort((a, b) => {
        const dateA = new Date(a.x);
        const dateB = new Date(b.x);
        if (dateA >= new Date(minimumDate) && dateB >= new Date(minimumDate)) {
            return dateA.getTime() - dateB.getTime();
        }
        else if (dateA >= new Date(minimumDate)) {
            return -1;
        }
        else if (dateB >= new Date(minimumDate)) {
            return 1;
        }
        else {
            return dateA.getTime() - dateB.getTime();
        }
    });
    const filteredBarChartDataPoints = sortedBarChartDataPoints.filter((point) => {
        return !isNil(point.y) && point.y !== 0;
    });
    const formattedPoints = filteredBarChartDataPoints.map((point) => {
        const isPointLessThanLimit = dayjs(point.x).isBefore(minimumDate);
        return {
            ...point,
            isPointLessThanLimit
        };
    });
    return formattedPoints;
};
const LegendChip = ({ label, date, amount, fundingTotal, isPointLessThanLimit }) => {
    return (_jsx(Tooltip, { body: _jsxs(_Fragment, { children: [_jsx("p", { className: "typography-label-small-strong", children: label.toUpperCase() }), _jsxs("p", { className: "typography-label-small", children: ["Raised:", ' ', _jsx("span", { className: "font-semibold", children: truncateMoneyValue(amount, 0) })] }), _jsx("p", { className: "typography-label-small", children: dayjs(date).format('MM/DD/YYYY') })] }), children: _jsxs("div", { className: "typography-label-small cursor-default flex gap-g30 items-center", children: [_jsx(Badge, { label: label.trimEnd(), color: isPointLessThanLimit
                        ? 'neutral'
                        : getFundingStageBadgeColor(label.trimEnd()), size: "medium", intensity: "highlight" }), _jsx("span", { className: "text-content-default typography-label", children: truncateMoneyValue(amount, 0) })] }) }));
};
const CompanyTimelineGraphLegends = ({ barChartData, fundingTotal, minimumDate = CHART_DEFAULT_MINIMUM_START_DATE }) => {
    const [isSeeAllMode, setIsSeeAllMode] = useState(false);
    const formattedBarDataPoints = formatBarDataPointsForLegends(barChartData, minimumDate);
    const pointsWithinLimit = formattedBarDataPoints.filter((point) => !point.isPointLessThanLimit);
    const pointsOutsideLimit = formattedBarDataPoints.filter((point) => point.isPointLessThanLimit);
    const allPointsAreWithinLimit = pointsWithinLimit.length === formattedBarDataPoints.length;
    return (_jsxs("div", { className: "mt-4 flex gap-x-g80 ml-10 flex-wrap items-center", children: [_jsx("div", { className: "flex gap-x-g80 gap-y-g50 flex-wrap items-center", children: pointsWithinLimit.map((point) => {
                    return (_jsx(LegendChip, { label: point.label, amount: point.y, date: point.x, isPointLessThanLimit: point.isPointLessThanLimit, fundingTotal: fundingTotal }, point.x));
                }) }), isSeeAllMode && (_jsx("div", { className: "my-4 flex gap-x-g80 gap-y-g50 flex-wrap items-center w-full", children: pointsOutsideLimit.map((point) => {
                    return (_jsx(LegendChip, { label: point.label, amount: point.y, date: point.x, isPointLessThanLimit: point.isPointLessThanLimit, fundingTotal: fundingTotal }, point.x));
                }) })), !allPointsAreWithinLimit && (_jsx(Button, { type: "secondary", emphasis: "low", onClick: () => setIsSeeAllMode(!isSeeAllMode), label: isSeeAllMode ? 'Collapse' : 'See All', size: "compact" }))] }));
};
export default CompanyTimelineGraphLegends;
