import { gql } from '@apollo/client';
export const REFRESH_PERSON_EMAILS = gql `
  mutation RefreshPersonEmails($personUrn: String!) {
    refreshPersonEmails(personUrn: $personUrn) {
      creditsUsed
      emails {
        email
      }
      personUrn
      remainingCredits
    }
  }
`;
