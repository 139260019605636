import { gql } from '@apollo/client';
export const getCompaniesViewRecords = gql `
  query GetCompaniesViewRecords($ids: [Int!]!, $extended: Boolean!) {
    getCompaniesByIds(ids: $ids, extended: $extended) {
      id
      viewRecord {
        createdAt
        updatedAt
      }
    }
  }
`;
