import { gql } from '@apollo/client';
export const GET_ALL_TEAM_MEMBERS_WITH_INTEGRATED_EMAILS = gql `
  query GetAllTeamMembersWithIntegratedEmailsByCustomer(
    $customer_urn: String!
  ) {
    getAllTeamMembersWithIntegratedEmailsByCustomer(urn: $customer_urn) {
      entityUrn
      email
      name
      integratedEmails
    }
  }
`;
