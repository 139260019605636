import { EntityURNTypeToDashboardPath } from 'interfaces/SearchModel/Search';
import { COMPANIES_ROUTE, COMPANY_ROUTE, DASHBOARD_ROUTE, PERSON_ROUTE, WATCHLIST_ROUTE } from './constants';
export const getUrnValues = (urn) => {
    const urnValues = urn.split(':');
    if (urnValues.length === 0 || urnValues[0] !== 'urn') {
        return null;
    }
    return urnValues;
};
export const updateIdtoURN = (id, entity) => {
    return `urn:harmonic:${EntityURNTypeToDashboardPath[entity]}:${id}`;
};
export const getIdFromUrn = (urn) => {
    if (!urn) {
        return null;
    }
    const urnValues = urn.split(':');
    const newURNValue = urnValues[urnValues.length - 1];
    return newURNValue;
};
export const getUrnFragment = (urn, fragment) => {
    if (!urn) {
        return null;
    }
    const urnValues = urn.split(':');
    const urnValue = urnValues[fragment];
    if (urnValue) {
        return urnValue;
    }
    return null;
};
export const getIdFromUrl = (url) => {
    const urlMatch = url.match(/\/urn:harmonic:[^:]+:([^/?]+)/);
    return urlMatch && urlMatch[1];
};
export const getHarmonicUrlFromUrn = (urn) => {
    const type = getUrnFragment(urn, 2);
    if (!type) {
        return undefined;
    }
    const id = getIdFromUrn(urn);
    const typeToUrlMap = {
        company: `${COMPANY_ROUTE}/${id}`,
        person: `${PERSON_ROUTE}/${id}`,
        saved_search: `${COMPANIES_ROUTE}/${urn}`,
        company_watchlist: `${WATCHLIST_ROUTE}/${urn}`
    };
    if (!Object.keys(typeToUrlMap).includes(type)) {
        return undefined;
    }
    return `${DASHBOARD_ROUTE}${typeToUrlMap[type]}`;
};
export const urnFrom = (entityType, id) => {
    return `urn:harmonic:${entityType}:${id}`;
};
export const isHarmonicUser = (customerUrn) => {
    return '1' === getIdFromUrn(customerUrn);
};
