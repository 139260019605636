import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as EmailUnlockImage } from 'assets/email-unlock.svg';
import { ReactComponent as RefreshIcon } from 'assets/harmonic-icons/refresh.svg';
import { Badge } from 'common/components';
import dayjs from 'dayjs';
import Button from 'harmonic-components/Button/Button';
import Email from 'harmonic-components/Email/Email';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import Tag from 'harmonic-components/Tag/Tag';
import useRefreshPersonEmails from 'hooks/useRefreshPersonEmails';
import { compact } from 'lodash';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import React, { useCallback } from 'react';
const GettingAddress = () => (_jsxs("div", { className: "flex gap-g40 bg-int-fill-secondary-disabled text-int-fill-secondary-onDisabled py-p20 px-p40 rounded-br20", children: [_jsx("p", { className: "typography-label-default-default", children: "Getting address..." }), _jsx("div", { children: _jsx(CircularProgress, { color: "inherit", size: 12 }) })] }));
const EmailUnlock = ({ onUnlock, disabled, loading }) => {
    if (disabled) {
        return (_jsxs("div", { "data-testid": "EmailUnlock", className: "flex flex-col justify-center items-center gap-g40", children: [_jsx("p", { className: "flex justify-center typography-label-default-strong pt-p50", "data-testid": "NotEnoughCredits", children: "You have insufficient credits" }), _jsxs("div", { className: "typography-label-small-default text-content-weak text-center", children: [_jsxs("p", { className: "flex items-center gap-g20", children: ["You need ", _jsx(Tag, { label: "1" }), " more credit to"] }), _jsx("p", { children: "request this email." })] })] }));
    }
    return (_jsxs("div", { "data-testid": "EmailUnlock", className: "flex flex-col justify-center items-center gap-g60", children: [_jsx("p", { className: "flex justify-center typography-label-default-strong pt-p50", children: "Get this person\u2019s email address" }), loading ? (_jsx(GettingAddress, {})) : (_jsx(Button, { size: "compact", onClick: onUnlock, isDisabled: disabled, label: "Request email address" }))] }));
};
const EmailList = ({ emails }) => {
    return (_jsxs("div", { children: [_jsx("p", { className: "flex justify-center typography-label-default-strong pt-p50", children: "Click to copy the email address" }), _jsx("div", { "data-testid": "EmailList", className: "flex flex-row items-start justify-center p-4", children: _jsx("div", { className: "flex flex-col pl-2 gap-g20", children: emails.map((email) => (_jsx("div", { className: "flex flex-col items-center justify-center", children: _jsx(Email, { email: email }, email) }, email))) }) })] }));
};
const EmailUnavailable = () => (_jsxs("div", { className: "flex flex-col justify-center items-center gap-g40 px-p100", "data-testid": "EmailUnavailable", children: [_jsx("p", { className: "flex justify-center typography-label-default-strong pt-p50", children: "Email address unavailable" }), _jsxs("p", { className: "typography-label-small-default text-content-weak text-center", children: ["Unfortunately, this person\u2019s email address ", _jsx("br", {}), " isn\u2019t available right now."] })] }));
const PersonEmailUnlockModal = ({ personId }) => {
    const { refreshPersonEmails, loading } = useRefreshPersonEmails();
    const { data: personContactInformation } = useQuery(getPersonContactInformation, {
        variables: {
            id: personId
        },
        fetchPolicy: 'cache-only'
    });
    const name = personContactInformation?.getPersonById?.fullName ?? '';
    const emails = compact(personContactInformation?.getPersonById?.contact?.emails ?? []);
    const emailEnrichedAt = personContactInformation?.getPersonById?.emailEnrichmentStatus?.enrichedAt;
    const isUnlockable = emails.length === 0 && emailEnrichedAt === null;
    const emailAddressText = emails.length > 1 ? 'email addresses' : 'email address';
    const onUnlock = useCallback(() => {
        refreshPersonEmails(`urn:harmonic:person:${personId}`);
    }, [personId, refreshPersonEmails]);
    const lastRequestedDate = emailEnrichedAt
        ? dayjs(emailEnrichedAt).format('MM/DD/YYYY')
        : null;
    const showRefreshButton = emailEnrichedAt
        ? dayjs(emailEnrichedAt).isBefore(dayjs().subtract(1, 'months'))
        : true;
    const LastUpdated = useCallback(() => (_jsxs("div", { "data-testid": "LastUpdated", className: "flex flex-col justify-center items-center pt-p40 px-4 pb-2 gap-g40", children: [_jsxs("p", { className: "typography-label-small text-content-weak", children: ["Last updated: ", lastRequestedDate ? lastRequestedDate : 'N/A'] }), showRefreshButton && !loading && (_jsx("div", { className: "flex flex-col items-center justify-center gap-g40", children: _jsxs("button", { id: "RequestEmailsButton", onClick: onUnlock, className: "flex justify-center items-center hover:underline typography-label-small text-content-title gap-g20", children: [_jsx(RefreshIcon, {}), _jsx("p", { children: "Refresh" })] }) })), loading && _jsx(GettingAddress, {})] })), [lastRequestedDate, loading, onUnlock, showRefreshButton]);
    return (_jsxs("div", { className: "w-px-480", children: [_jsx(ModalTitle, { title: isUnlockable
                    ? `Request ${name}'s ${emailAddressText}`
                    : `${name}'s ${emailAddressText}`, titleBadge: _jsx(Badge, { label: "Beta" }) }), _jsxs("div", { className: "px-p50 py-p80", children: [_jsx("div", { className: "flex w-full justify-center", children: _jsx(EmailUnlockImage, {}) }), isUnlockable ? (_jsx(EmailUnlock, { loading: loading, onUnlock: onUnlock, disabled: false })) : emails.length > 0 ? (_jsx(EmailList, { emails: emails })) : (_jsx(EmailUnavailable, {})), emails.length > 0 && _jsx(LastUpdated, {})] })] }));
};
export default PersonEmailUnlockModal;
