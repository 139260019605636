import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EmailCalendarConsentScreen from 'assets/email-calendar-consent-screen.webp';
import { Badge } from 'common/components';
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import React from 'react';
const EmailCalendarTermsModal = ({ handleClose, onConfirmClick }) => {
    return (_jsxs("div", { className: "w-px-550", children: [_jsx(ModalTitle, { title: 'Connect Google account for network mapping', titleBadge: _jsx(Badge, { label: "Beta" }) }), _jsxs("div", { className: "p-p50 flex flex-col gap-g80", children: [_jsxs("p", { className: "typography-paragraph text-content-default", children: ["Check", ' ', _jsx("span", { className: "bg-surface-sentiment-neutral text-content-strong", children: "both permission boxes" }), ' ', "on the next screen to set up the Google email and calendar integration. Without these permissions, the integration will not be able to sync necessary data."] }), _jsx("div", { className: "flex justify-center", children: _jsx("img", { src: EmailCalendarConsentScreen, alt: "Email and Calendar Consent Screen", className: "w-[300px] drop-shadow-md" }) }), _jsxs("p", { className: "typography-paragraph text-content-default", children: ["We take your privacy very seriously. Read our Privacy Policy", ' ', _jsx("a", { href: "https://www.harmonic.ai/legal/privacy-policy", target: "_blank", rel: "noreferrer", className: "typography-paragraph-default-link text-content-strong", children: "here" }), "."] }), _jsxs("p", { className: "typography-paragraph text-content-default", children: [' ', "By connecting your Google account, you agree to the", ' ', _jsx("a", { href: "https://www.harmonic.ai/legal/harmonic-email-and-calendar-integrations-terms", target: "_blank", rel: "noreferrer", className: "typography-paragraph-default-link text-content-strong", children: "Harmonic Email and Calendar Integrations Terms" }), "."] })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { label: 'Cancel', emphasis: "medium", type: "secondary", onClick: handleClose }), _jsx(Button, { label: 'I accept these terms', emphasis: "high", type: "primary", onClick: onConfirmClick })] })] }));
};
export default EmailCalendarTermsModal;
