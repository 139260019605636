import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'common/components';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { getCompanyHighlights } from '../../../../../utils/cellRendererUtils';
import { LightTooltip } from '../../../../common';
import { getHighlightIcon } from '../../../../common/Highlights/HighlightsChip';
const HighlightBadge = React.memo(({ highlight }) => {
    const badgeContent = (_jsx(Badge, { leadingIcon: getHighlightIcon(highlight.category), label: highlight.category, color: "expressive-32", size: "large", suffix: highlight.count > 1 ? `${highlight.count}` : '' }));
    if (highlight.tooltip) {
        return (_jsx(LightTooltip, { enterDelay: 1000, placement: "bottom-end", interactive: true, title: highlight.tooltip, children: _jsx("div", { children: badgeContent }) }));
    }
    return badgeContent;
});
export const CompanyHighlights = (props) => {
    const company = get(props, 'node.data.company');
    const highlights = get(props, 'value');
    const allHighlights = useMemo(() => getCompanyHighlights(highlights, company), [highlights, company]);
    if (allHighlights.length === 0)
        return null;
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { height: 54, children: allHighlights.map((highlight, index) => (_jsx(HighlightBadge, { highlight: highlight }, `${highlight.category}-${index}`))) }) }));
};
