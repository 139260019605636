import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useState } from 'react';
import TogglePersonListsDropdown from './EntityActions/TogglePersonListDropdown';
const AddPeopleToWatchlist = ({ type = 'icon', personIds }) => {
    const [watchlistActionsPopoverAnchorEl, setWatchlistActionsPopoverAnchorEl] = useState(null);
    const WatchlistActionsPopoverOpen = Boolean(watchlistActionsPopoverAnchorEl);
    const handleWatchlistActionsPopoverClose = () => {
        setWatchlistActionsPopoverAnchorEl(null);
    };
    const handleWatchlistActionPopoverOpen = (event) => {
        event.preventDefault();
        setWatchlistActionsPopoverAnchorEl(event.currentTarget);
    };
    return (_jsxs(_Fragment, { children: [type === 'button' && (_jsx(Button, { leadingIcon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "primary", emphasis: "high", label: "Add to ...", size: "default", dataTestId: "AddPeopleToWatchlist-Btn" })), type === 'icon' && (_jsx("div", { children: _jsx(IconButton, { icon: PlusIcon, size: "compact", emphasis: "high", onClick: handleWatchlistActionPopoverOpen, dataTestId: "AddPeopleToWatchlist-Btn" }) })), type === 'inline' && (_jsx(IconButton, { icon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "inline", dataTestId: "AddPeopleToWatchlist-Btn" })), type === 'inline-small' && (_jsx(IconButton, { icon: PlusIcon, onClick: handleWatchlistActionPopoverOpen, type: "secondary", emphasis: "low", size: "compact" })), _jsx(TogglePersonListsDropdown, { open: WatchlistActionsPopoverOpen, anchorEl: watchlistActionsPopoverAnchorEl, onClose: handleWatchlistActionsPopoverClose, personIds: personIds })] }));
};
export default AddPeopleToWatchlist;
