import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Transition } from '@headlessui/react';
import { CrossLargeIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import React, { Fragment } from 'react';
export var ModalType;
(function (ModalType) {
    ModalType["default"] = "default";
    ModalType["inverted"] = "inverted";
})(ModalType || (ModalType = {}));
const Modal = ({ isOpen, onClose, children, showCloseBtn = true, preventCloseOnOutsideClick, type = ModalType.default }) => {
    return (_jsx(Transition, { appear: true, show: isOpen, as: Fragment, children: _jsxs(Dialog, { as: "div", className: "relative", onClose: !preventCloseOnOutsideClick ? onClose : () => Function.prototype, children: [_jsx(Transition.Child, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0", children: _jsx("div", { "data-testid": "Modal-Background", className: "fixed inset-0 bg-black bg-opacity-25 z-1400" }) }), _jsx("div", { className: "fixed inset-0 overflow-y-auto z-1400", children: _jsx("div", { className: "flex min-h-full items-center justify-center p-4 text-center", children: _jsx(Transition.Child, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 scale-95", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 scale-100", leaveTo: "opacity-0 scale-95", children: _jsxs(Dialog.Panel, { className: classNames('relative rounded-br40 text-left align-middle shadow-static-elevation-floating transition-all min-w-96 max-h-full', type === 'default' && 'bg-surface-default', type === 'inverted' && 'bg-surface-inverted-elevated'), children: [children, showCloseBtn && onClose && (_jsx("div", { className: "absolute right-0 top-0 pr-p40 pt-p40", children: _jsx(IconButton, { dataTestId: "Modal-Close-Button", icon: CrossLargeIcon, onClick: onClose, type: "secondary", size: "compact", emphasis: "low" }) }))] }) }) }) })] }) }));
};
export default Modal;
