import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from '@apollo/client';
import Paper from 'components/common/Paper';
import Email from 'harmonic-components/Email/Email';
import { compact, uniq } from 'lodash';
import React from 'react';
import { formatPhoneNumber } from 'utils/utilities';
export const getCompanyContacts = gql `
  query GetCompanyContactsV2($id: Int!) {
    getCompanyById(id: $id) {
      id
      contact {
        emails
        execEmails
        phoneNumbers
      }
    }
  }
`;
const CompanyContactContentLoader = () => {
    return (_jsxs("div", { "data-testid": "CompanyContact-Loading", className: "grid grid-cols-3 gap-4 w-full py-6", children: [_jsx("div", { className: "h-20 w-full col-span-2" }), _jsx("div", { className: "h-20 w-full col-span-1" })] }));
};
const CompanyContactEmailsPhone = ({ companyId }) => {
    const { data } = useQuery(getCompanyContacts, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const companyContact = data?.getCompanyById?.contact;
    const companyEmails = uniq(compact(companyContact?.emails));
    const phoneNumbers = uniq(compact(companyContact?.phoneNumbers?.map((number) => formatPhoneNumber(number))));
    const execEmails = uniq(compact(companyContact?.execEmails));
    const renderCompanyEmails = (emails) => {
        if (emails.length === 0) {
            return null;
        }
        return (_jsxs("div", { "data-testid": "CompanyContact-Company-Emails", className: "flex flex-col gap-g40", children: [_jsx("p", { className: "text-content-strong typography-label-large-strong", children: "Company emails" }), companyEmails.map((email, index) => {
                    return (_jsx("div", { children: _jsx(Email, { email: email }) }, index));
                })] }));
    };
    const renderExecEmails = (execEmails) => {
        if (execEmails.length === 0) {
            return null;
        }
        return (_jsxs("div", { "data-testid": "CompanyContact-Exec-Emails", className: "flex flex-col gap-g40", children: [_jsx("p", { "data-testid": "CompanyContact-Exec-Email-Item", className: "text-content-strong typography-label-large-strong", children: "Executive emails" }), execEmails.map((email, index) => {
                    return (_jsx("div", { children: _jsx(Email, { dataTestId: "CompanyContact-Company-Email-Item", email: email }) }, index));
                })] }));
    };
    const renderEmails = (emails, execEmails) => {
        if (emails.length === 0 && execEmails.length === 0) {
            return null;
        }
        return (_jsx("div", { "data-testid": "CompanyContact-All-Emails", className: "col-span-2", children: _jsx(Paper, { displayShadow: false, children: _jsxs("div", { className: "flex flex-col gap-g80", children: [renderExecEmails(execEmails), renderCompanyEmails(emails)] }) }) }));
    };
    const renderPhoneNumbers = (phoneNumbers) => {
        if (phoneNumbers.length === 0) {
            return null;
        }
        return (_jsx("div", { children: _jsx(Paper, { displayShadow: false, children: _jsxs("div", { "data-testid": "CompanyContact-Phone-Numbers", className: "flex flex-col gap-g40", children: [_jsx("p", { "data-testid": "CompanyContact-Phone-Number-Item", className: "text-content-strong typography-label-large-strong", children: "Phone numbers" }), phoneNumbers.map((phoneNumber, index) => {
                            return (_jsx("p", { className: "typography-label text-content-default", "data-testid": `CompanyContact-phoneNumber-${phoneNumber}`, children: phoneNumber }, index));
                        })] }) }) }));
    };
    return (_jsx("div", { "data-testid": "CompanyContact", className: "flex flex-col justify-start items-start gap-g110 w-full", children: _jsxs("div", { className: "w-full", children: [_jsx("h1", { className: "typography-title-medium text-content-title pb-p70 pt-p20 border-b border-solid border-border", children: "Contact" }), !data ? (_jsx(CompanyContactContentLoader, {})) : (_jsx("div", { className: "py-6 w-full", children: companyEmails.length === 0 &&
                        execEmails.length === 0 &&
                        phoneNumbers.length === 0 ? (_jsx("div", { className: "typography-label", children: "No contact information." })) : (_jsxs("div", { "data-testid": "CompanyContact-All-Contact-Info", className: "grid grid-cols-3 gap-g80 w-full", children: [renderEmails(companyEmails, execEmails), renderPhoneNumbers(phoneNumbers)] })) }))] }) }));
};
export default CompanyContactEmailsPhone;
