const getCompanyCapData = {
    __typename: 'Company',
    id: 1,
    capTable: [
        {
            round: 'series_d',
            roundNumber: 1,
            shares: 20000,
            value: 0.00001,
            issuePrice: 17.89,
            liquidation: 17.89,
            pref_multiple: 1,
            conversion: 17.89,
            owned: 0.22
        },
        {
            round: 'series_d',
            roundNumber: 2,
            shares: 20000,
            value: 0.00001,
            issuePrice: 17.89,
            liquidation: 17.89,
            pref_multiple: 1,
            conversion: 17.89,
            owned: 0.22
        },
        {
            round: 'series_c',
            roundNumber: 1,
            shares: 20000,
            value: 0.00001,
            issuePrice: 17.89,
            liquidation: 17.89,
            pref_multiple: 1,
            conversion: 17.89,
            owned: 0.22
        },
        {
            round: 'series_c',
            roundNumber: 2,
            shares: 20000,
            value: 0.00001,
            issuePrice: 17.89,
            liquidation: 17.89,
            pref_multiple: 1,
            conversion: 17.89,
            owned: 0.22
        },
        {
            round: 'series_c',
            roundNumber: 3,
            shares: 20000,
            value: 0.00001,
            issuePrice: 17.89,
            liquidation: 17.89,
            pref_multiple: 1,
            conversion: 17.89,
            owned: 0.22
        },
        {
            round: 'series_b',
            roundNumber: 1,
            shares: 20000,
            value: 0.00001,
            issuePrice: 17.89,
            liquidation: 17.89,
            pref_multiple: 1,
            conversion: 17.89,
            owned: 0.22
        },
        {
            round: 'series_a',
            roundNumber: null,
            shares: null,
            value: null,
            issuePrice: null,
            liquidation: null,
            pref_multiple: null,
            conversion: null,
            owned: null
        },
        {
            round: 'seed',
            roundNumber: 1,
            shares: 20000,
            value: 0.01001,
            issuePrice: 17.89,
            liquidation: 17.89,
            pref_multiple: 1,
            conversion: 17.89,
            owned: 0.22
        },
        {
            round: 'seed',
            roundNumber: 2,
            shares: 20000,
            value: 0.0001,
            issuePrice: 17.89,
            liquidation: 17.89,
            pref_multiple: 1,
            conversion: 17.89,
            owned: 0.22
        }
    ]
};
export default getCompanyCapData;
