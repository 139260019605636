import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DragDropContext } from '@hello-pangea/dnd';
import React, { createContext, useState } from 'react';
import useFlags from '../../../hooks/useFlags';
import analytics, { CustomTrackEvent } from '../../../utils/analytics';
import { SPLITS } from '../../../utils/constants';
import { getCustomFieldValue } from './utils';
export const NOT_APPLICABLE_FIELD_OPTION = {
    name: 'N/A',
    urn: 'n/a',
    color: 'informative'
};
export const BoardContext = createContext({
    isDragging: false
});
export const BoardView = ({ watchlistId, customField, boardColumn, onDragEnd, columnRef }) => {
    const [isDragging, setIsDragging] = useState(false);
    const { enabled: enableKanbanViewRefetch } = useFlags(SPLITS.enableKanbanViewRefetch);
    if (customField.metadata?.__typename !== 'SelectListCustomFieldMetadata') {
        // TODO: This is an exception, the kanban view shouldn't be rendered
        // without on a custom field thar is not a select list type. Doing this hack
        // while we work on the prototype.
        return null;
    }
    const fieldUrn = customField.urn;
    const options = customField.metadata.options || [];
    const BoardColumn = boardColumn;
    const handleDragStart = () => {
        setIsDragging(true);
    };
    const handleDragEnd = async (result) => {
        const customFieldValue = result.destination?.droppableId;
        if (!customFieldValue)
            return;
        const previousCustomFieldUrn = result.source.droppableId;
        if (previousCustomFieldUrn === customFieldValue)
            return;
        analytics.trackCustomEvent({
            event: CustomTrackEvent.BOARD_VIEW_DROP_END,
            properties: {
                watchlistId: watchlistId,
                customFieldUrn: fieldUrn,
                customFieldValueUrn: customFieldValue,
                previousCustomFieldValueUrn: previousCustomFieldUrn
            }
        });
        setIsDragging(false);
        await onDragEnd?.({
            customFieldValueUrn: getCustomFieldValue(customFieldValue),
            previousCustomFieldValueUrn: getCustomFieldValue(previousCustomFieldUrn),
            watchlistEntryUrn: result.draggableId,
            customFieldUrn: customField?.urn,
            index: result.destination?.index || 0
        });
        // Refetch the column to receive the right order
        if (enableKanbanViewRefetch) {
            columnRef?.current?.[customFieldValue]?.refetch();
        }
    };
    const NOT_APPLICABLE_FIELD_OPTION = {
        name: `No ${customField.name}`,
        urn: 'n/a',
        color: 'neutral'
    };
    return (_jsx("div", { className: "flex flex-col h-full py-2 px-1", children: _jsx("div", { className: "flex w-full overflow-x-scroll", children: _jsx(DragDropContext, { onDragStart: handleDragStart, onDragEnd: handleDragEnd, children: _jsxs(BoardContext.Provider, { value: { isDragging }, children: [_jsx(BoardColumn, { watchlistId: watchlistId, fieldUrn: fieldUrn, option: NOT_APPLICABLE_FIELD_OPTION }), options?.map((option, i) => (_jsx(BoardColumn, { watchlistId: watchlistId, fieldUrn: fieldUrn, option: option }, i)))] }) }) }) }));
};
