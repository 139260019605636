import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { EmployeeGroupType } from '__generated__/graphql';
import { getCompanyEmployees } from 'queries/getCompanyEmployees';
import React from 'react';
import { EMPLOYEE_LIST_PAGE_SIZE } from 'utils/constants';
import CompanyExecutives from '../CompanyTeam/CompanyExecutives';
import CompanyFoundersCeo, { CompanyFoundersContentLoader, FETCH_FOUNDERS_CEO_COUNT, getCompanyFounders } from '../CompanyTeam/CompanyFoundersCeo';
const CompanyOverviewTeam = ({ companyId, onTabChange }) => {
    const { data: founderCeoData } = useQuery(getCompanyFounders, {
        variables: {
            companyUrn: `urn:harmonic:company:${companyId}`,
            page: 0,
            size: FETCH_FOUNDERS_CEO_COUNT,
            employeeGroupType: EmployeeGroupType.FOUNDERS_AND_CEO
        },
        fetchPolicy: 'cache-only'
    });
    const { data: executivesData } = useQuery(getCompanyEmployees, {
        variables: {
            companyUrn: `urn:harmonic:company:${companyId}`,
            page: 0,
            size: EMPLOYEE_LIST_PAGE_SIZE,
            employeeGroupType: EmployeeGroupType.EXECUTIVES,
            userConnectionStatus: null
        },
        fetchPolicy: 'cache-only'
    });
    const showFounderAndCeo = founderCeoData?.getEmployeesByCompanyId?.edges &&
        founderCeoData.getEmployeesByCompanyId.edges.length > 0;
    const showExecutives = !showFounderAndCeo &&
        executivesData?.getEmployeesByCompanyId?.edges &&
        executivesData.getEmployeesByCompanyId.edges.length > 0;
    if (!founderCeoData || !executivesData) {
        return _jsx(CompanyFoundersContentLoader, {});
    }
    return (_jsxs(_Fragment, { children: [showFounderAndCeo && (_jsx(CompanyFoundersCeo, { onTabChange: onTabChange, companyId: companyId })), showExecutives && (_jsx(CompanyExecutives, { companyId: companyId, onTabChange: onTabChange, disableLoadMore: true }))] }));
};
export default CompanyOverviewTeam;
