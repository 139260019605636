import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import CompanyOverviewFinancing from './CompanyOverviewFinancing';
import CompanyOverviewTeam from './CompanyOverviewTeam';
import CompanyTimeline from './CompanyTimeline';
const CompanyOverview = ({ companyId, onTabChange, isStealthCompany }) => {
    return (_jsxs("div", { "data-testid": "CompanyOverview", className: "min-h-screen", children: [!isStealthCompany && (_jsx("div", { className: "pt-4", children: _jsx(CompanyTimeline, { onTabChange: onTabChange, companyId: companyId }) })), _jsx("div", { className: classNames({
                    'py-16': !isStealthCompany,
                    'pt-1 pb-8': isStealthCompany
                }), children: _jsx(CompanyOverviewTeam, { companyId: companyId, onTabChange: !isStealthCompany ? onTabChange : undefined }) }), _jsx(CompanyOverviewFinancing, { companyId: companyId, onTabChange: !isStealthCompany ? onTabChange : undefined })] }));
};
export default CompanyOverview;
