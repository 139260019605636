import { jsx as _jsx } from "react/jsx-runtime";
import { RelevanceScoreIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useStore from 'stores/zustandStore';
import { RELEVANCE_SETTINGS_ROUTE } from 'utils/constants';
const RelevanceScore = ({ score }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const editStoreData = useStore((state) => state.editStoreData);
    const handleClick = () => {
        editStoreData('settingsCalledFromLocation', location);
        navigate(RELEVANCE_SETTINGS_ROUTE);
    };
    return (_jsx("div", { onClick: handleClick, className: "cursor-pointer", children: _jsx(Badge, { leadingIcon: RelevanceScoreIcon, label: `${score}%`, color: "positive", size: "large" }) }));
};
export default RelevanceScore;
