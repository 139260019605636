import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import UserConnectionsTooltip from 'components/common/UserConnectionsTooltip';
import { isNil } from 'lodash';
import getCompanyUserConnections from 'queries/getCompanyUserConnections';
import { getGroupedCompanyUserConnections } from 'utils/userConnections';
import UserConnectionsList from './UserConnectionsList';
const CompanyInNetworkContentLoader = () => {
    return (_jsxs("div", { "data-testid": "CompanyInNetwork-Loading", className: "w-full flex flex-col space-y-2 my-4", children: [_jsx("div", { className: "h-24 w-full bg-slate-200 animate-pulse" }), _jsx("div", { className: "h-24 w-full bg-slate-200 animate-pulse" }), _jsx("div", { className: "h-24 w-full bg-slate-200 animate-pulse" })] }));
};
const CompanyInNetwork = ({ companyId }) => {
    const { data } = useQuery(getCompanyUserConnections, {
        variables: { id: companyId },
        fetchPolicy: 'cache-first'
    });
    const companyUserConnections = data?.getCompanyById?.userConnections;
    const peopleWithConnections = useMemo(() => getGroupedCompanyUserConnections(companyUserConnections, companyId).filter((person) => !isNil(person.person?.id)), [companyUserConnections, companyId]);
    return (_jsxs("div", { "data-testid": "CompanyInNetwork", className: "flex flex-col justify-start items-start w-full", children: [_jsxs("div", { className: "flex items-center py-p60 gap-g30 w-full border-b border-solid border-border", children: [_jsx("h1", { className: "typography-title-medium text-content-title", children: "In network" }), _jsx(UserConnectionsTooltip, {})] }), !data ? (_jsx(CompanyInNetworkContentLoader, {})) : (_jsx("div", { className: "py-6 w-full", children: _jsx("div", { "data-testid": "CompanyInNetwork-All-Connections", className: "w-full", children: companyUserConnections?.length == 0 ? (_jsx("p", { className: "text-content-default typography-label", children: "No people in network." })) : (_jsx(UserConnectionsList, { loading: isNil(data), peopleWithConnections: peopleWithConnections, currentCompanyId: companyId })) }) }))] }));
};
export default CompanyInNetwork;
