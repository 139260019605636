import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CircularProgress } from '@material-ui/core';
import AuthActionPageWrapper from 'components/Auth/AuthActionPageWrapper';
import Button from 'components/common/lib/Button';
import TextField from 'components/common/lib/TextField';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { getApp } from 'firebase/app';
import { confirmPasswordReset, getAuth, verifyPasswordResetCode } from 'firebase/auth';
import { has } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
const PasswordResetHandler = (props) => {
    const auth = getAuth(getApp());
    const { actionCode } = props;
    const [submitted, setSubmitted] = React.useState(false); // If the form has been submitted
    const [loading, setLoading] = React.useState(true); // If we are in a loading state
    const [error, setError] = React.useState(false); // If Firebase returns an error state
    const formSchema = yup.object().shape({
        password: yup
            .string()
            .required()
            .min(8, 'Password requires at least 8 characters.')
            .label('Password')
    });
    const form = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema)
    });
    const { handleSubmit, register, watch } = form;
    const errors = { ...form.errors };
    const handlePasswordReset = (formData) => {
        setLoading(true);
        confirmPasswordReset(auth, actionCode, formData.password)
            .then(() => {
            setLoading(false);
            setSubmitted(true);
        })
            .catch(() => {
            setLoading(false);
            setSubmitted(true);
            setError(true);
        });
    };
    React.useEffect(() => {
        // Verify action code before rendering page
        verifyPasswordResetCode(auth, actionCode)
            .then(() => {
            setLoading(false);
        })
            .catch(() => {
            setLoading(false);
            setError(true);
        });
    }, [actionCode, auth]);
    return (_jsx(AuthActionPageWrapper, { className: "bg-auth-gray justify-start min-h-screen", children: _jsx(FormProvider, { ...form, children: _jsxs("form", { className: "p-8 gap-4 border-none shadow-far form-wrapper bg-white items-start", noValidate: true, autoComplete: "off", onSubmit: handleSubmit(handlePasswordReset), children: [_jsx("h2", { className: "text-left text-ink-light text-2xl font-bold", children: "Reset Password" }), !submitted ? (_jsxs(_Fragment, { children: [_jsx(TextField, { label: "Password", placeholder: "8+ characters", name: "password", id: "password", type: "password", errorText: has(errors, 'password') ? errors.password.message : '', register: register }), _jsxs(Button, { color: "primary", className: "font-semibold flex items-center text-md w-full focus:outline-black", id: "reset-password", fontSize: "small", height: "tall", type: "submit", disabled: loading && !watch('password'), children: [loading ? (_jsx(CircularProgress, { size: 20, className: "circular-progress" })) : null, "Reset Password"] })] })) : !loading ? (_jsx(_Fragment, { children: _jsx("div", { className: "validation-text", children: "Your password has successfully been changed. You may now proceed to the app." }) })) : (_jsx(CircularProgress, {})), error && (_jsx(_Fragment, { children: _jsx("div", { className: "validation-text", children: "There was a problem resetting your password. Please try again." }) })), _jsx(Link, { to: "/login", className: "text-xs font-semibold text-blue-dark focus:underline text-center w-full", children: "Back to login" })] }) }) }));
};
export default PasswordResetHandler;
