import { jsx as _jsx } from "react/jsx-runtime";
import { ParticipantResponseStatusType } from '__generated__/graphql';
import { CircleXIcon } from 'assets/harmonic-icons';
import CircleCheckFilledIcon from 'assets/harmonic-icons/circle-check-filled';
import CircleQuestionmarkIcon from 'assets/harmonic-icons/circle-questionmark';
import { Badge } from 'common/components';
const SingleParticipant = ({ name, emailAddress, responseStatus }) => {
    let trailingIcon = undefined;
    const color = 'neutral';
    let dataTestId = '';
    if (responseStatus === ParticipantResponseStatusType.ACCEPTED) {
        trailingIcon = () => (_jsx(CircleCheckFilledIcon, { className: "h-3 w-3 text-content-sentiment-positive" }));
        dataTestId = 'Event-Single-Participant-Accepted-Response-Status';
    }
    else if (responseStatus === ParticipantResponseStatusType.DECLINED) {
        trailingIcon = () => (_jsx(CircleXIcon, { className: "text-content-sentiment-negative" }));
        dataTestId = 'Event-Single-Participant-Declined-Response-Status';
    }
    else if (responseStatus === ParticipantResponseStatusType.TENTATIVE) {
        trailingIcon = () => _jsx(CircleQuestionmarkIcon, { className: "h-3 w-3" });
        dataTestId = 'Event-Single-Participant-Tentative-Response-Status';
    }
    return (_jsx(Badge, { size: "medium", intensity: "subtle", label: name && name !== '' ? name : emailAddress, tooltipTitle: emailAddress, trailingIcon: trailingIcon, color: color, dataTestId: dataTestId }));
};
export default SingleParticipant;
