import { gql } from '@apollo/client';
export const getCompanyMetrics = gql `
  fragment GetCompanyMetrics_getCompanyById on Company {
    id
    headcount
    externalHeadcount
    correctedHeadcount
    foundingDate {
      date
    }
    socials {
      linkedin {
        status
        url
        followerCount
      }
      crunchbase {
        status
        url
        followerCount
      }
      pitchbook {
        status
        url
        followerCount
      }
      angellist {
        status
        url
        followerCount
      }
      twitter {
        status
        url
        followerCount
      }
      facebook {
        status
        url
        likeCount
      }
      instagram {
        url
        followerCount
        status
      }
      indeed {
        url
        followerCount
        status
      }
      youtube {
        url
        followerCount
        status
      }
      monster {
        url
        followerCount
        status
      }
      stackoverflow {
        url
        followerCount
        status
      }
    }
    tractionMetrics {
      facebookLikeCount {
        metrics {
          timestamp
          metricValue
        }
        ago90d {
          percentChange
          value
          change
        }
      }
      twitterFollowerCount {
        metrics {
          timestamp
          metricValue
        }
        ago90d {
          percentChange
          value
          change
        }
      }
      instagramFollowerCount {
        metrics {
          timestamp
          metricValue
        }
        ago90d {
          percentChange
          value
          change
        }
      }
      linkedinFollowerCount {
        metrics {
          timestamp
          metricValue
        }
        ago90d {
          percentChange
          value
          change
        }
      }
      headcount {
        ago90d {
          percentChange
          value
          change
        }
        metrics {
          timestamp
          metricValue
        }
      }
      correctedHeadcount {
        ago90d {
          percentChange
          value
          change
        }
        metrics {
          timestamp
          metricValue
        }
      }
      externalHeadcount {
        ago90d {
          percentChange
          value
          change
        }
        metrics {
          timestamp
          metricValue
        }
      }
      webTraffic {
        ago90d {
          percentChange
          value
          change
        }
        metrics {
          timestamp
          metricValue
        }
      }
      headcountAdvisor {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountCustomerSuccess {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountData {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountDesign {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountEngineering {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountFinance {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountLegal {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountMarketing {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountOperations {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountOther {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountPeople {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountProduct {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountSales {
        metrics {
          timestamp
          metricValue
        }
      }
      headcountSupport {
        metrics {
          timestamp
          metricValue
        }
      }
    }
  }
  query GetCompanyMetrics($id: Int!) {
    getCompanyById(id: $id) {
      ...GetCompanyMetrics_getCompanyById
    }
  }
`;
