import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import EmailIcon from 'assets/harmonic-icons/email-icon';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useModal } from 'hooks/useModal';
import React from 'react';
import PersonEmailStaticModal from './PersonEmailStaticModal';
const ContactButton = ({ name, emailAddress }) => {
    const { show } = useModal();
    const handleOnUnlock = () => {
        show(_jsx(PersonEmailStaticModal, { name: name, emailAddress: emailAddress }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "lg:hidden", children: _jsx(IconButton, { icon: EmailIcon, onClick: handleOnUnlock, type: "primary", emphasis: "high", size: "compact", dataTestId: "ContactStaticEmailIcon" }) }), _jsx("div", { className: "hidden lg:block", children: _jsx(Button, { leadingIcon: EmailIcon, onClick: handleOnUnlock, label: "Contact", type: "primary", emphasis: "high", size: "compact", dataTestId: "ContactStaticEmail" }) })] }));
};
export default ContactButton;
