import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ENVIRONMENT } from './constants';
const trackUserViewports = () => {
    datadogRum.addAction('viewport_and_resolution_capture', {
        viewport_width: window.innerWidth,
        viewport_height: window.innerHeight,
        screen_width: screen.width,
        screen_height: screen.height
    });
};
export const initializeDatadog = () => {
    datadogRum.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
        env: ENVIRONMENT,
        site: 'datadoghq.com',
        service: 'console-harmonic',
        version: process.env.REACT_APP_VERSION,
        sampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'mask-user-input',
        enableExperimentalFeatures: ['feature_flags'],
        beforeSend: (event, context) => {
            // Add the response headers to the event so we can get the trace id
            // in datadog
            if (event.type === 'resource') {
                if (!event.context) {
                    event.context = {};
                }
                if (event.resource.type === 'fetch') {
                    const fetchContext = context;
                    event.context.responseHeaders = Object.fromEntries(fetchContext.response?.headers.entries() || []);
                }
                if (event.resource.type === 'xhr') {
                    const xhrContext = context;
                    const headers = xhrContext.xhr
                        .getAllResponseHeaders()
                        .split(/\r\n/)
                        .map((header) => header.split(': '));
                    event.context.responseHeaders = Object.fromEntries(headers);
                }
            }
            return true;
        }
    });
    datadogRum.startSessionReplayRecording();
    trackUserViewports();
    datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: 'datadoghq.com',
        service: 'console-harmonic',
        env: ENVIRONMENT,
        forwardErrorsToLogs: true,
        sampleRate: 100
    });
};
export const identifyDatadogUser = ({ name, email, entityUrn }) => {
    datadogRum.setUser({
        urn: entityUrn,
        name,
        email
    });
    datadogLogs.addLoggerGlobalContext('user.urn', entityUrn);
    datadogLogs.addLoggerGlobalContext('user.name', name);
    datadogLogs.addLoggerGlobalContext('user.email', email);
};
export const addDatadogRUMGlobalContext = (properties) => {
    Object.entries(properties).forEach(([key, value]) => {
        datadogRum.addRumGlobalContext(key, value);
    });
};
export const addDatadogEventProperties = (properties) => {
    Object.entries(properties).forEach(([key, value]) => {
        datadogLogs.logger.addContext(key, value);
    });
};
export const sendDatadogTiming = (event) => {
    datadogRum.addTiming(event);
};
