import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
export const PersonUserConnectionsList = ({ userConnections, dataTestId }) => {
    return (_jsx("div", { "data-testid": dataTestId, className: "typography-label", children: userConnections.map((group, index) => {
            const connection = group[0];
            return (_jsxs("span", { children: [_jsx("span", { className: "typography-label", children: connection.userName }), index < userConnections?.length - 2
                        ? ', '
                        : index == userConnections?.length - 2
                            ? ' and '
                            : ''] }, `userConnectionTooltip_${index}`));
        }) }));
};
