import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { ConnectionIntegrationType } from '__generated__/graphql';
import ConnectionsGraphic from 'assets/connections-graphic';
import IntegrateGmailButton from 'components/Dashboard/CompanyV2/CompanyConnections/IntegrateGmailButton';
import IntegrateLinkedinButton from 'components/Dashboard/CompanyV2/CompanyConnections/IntegrateLinkedinButton';
import Banner from 'harmonic-components/Banner/Banner';
import Button from 'harmonic-components/Button/Button';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';
import { useState } from 'react';
import { getConnectionsBannerDismissLocalStorageState, setConnectionsBannerDismissLocalStorageState } from 'utils/connections';
export const ConnectionsBanner = ({ hideDismissButton }) => {
    const [dismissed, setDismissed] = useState(getConnectionsBannerDismissLocalStorageState());
    const { data: userData } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'cache-first'
    });
    const integrationStatus = userData?.getCurrentUser?.connectionsIntegrationStatus;
    const isLinkedinIntegrated = integrationStatus?.find((integ) => integ?.type === ConnectionIntegrationType.LINKEDIN)?.isEnabled ?? false;
    const isGmailIntegrated = integrationStatus?.find((integ) => integ?.type === ConnectionIntegrationType.GOOGLE)?.isEnabled ?? false;
    const onDismiss = () => {
        setDismissed(true);
        setConnectionsBannerDismissLocalStorageState(true);
    };
    if (dismissed) {
        return null;
    }
    return (_jsx("div", { className: "hidden lg:block", children: _jsxs(Banner, { leftGraphic: _jsx(ConnectionsGraphic, {}), "data-testid": "ConnectionsBanner", children: [_jsx("h3", { className: "typography-label-large-strong text-content-strong", children: "Get the most from your network" }), _jsx("p", { className: "mt-p20 typography-paragraph-default-default text-content-default", children: `Use your teammates' network to discover connections into companies
        and people. Sync your email, calendar, and LinkedIn to take advantage of
        connections.` }), _jsxs("div", { className: "flex justify-between mt-p40 max-h-8 w-full", children: [_jsxs("div", { className: "flex gap-g20 w-full", children: [_jsx(IntegrateGmailButton, { type: "primary", isIntegrated: isGmailIntegrated }), _jsx(IntegrateLinkedinButton, { type: "primary", isIntegrated: isLinkedinIntegrated })] }), !hideDismissButton && (_jsx(Button, { label: "Dismiss", emphasis: "low", onClick: onDismiss }))] }), _jsx("div", { children: "\u00A0" })] }) }));
};
