import { gql } from '@apollo/client';
export const GET_WATCHLISTS_FROM_PEOPLE = gql `
  query GetWatchlistsFromPeople($ids: [Int!]!, $extended: Boolean!) {
    getPersonsByIds(ids: $ids, extended: $extended) {
      id
      watchlists {
        id
      }
    }
  }
`;
