import { gql } from '@apollo/client';
export const HIDE_COMPANIES_FROM_SEARCHES = gql `
  mutation HideCompaniesFromSearches($companies: [CompanyUrn]!) {
    hideCompaniesFromSearches(companies: $companies) {
      isHiddenFromSearchesByCurrentUser
      id
    }
  }
`;
export const UNHIDE_COMPANIES_FROM_SEARCHES = gql `
  mutation UnhideCompaniesFromSearches($companies: [CompanyUrn]!) {
    unhideCompaniesFromSearches(companies: $companies) {
      isHiddenFromSearchesByCurrentUser
      id
    }
  }
`;
