import ExpressiveColors from 'common/theme/colors/expressive';
import { dayjsExt } from 'config/dayjs';
import dayjs from 'dayjs';
import { getFundingTypeDisplayName } from 'interfaces/SearchModel/Search';
import { get, round } from 'lodash';
export const CHART_DEFAULT_MINIMUM_START_DATE = dayjs()
    .subtract(3, 'year')
    .format('YYYY-MM-DD');
export const formatMetricsForChart = (metrics) => {
    const lineChartDataPoints = metrics
        ?.filter((m) => m?.timestamp && m?.metricValue)
        ?.map((m) => ({
        x: dayjsExt
            .utc(m?.timestamp)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
        y: m?.metricValue
    }));
    lineChartDataPoints.sort((a, b) => new Date(a.x).valueOf() - new Date(b.x).valueOf());
    return lineChartDataPoints;
};
export const formatFundingRoundsForChart = (fundingRounds) => {
    //Chart.js crashes if there are duplicate labels. It's a bug with library itself.
    //https://stackoverflow.com/questions/69487644/react-chart-js-2-3-0-5-typeerror-cannot-read-properties-of-undefined-readin
    //Solution was to add extra space to each duplicate label.
    const labelCounts = new Map();
    const filteredData = fundingRounds.filter((f) => f?.announcementDate && f?.fundingAmount && f.fundingRoundType);
    filteredData.sort((a, b) => new Date(a.announcementDate).valueOf() -
        new Date(b.announcementDate).valueOf());
    const formattedData = filteredData.map((fundingRound) => {
        const label = getFundingTypeDisplayName(fundingRound?.fundingRoundType);
        const count = labelCounts.get(label) || 0;
        labelCounts.set(label, count + 1);
        const extraSpaces = ' '.repeat(count);
        const formattedLabel = `${label}${extraSpaces}`;
        return {
            x: fundingRound?.announcementDate,
            y: parseInt(fundingRound?.fundingAmount),
            label: formattedLabel
        };
    });
    const allDates = new Set(formattedData.map((data) => data.x));
    const filteredFormattedData = formattedData.filter(
    // Filter out unknown funding rounds that have the same date as known funding rounds
    (data) => !(data.label === 'Unknown' && allDates.has(data.x)));
    return filteredFormattedData;
};
export const formatHeadcountFundingForChart = (tractionData, fundingData, correctedHeadcountEnabled) => {
    const tractionMetrics = correctedHeadcountEnabled
        ? tractionData?.tractionMetrics?.headcount?.metrics ??
            tractionData?.tractionMetrics?.externalHeadcount?.metrics ??
            []
        : tractionData?.tractionMetrics?.externalHeadcount?.metrics ?? [];
    const fundingRounds = fundingData?.funding?.fundingRounds ?? [];
    const lineChartDataPoints = formatMetricsForChart(tractionMetrics);
    const barChartDataPoints = formatFundingRoundsForChart(fundingRounds);
    return {
        lineChartDataPoints,
        barChartDataPoints
    };
};
export const findYBetweenTwoPointsWhenXIsKnown = (minimumDate, maximumDate, minimumDateYValue, maximumDateYValue, toFindDate, roundValue = 2) => {
    // (x1,y1)............(x3,y3).............................(x2,y2)
    // In above equation, we have to find the value of y3 such that x3,y3 falls within the straight line.
    // x1 and x2 is a date and y1 and y2 is a value. X3 is known.
    // Formula is y3 = y1 + ((x3-x1).(y2-y1))/(x2-x1). Positive slope
    if (minimumDateYValue === maximumDateYValue) {
        return minimumDateYValue;
    }
    const x1 = minimumDate;
    const y1 = minimumDateYValue;
    const x2 = maximumDate;
    const y2 = maximumDateYValue;
    const x3 = toFindDate;
    const X3MinusX1 = dayjs(x3).diff(x1).valueOf();
    const Y2MinusY1 = y2 - y1;
    const X2MinusX1 = dayjs(x2).diff(x1).valueOf();
    const value = y1 + (X3MinusX1 * Y2MinusY1) / X2MinusX1;
    const roundedValue = round(value, roundValue);
    // Adding a fail safe incase invalid parameters are passed and calculated value is messed up
    if (roundValue < 0)
        return minimumDateYValue;
    return roundedValue;
};
// Finds the index of the closest date in an array of date strings to a given target date. Date should also be smaller than the target date
// Eg: Suppose we have array of dates as ['2022-5-01', '2023-10-03', '2024-3-05'];
// Our toFindDate is '2023-11-01'
// It will return 1 as the output since the date closest to 2023-11-01 is 2023-10-03 in above array which is at index 1
export const findClosestSmallerDateIndex = (dateArray, toFindDate) => {
    const toFindDateMoment = dayjs(toFindDate, 'YYYY-MM-DD');
    const filteredDates = dateArray.filter((date) => dayjs(date, 'YYYY-MM-DD').isBefore(toFindDateMoment));
    if (filteredDates.length === 0)
        return 0;
    const closestIndex = filteredDates
        .map((date) => ({ date, diff: Math.abs(toFindDateMoment.diff(date)) }))
        .reduce((minIdx, current, idx, arr) => current.diff < arr[minIdx].diff ? idx : minIdx, 0);
    return dateArray.indexOf(filteredDates[closestIndex]);
};
// Adds an interpolated data point to a line chart data array between the closest existing data points to a specified date, extending the line smoothly.
// Eg: Suppose we have an array [ {x:'2020-01-01',y:10} , {x:"2020-06-05",y:20} , {x:"2023-01-01",y:30} ]
// Our toAddDate is '2020-03-01'. Below function will add the toAddDate in above point with calculated y value such that point fits the line.
// Output will be [ {x:'2020-01-01',y:10} , {x:"2020-03-01",y:15}, {x:"2020-06-05",y:20} , {x:"2023-01-01",y:30} ]
export const addInterpolatedDatePoint = (lineChartData, toAddDate) => {
    if (dayjs(toAddDate).isBefore(lineChartData?.[0]?.x) ||
        dayjs(toAddDate).isAfter(lineChartData?.[lineChartData.length - 1]?.x)) {
        return lineChartData;
    }
    const lineChartDataDates = lineChartData.map((d) => d.x);
    const interpolatedLineChartData = [...lineChartData];
    const indexCloseToDate = findClosestSmallerDateIndex(lineChartDataDates, toAddDate);
    const nextIndexCloseToDate = indexCloseToDate < lineChartDataDates.length - 1
        ? indexCloseToDate + 1
        : indexCloseToDate;
    const newYPos = findYBetweenTwoPointsWhenXIsKnown(lineChartData[indexCloseToDate].x, lineChartData[nextIndexCloseToDate].x, lineChartData[indexCloseToDate].y, lineChartData[nextIndexCloseToDate].y, toAddDate, 0);
    const sliceIndex = dayjs(toAddDate).isBefore(lineChartData[indexCloseToDate].x)
        ? indexCloseToDate
        : indexCloseToDate + 1;
    interpolatedLineChartData.splice(sliceIndex, 0, {
        x: toAddDate,
        y: newYPos
    });
    return interpolatedLineChartData;
};
export const chartAreaRoundedBorderPlugin = {
    id: 'chartAreaRoundedBorder',
    //eslint-disable-next-line
    //@ts-ignore
    beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, width, height } } = chart;
        ctx.save();
        ctx.strokeStyle = '#E8EAEE';
        ctx.lineWidth = 1.5;
        ctx.setLineDash(options.borderDash || []);
        ctx.lineDashOffset = 2;
        const borderRadius = 5; // Adjust the border radius as needed
        const outwardOffset = 1.5; // Set the desired outward offset
        // Calculate new dimensions and positions
        const newX = left - outwardOffset;
        const newY = top - outwardOffset;
        const newWidth = width + 2 * outwardOffset;
        const newHeight = height + 2 * outwardOffset;
        // Draw rounded rectangle
        ctx.beginPath();
        ctx.moveTo(newX + borderRadius, newY);
        ctx.arcTo(newX + newWidth, newY, newX + newWidth, newY + newHeight, borderRadius);
        ctx.arcTo(newX + newWidth, newY + newHeight, newX, newY + newHeight, borderRadius);
        ctx.arcTo(newX, newY + newHeight, newX, newY, borderRadius);
        ctx.arcTo(newX, newY, newX + newWidth, newY, borderRadius);
        ctx.closePath();
        // Set the clipping region
        ctx.clip();
        ctx.stroke();
        ctx.restore();
    }
};
// TS Compiler gets upset if we try to use enum values of HeadcountMetricType directly
const DepartmentTypes = [
    'ALL_TEAM',
    'ADVISOR',
    'CUSTOMER_SUCCESS',
    'DATA',
    'DESIGN',
    'ENGINEERING',
    'FINANCE',
    'LEGAL',
    'MARKETING',
    'OPERATIONS',
    'OTHER',
    'PEOPLE',
    'PRODUCT',
    'SALES',
    'SUPPORT'
];
export const getColorsForDepartment = (department) => {
    const color = get(ExpressiveColors.expressive, DepartmentTypes.indexOf(department) * 24);
    if (!color) {
        return {
            background: '#f0f0f0',
            border: '#ccc'
        };
    }
    return {
        background: color.light,
        border: color.mid
    };
};
