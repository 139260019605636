import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from 'harmonic-components/Button/Button';
import { useEffect, useRef } from 'react';
import { ScaleLoader } from 'react-spinners';
import Employee from './Employee';
const LoadMore = ({ onClick }) => {
    return (_jsx(Button, { label: "Load more", onClick: onClick, type: "secondary", emphasis: "low" }));
};
const EmployeesListContentLoader = () => {
    const rows = [...Array(8)];
    return (_jsx("div", { "data-testid": "EmployeesListContentLoader", className: "grid grid-cols-2 gap-4", children: rows.map((_, index) => {
            return (_jsx("div", { className: "flex w-full animated-box-pulse h-24 py-1.5" }, index));
        }) }));
};
const EmployeesList = ({ totalCount, employees, onLoadMore, loading, infiniteScroll = false, currentCompanyId, disableLoadMore, employeeCard = Employee }) => {
    const sentinelRef = useRef(null);
    const showLoadMore = totalCount > employees.length && !disableLoadMore;
    const showContentLoader = loading && employees.length === 0;
    const isLoadingMore = loading && employees.length > 0;
    // The following useEffect will trigger onLoadMore when the bottom of the page is reached and infiniteScroll is true
    useEffect(() => {
        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && !disableLoadMore) {
                    onLoadMore();
                }
            }, {
                threshold: 1.0 // Once 100% of the sentinel is visible, the callback is invoked
            });
            if (sentinelRef.current && infiniteScroll) {
                observer.observe(sentinelRef.current);
            }
            return () => {
                observer.disconnect();
            };
        }
    }, [infiniteScroll, onLoadMore, disableLoadMore]);
    if (showContentLoader) {
        return _jsx(EmployeesListContentLoader, {});
    }
    const EmployeeCard = employeeCard;
    return (_jsxs("div", { "data-testid": "EmployeesList", children: [_jsx("div", { className: "grid sm:grid-cols-2 gap-4 items-start", children: employees.map((employee) => (_jsx(EmployeeCard, { employee: employee, currentCompanyId: currentCompanyId }, `employee-${employee.id}`))) }), _jsxs("div", { className: "flex justify-center items-center mt-4", children: [showLoadMore && _jsx(LoadMore, { onClick: onLoadMore }), isLoadingMore && _jsx(ScaleLoader, { color: "#666", width: 2, height: 20 })] })] }));
};
export default EmployeesList;
