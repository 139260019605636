import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as HeadcountIcon } from 'assets/socials/bxl-headcount.svg';
import MetricCard from 'components/common/CompanyCard/MetricCard';
import PercentChangeBadge from 'components/common/TractionMetrics/PercentChangeBadge';
import { isNil } from 'lodash';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import { addCommasToNumber } from 'utils/utilities';
const HeadcountChange = ({ companyId }) => {
    const { data } = useQuery(getCompanyMetrics, {
        variables: { id: companyId },
        fetchPolicy: 'cache-only'
    });
    const tractionData = data?.getCompanyById;
    const currentHeadcount = tractionData?.headcount;
    const headcountTractionMetrics = tractionData?.tractionMetrics?.headcount?.ago90d;
    const bothPercentAndValueExist = !isNil(headcountTractionMetrics?.percentChange) &&
        !isNil(headcountTractionMetrics?.value);
    return (_jsx(MetricCard, { dataTestId: "CompanySocialCard", value: !isNil(currentHeadcount)
            ? addCommasToNumber(currentHeadcount?.toFixed())
            : 'Unknown', label: "Headcount", icon: HeadcountIcon, titleSuffixComponent: bothPercentAndValueExist && (_jsx(Tooltip, { classes: { tooltip: 'text-xs' }, title: _jsx("span", { children: "\u039490d" }), children: _jsx("div", { "data-testid": "CompanySocialCardPercentGrowth", children: _jsx(PercentChangeBadge, { tractionMetrics: headcountTractionMetrics }) }) })) }));
};
export default HeadcountChange;
