/**
 * @deprecated This type should not be used
 * use EntityListType instead.
 */
export var ApiResourceType;
(function (ApiResourceType) {
    ApiResourceType["Company"] = "COMPANY";
    ApiResourceType["School"] = "SCHOOL";
    ApiResourceType["Person"] = "PERSON";
    ApiResourceType["PeopleList"] = "PEOPLE_LIST";
    ApiResourceType["CompaniesList"] = "COMPANIES_LIST";
})(ApiResourceType || (ApiResourceType = {}));
export var GraphQLResourceType;
(function (GraphQLResourceType) {
    GraphQLResourceType["Company"] = "Company";
    GraphQLResourceType["Person"] = "Person";
})(GraphQLResourceType || (GraphQLResourceType = {}));
