import { gql } from '@apollo/client';
const NET_NEW_SAVED_SEARCH_FRAGMENT = gql `
  fragment NetNewSavedSearch on SavedSearch {
    id
    name
    entityUrn
  }
`;
export const GET_NET_NEW_COMPANIES = gql `
  ${NET_NEW_SAVED_SEARCH_FRAGMENT}
  query GetNetNewCompanies {
    getNetNew {
      companies {
        entityUrn
        netNewId
        savedSearch {
          ...NetNewSavedSearch
        }
        company {
          id
          name
          logoUrl
          funding {
            fundingTotal
          }
          headcount
          tractionMetrics {
            headcount {
              ago365d {
                percentChange
                value
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_NET_NEW_PEOPLE = gql `
  ${NET_NEW_SAVED_SEARCH_FRAGMENT}
  query GetNetNewPeople {
    getNetNew {
      people {
        entityUrn
        netNewId
        person {
          id
        }
        savedSearch {
          ...NetNewSavedSearch
        }
      }
    }
  }
`;
export const GET_NET_NEW_COUNTS = gql `
  query GetNetNewCounts {
    getNetNewCounts {
      count
      savedSearch {
        entityUrn
        name
        type
      }
    }
  }
`;
export const CLEAR_COMPANY_NET_NEW = gql `
  mutation ClearCompanyNetNew($netNewIds: [Int!]!) {
    clearCompanyNetNew(netNewIds: $netNewIds)
  }
`;
export const CLEAR_PERSON_NET_NEW = gql `
  mutation clearPersonNetNew($netNewIds: [Int!]!) {
    clearPersonNetNew(netNewIds: $netNewIds)
  }
`;
export const CLEAR_NET_NEW_FOR_SEARCH = gql `
  mutation clearNetNewBySavedSearchIdOrUrn($idOrUrn: String!) {
    clearNetNewBySavedSearchIdOrUrn(idOrUrn: $idOrUrn)
  }
`;
