import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowRightIcon, CrossIcon, ListIcon, SearchIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_DASHBOARD_ROUTE } from 'utils/constants';
import { DashboardSidebarMenuButton } from '../../components/Dashboard/DashboardLayout/DashboardSidebarButton';
const TopNavigationBar = ({ iconButton = 'cross', icon, segments, name, defaultNavigationOnEmtpyHistory = DEFAULT_DASHBOARD_ROUTE }) => {
    const navigationLocation = useLocation();
    const navigate = useNavigate();
    const onGoBackRoute = () => {
        const historyState = navigationLocation.state;
        if (historyState && historyState.from && historyState.params) {
            const lastURL = `${historyState.from}?${historyState.params}`;
            navigate(lastURL);
            return;
        }
        if (history.length > 1) {
            navigate(-1);
        }
        else {
            navigate(defaultNavigationOnEmtpyHistory);
        }
    };
    function pickIconButton(iconButton) {
        switch (iconButton) {
            case 'back-arrow':
                return (props) => (_jsx(ArrowRightIcon, { ...props, applyCurrentColor: false, className: "stroke-int-overlay-secondary-onEnabled rotate-180" }));
            case 'cross':
            default:
                return CrossIcon;
        }
    }
    function pickIcon(icon) {
        switch (icon) {
            case 'magifying-glass':
                return (props) => (_jsx(SearchIcon, { ...props, className: "w-3 h-3", applyCurrentColor: true }));
            case 'list':
            default:
                return (props) => (_jsx(ListIcon, { ...props, className: "w-3 h-3", applyCurrentColor: true }));
        }
    }
    const PrefixIcon = icon ? pickIcon(icon) : null;
    const finalSegments = segments || (name ? [name] : undefined);
    return (_jsx("div", { className: classNames('fixed left-0 right-0 px-p70 py-p50 h-[52px] bg-white z-10 flex items-center', 'border-b border-solid border-border justify-between'), children: _jsxs("div", { className: "flex items-center gap-g60", children: [_jsx(DashboardSidebarMenuButton, {}), _jsx(IconButton, { "data-testid": "TopNavigationBar-Logo", icon: pickIconButton(iconButton), onClick: onGoBackRoute, size: "compact" }), finalSegments && (_jsxs("div", { "data-testid": "TopNavigationbar-Title", className: classNames('typography-label-small-default text-content-weak flex flex-row items-center gap-g30'), children: [PrefixIcon && _jsx(PrefixIcon, {}), finalSegments.map((segment, index) => (_jsxs("div", { className: "flex flex-row gap-g30", children: [_jsx("p", { children: segment }), index < finalSegments.length - 1 && _jsx("p", { children: "/" })] }, index)))] }))] }) }));
};
export default TopNavigationBar;
