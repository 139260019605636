import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useAppState } from '../../../../hooks/useAppState';
const UserConnectionsDegree = (props) => {
    const { userConnections, onClick } = props;
    const currentUserEmail = useAppState((state) => state.auth.user?.email ?? '');
    const degreeConnection = userConnections.find((userConnection) => userConnection.email === currentUserEmail)
        ? '1st'
        : '2nd';
    return (_jsx("div", { "data-testid": "UserConnectionsDegree", className: classNames('select-none text-content-headerGray typography-label-small', onClick ? 'cursor-pointer' : 'cursor-default'), onClick: onClick, children: degreeConnection }));
};
export default UserConnectionsDegree;
