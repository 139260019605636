import { gql } from '@apollo/client';
export const ADD_COMPANIES_TO_WATCHLIST_BY_CANONICALS = gql `
  mutation AddCompaniesToWatchlistWithCanonicals(
    $urn: CompanyWatchlistUrn!
    $fileName: String
    $flatfileBatchId: String
    $canonicals: [CompanyCanonicalInput!]!
  ) {
    addCompaniesToWatchlistWithCanonicals(
      urn: $urn
      fileName: $fileName
      flatfileBatchId: $flatfileBatchId
      canonicals: $canonicals
    ) {
      userCompaniesImportUrn
      totalCompaniesCount
      companiesFoundCount
      companiesNotFoundCount
      companiesInvalidCount
    }
  }
`;
