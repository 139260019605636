import { jsx as _jsx } from "react/jsx-runtime";
import { get, toString } from 'lodash';
import React from 'react';
import { useShallowTableStore } from '../../../../../stores/tableStore';
import { isElementOverflow } from '../../../../../utils/utilities';
import { LightTooltip } from '../../../../common';
import { LoadingCellRenderer, NoDataSubRenderer, useStyles } from '../CellRenderers';
export const LongText = (props) => {
    const classes = useStyles();
    const textRef = React.useRef(null);
    const [isEllipsisActive, setIsEllipsisActive] = React.useState(false);
    const { gridRowHeight } = useShallowTableStore(['gridRowHeight']);
    React.useEffect(() => {
        if (textRef.current) {
            setIsEllipsisActive(isElementOverflow(textRef.current));
        }
    }, [textRef.current]);
    const text = get(props, 'value');
    if (text === 'loading')
        return _jsx(LoadingCellRenderer, {});
    if (!text && props.hidePlaceholder)
        return null;
    if (!text) {
        return _jsx(NoDataSubRenderer, {});
    }
    let formattedText = '';
    if (typeof text === 'number') {
        formattedText = toString(text);
    }
    else if (typeof text === 'string') {
        formattedText = text;
    }
    // Remove characters that may mess with formatting (currently just emojis)
    formattedText = formattedText.replace(/[\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]/g, '');
    if (text && text != '') {
        return (_jsx(LightTooltip, { enterDelay: 1500, title: isEllipsisActive ? formattedText : '', interactive: true, children: _jsx("div", { ref: textRef, className: `${classes.ellipsis} ${gridRowHeight > 100
                    ? classes.rowHeightTall
                    : gridRowHeight < 60
                        ? classes.rowHeightSmall
                        : classes.rowHeightMedium}
          ${classes.breakWord}
          typography-paragraph text-content-default
          `, children: formattedText }) }));
    }
    return (_jsx("p", { className: "typography-paragraph text-content-default", children: props.node?.data && ' ' }));
};
