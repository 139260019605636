import { useLazyQuery, useQuery } from '@apollo/client';
import { useShallowTableStore } from '../stores/tableStore';
import { GRID_INITIAL_PAGE_SIZE } from '../utils/constants';
export const useRefetchWatchlistResults = ({ query, variables, getDataPath, getUrnPrefix }) => {
    const { addToLoadedExtendedUrns } = useShallowTableStore([
        'addToLoadedExtendedUrns'
    ]);
    const { data: cachedData } = useQuery(query, {
        variables,
        fetchPolicy: 'cache-only'
    });
    const numCachedEdges = getDataPath(cachedData)?.edges?.length || 0;
    const [fetch] = useLazyQuery(query, {
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only'
    });
    const handleCompleted = (data) => {
        const entries = getDataPath(data);
        const urns = entries?.edges?.map((edge) => `${getUrnPrefix}${edge.node[Object.keys(edge.node)[0]].id}`) ?? [];
        addToLoadedExtendedUrns(urns);
        return entries;
    };
    const refetchResults = async () => {
        let hasNextPage = true;
        let cursor = null;
        let fetchedCount = 0;
        // Fetch only first page if no results are cached
        if (numCachedEdges === 0) {
            await fetch({
                variables: {
                    ...variables,
                    first: GRID_INITIAL_PAGE_SIZE,
                    after: cursor,
                    skipExtended: false
                },
                onCompleted: handleCompleted
            });
        }
        else {
            let shouldRefetch = fetchedCount < numCachedEdges;
            while (hasNextPage && shouldRefetch) {
                await fetch({
                    variables: {
                        ...variables,
                        first: GRID_INITIAL_PAGE_SIZE,
                        after: cursor,
                        skipExtended: false
                    },
                    onError: () => {
                        shouldRefetch = false;
                    },
                    onCompleted: (data) => {
                        const entries = handleCompleted(data);
                        const pageInfo = entries?.pageInfo;
                        hasNextPage = pageInfo?.hasNextPage ?? false;
                        cursor = pageInfo?.endCursor ?? null;
                        fetchedCount += entries?.edges?.length ?? 0;
                        shouldRefetch = fetchedCount < numCachedEdges;
                    }
                });
            }
        }
    };
    return {
        refetchResults
    };
};
