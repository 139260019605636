import { jsx as _jsx } from "react/jsx-runtime";
import ListItem from 'harmonic-components/ListItem/ListItem';
import { useContext } from 'react';
import SelectContext from './SelectContext';
const SelectListItem = (props) => {
    const { listItems, keyboardFocusIndex } = useContext(SelectContext);
    const isFocused = listItems?.[keyboardFocusIndex]?.value === props.value;
    return _jsx(ListItem, { ...props, focused: isFocused });
};
SelectListItem.displayName = 'SelectListItem';
export default SelectListItem;
