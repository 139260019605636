import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LocationIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import React from 'react';
import { formatLocation } from 'utils/utilities';
const Location = ({ city, state, country, formattedLocation, fontSize = 'medium' }) => {
    const addressFormatted = formattedLocation
        ? formattedLocation
        : formatLocation(city, state, country);
    if (!addressFormatted)
        return null;
    return (_jsxs("div", { className: "flex flex-row items-center gap-x-1", children: [_jsx(LocationIcon, { className: "text-content-default" }), _jsx("p", { className: classNames('text-content-default', {
                    'typography-label-large': fontSize === 'medium',
                    'typography-label-default-default': fontSize === 'small'
                }), children: addressFormatted })] }));
};
export default Location;
