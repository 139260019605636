import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import DatePickerHeader from 'harmonic-components/DatePicker/DatePickerHeader';
import DatePickerInput from 'harmonic-components/DatePicker/DatePickerInput';
import ReactDatePicker from 'react-datepicker';
import { DateTypeEnum, InternalModeType } from './DateModal';
export const getDateFormat = (dateType) => {
    switch (dateType) {
        case DateTypeEnum.YEAR:
            return 'yyyy';
        default:
            return 'yyyy-MM-dd';
    }
};
const SingleInlineDatePicker = ({ selectedDate, onChange, dateType = DateTypeEnum.DAY, internalModeType }) => {
    const isYear = dateType === DateTypeEnum.YEAR;
    const onDateChange = (date) => {
        if (internalModeType === InternalModeType.BEFORE && isYear) {
            date = date ? new Date(date.getFullYear(), 11, 31) : null;
        }
        onChange(date ? dayjs(date).format('YYYY-MM-DD') : null);
    };
    const dateObj = selectedDate
        ? dayjs(selectedDate, 'YYYY-MM-DD').toDate()
        : null;
    if (isYear) {
        return (_jsxs("div", { children: [_jsx("div", { className: "pb-p50 border-b border-solid border-border", children: _jsx(ReactDatePicker, { open: false, selected: dateObj, onChange: (date) => onDateChange(date), dateFormat: getDateFormat(dateType), showPopperArrow: false, calendarClassName: "hidden", wrapperClassName: "w-full", className: "w-full", customInput: 
                        //eslint-disable-next-line
                        //@ts-ignore
                        _jsx(DatePickerInput, { native: false, inputPlaceholder: 'Select a year' }), showYearPicker: dateType === DateTypeEnum.YEAR, showYearDropdown: dateType === DateTypeEnum.YEAR }) }), _jsx(ReactDatePicker, { selected: dateObj, onChange: (date) => onDateChange(date), dateFormat: getDateFormat(dateType), calendarClassName: "flex flex-col border-none", showPopperArrow: false, renderCustomHeader: ({ ...props }) => {
                        return (_jsx(DatePickerHeader, { ...props, selectedDate: selectedDate, dateType: dateType, onChange: (date) => onDateChange(date) }));
                    }, inline: true, showYearPicker: dateType === DateTypeEnum.YEAR, showYearDropdown: dateType === DateTypeEnum.YEAR })] }));
    }
    return (_jsxs("div", { children: [_jsx("div", { className: "pb-p50 border-b border-solid border-border", children: _jsx(ReactDatePicker, { open: false, selected: dateObj, onChange: (date) => onDateChange(date), dateFormat: "yyyy-MM-dd", showPopperArrow: false, calendarClassName: "hidden", wrapperClassName: "w-full", className: "w-full", 
                    //eslint-disable-next-line
                    //@ts-ignore
                    customInput: _jsx(DatePickerInput, {}) }) }), _jsx(ReactDatePicker, { selected: dateObj, onChange: (date) => onDateChange(date), dateFormat: "yyyy-MM-dd", calendarClassName: "flex flex-col border-none", showPopperArrow: false, renderCustomHeader: ({ ...props }) => {
                    return _jsx(DatePickerHeader, { ...props, selectedDate: selectedDate });
                }, inline: true })] }));
};
export default SingleInlineDatePicker;
