import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToastCautionIcon, ToastNegativeIcon, ToastPositiveIcon } from 'assets/harmonic-icons';
import Button from 'harmonic-components/Button/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
export const displayToast = ({ primaryText, secondaryText, link, mode = 'success', cta }) => {
    const ToastComponent = (_jsxs("div", { className: "flex-1 flex flex-row justify-between", children: [_jsxs("div", { className: "flex flex-col gap-g20 py-p20", children: [_jsx("p", { className: "typography-paragraph text-content-inverted-strong", children: primaryText }), (link || secondaryText) && (_jsx("div", { className: "typography-paragraph-small-default text-content-inverted-default", children: link ? (_jsx(Link, { to: link, children: secondaryText })) : (_jsx("p", { children: secondaryText })) }))] }), cta && (_jsx(Button, { type: "inverted", emphasis: "low", size: "compact", label: cta.text, onClick: cta.onClick }))] }));
    if (mode === 'success')
        toast.success(ToastComponent, {
            icon: () => (_jsx(ToastPositiveIcon, {}))
        });
    if (mode === 'info')
        toast.info(ToastComponent, {
            icon: () => (_jsx(ToastCautionIcon, {}))
        });
    if (mode === 'warn')
        toast.warn(ToastComponent, {
            icon: () => (_jsx(ToastCautionIcon, {}))
        });
    if (mode === 'error')
        toast.error(ToastComponent, {
            icon: () => (_jsx(ToastNegativeIcon, {}))
        });
};
