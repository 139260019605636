import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'common/components';
import { isNil, toNumber } from 'lodash';
export const RelevanceScore = (props) => {
    if (isNil(props.value))
        return null;
    const value = toNumber(props.value);
    const isBelowThreshold = value < 70;
    return (_jsx("div", { className: "inline-block", children: _jsx(Badge, { size: "large", color: isBelowThreshold ? 'neutral' : 'positive', intensity: "subtle", label: `${value}%` }) }));
};
