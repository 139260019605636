import { useQuery } from '@apollo/client';
import { GET_WATCHLIST } from 'queries/getWatchlist';
import { useMemo } from 'react';
import { getIdFromUrn } from 'utils/urn';
import useDashboardLocation, { DashboardLocation } from './useDashboardLocation';
const useFetchSingleWatchlist = ({ fetchPolicy } = {
    fetchPolicy: 'cache-first'
}) => {
    const routeDetails = useDashboardLocation();
    const urn = routeDetails.urn;
    const isCompanyWatchlist = routeDetails.location === DashboardLocation.COMPANY_LIST;
    const id = getIdFromUrn(urn ?? null) || '';
    const { data: companyData, loading: companyWatchlistLoading, error: companyWatchlistError } = useQuery(GET_WATCHLIST, {
        variables: {
            idOrUrn: id
        },
        skip: !isCompanyWatchlist,
        fetchPolicy,
        returnPartialData: true,
        notifyOnNetworkStatusChange: true
    });
    const companyWatchlistData = useMemo(() => {
        if (companyData && isCompanyWatchlist) {
            return companyData?.getCompanyWatchlistByIdOrUrn;
        }
    }, [companyData, isCompanyWatchlist]);
    return {
        companyWatchlist: companyWatchlistData,
        companyWatchlistLoading: !companyData && companyWatchlistLoading,
        companyWatchlistError
    };
};
export default useFetchSingleWatchlist;
