import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '@material-ui/core';
import { ReactComponent as BoxAwardIcon } from 'assets/bxs-award.svg';
import defaultPersonLogo from 'assets/default-person-logo.svg';
import classNames from 'classnames';
import { getHighlightIcon, SingleHighlight } from 'components/common/Highlights/HighlightsChip';
import InternalLink from 'components/common/InternalLink';
import Avatar from 'harmonic-components/Avatar/Avatar';
import React, { useState } from 'react';
import { isSafeHostedAsset } from 'utils/utilities';
const AvatarWithHighlightBadge = ({ highlights, personId, href, src, size }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const imageSrc = isSafeHostedAsset(src) ? src : defaultPersonLogo;
    const open = Boolean(anchorEl);
    return (_jsxs("div", { className: classNames('relative'), children: [_jsxs("div", { className: "relative", children: [_jsx(InternalLink, { to: { pathname: href }, dataTestId: "AvatarWithHighlightBadge-InternalLink", children: _jsx(Avatar, { size: size, src: imageSrc, alt: "Person image", kind: "face" }) }), highlights && highlights.length > 0 && (_jsx("div", { "data-testid": "HighlightBadge", className: classNames('flex justify-center absolute -bottom-2 -right-1'), children: _jsx("span", { onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose, children: _jsx(BoxAwardIcon, {}) }) }))] }), highlights && highlights.length > 0 && (_jsx("div", { "data-testid": "HighlightBadge-Popover", children: _jsx(Popover, { open: open, anchorEl: anchorEl, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                    }, disableScrollLock: true, disableRestoreFocus: true, disableAutoFocus: true, classes: {
                        paper: 'rounded-none border-none bg-transparent shadow-none p-p20'
                    }, style: { pointerEvents: 'none', marginTop: 4 }, children: _jsx("div", { "data-testid": "Highlights-Dropdown", className: classNames('bg-white grid p-p50 gap-g30 border border-solid border-border shadow-int-elevation-floating rounded-br30', highlights.length > 1 ? 'grid-cols-2' : 'grid-cols-1'), children: highlights.map((highlight) => {
                            const HighlightIcon = getHighlightIcon(highlight);
                            return (_jsx(SingleHighlight, { highlight: highlight, icon: HighlightIcon }, `highlight-${highlight}-${personId}`));
                        }) }) }) }))] }));
};
export default AvatarWithHighlightBadge;
