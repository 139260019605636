import { jsx as _jsx } from "react/jsx-runtime";
import { get } from 'lodash';
import React, { useMemo } from 'react';
import PersonAttribute from '../../../../../harmonic-components/PersonAttribute/PersonAttribute';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { EventLocations } from '../../../../../utils/analytics';
import { NoDataSubRenderer } from '../CellRenderers';
export const PeopleList = (props) => {
    const headerName = props.column?.getDefinition()?.headerName ?? '';
    const shouldLinkToLinkedin = headerName.includes('LinkedIn');
    const company = get(props, 'node.data.company');
    const personRelationships = useMemo(() => {
        const relevantPersonRelationships = (company?.person_relationships ?? []).filter((person) => person != null);
        return relevantPersonRelationships.map((personRelationship) => {
            if (personRelationship) {
                return {
                    ...personRelationship,
                    person: {
                        id: personRelationship.id,
                        full_name: personRelationship.fullName,
                        linkedin_url: personRelationship.socials?.linkedin?.url,
                        personal_emails: personRelationship.contact?.emails
                    },
                    person_company_emails: personRelationship?.contact?.emails
                };
            }
        });
    }, [company?.person_relationships]);
    const team = useMemo(() => {
        const founders = personRelationships?.filter((relationship) => relationship?.person?.full_name) || [];
        return founders
            .map((person) => {
            const teamMemberHarmonicProfileLink = get(person, 'person.id', null)
                ? `/dashboard/person/${get(person, 'person.id')}`
                : undefined;
            const useLinkToLinkedIn = shouldLinkToLinkedin && Boolean(get(person, 'person.linkedin_url'));
            return {
                text: get(person, 'person.full_name'),
                href: useLinkToLinkedIn
                    ? get(person, 'person.linkedin_url')
                    : teamMemberHarmonicProfileLink,
                openedFromLocation: !useLinkToLinkedIn
                    ? EventLocations.GRID_TABLE_TEAM
                    : undefined,
                profilePicSrc: get(person, 'profilePictureUrl')
            };
        })
            .filter((listItem) => Boolean(listItem.text));
    }, [personRelationships, shouldLinkToLinkedin]);
    if (team.length === 0) {
        return _jsx(NoDataSubRenderer, {});
    }
    return (_jsx("div", { className: "inline-block", children: _jsx(TruncatedList, { wrapperClassName: "-ml-p30", height: 54, children: team.map((person, index) => (_jsx(PersonAttribute, { src: person.profilePicSrc ?? '', href: person.href ?? '', name: person.text ?? '' }, index))) }) }));
};
