export var SocialMetricsType;
(function (SocialMetricsType) {
    SocialMetricsType["WEB_TRAFFIC"] = "WEB_TRAFFIC";
    SocialMetricsType["FACEBOOK_LIKES"] = "FACEBOOK_LIKES";
    SocialMetricsType["TWITTER_FOLLOWERS"] = "TWITTER_FOLLOWERS";
    SocialMetricsType["INSTAGRAM_FOLLOWERS"] = "INSTAGRAM_FOLLOWERS";
    SocialMetricsType["LINKEDIN_FOLLOWERS"] = "LINKEDIN_FOLLOWERS";
})(SocialMetricsType || (SocialMetricsType = {}));
export var HeadcountMetricsType;
(function (HeadcountMetricsType) {
    HeadcountMetricsType["CORRECTED_HEADCOUNT"] = "CORRECTED_HEADCOUNT";
    HeadcountMetricsType["LINKEDIN_HEADCOUNT"] = "LINKEDIN_HEADCOUNT";
})(HeadcountMetricsType || (HeadcountMetricsType = {}));
export var InitialScrollToSection;
(function (InitialScrollToSection) {
    InitialScrollToSection["SOCIALS_HEADCOUNT"] = "SOCIALS_HEADCOUNT";
})(InitialScrollToSection || (InitialScrollToSection = {}));
